import React from "react"
import { Container } from "react-bootstrap"
import signature from '../Icons/file-signature-solid.svg';
import handshake from '../Icons/handshake-solid.svg';
import personCheck from '../Icons/person-circle-check-solid.svg';
import headset from '../Icons/headset-solid.svg';
import percent from '../Icons/percent-solid.svg';
import hand from '../Icons/hand-holding-dollar-solid.svg';
import '../Styles/2rows.css'
// import Tilt from './flare'

const SixCardsInfo = [
    {
        id: 1,
        src: handshake,
        desc: 'Войдём в положение и найдём удобный вариант погашения задолженности',
    },
    {
        id: 2,
        src: personCheck,
        desc: 'У вас есть возможность улучшить свою кредитную историю',
    },
    {
        id: 3,
        src: percent,
        desc: 'Мы прощаем до 50% долга, исходя из вашей ситуации',
    },
    {
        id: 4,
        src: headset,
        desc: 'Оперативно проконсультируем и ответим на возникшие вопросы',
    },
    {
        id: 5,
        src: hand,
        desc: 'Мы списываем все пени и штрафы, и ничего не начисляем дополнительно',
    },
    {
        id: 6,
        src: signature,
        desc: 'Вы получаете полный набор документов и справку о погашении задолженности',
    },
]

const Tilt = React.lazy(() => import('./flare'))

function SixCards() {
    const preventDragHandler = (e) => {
        e.preventDefault()
    }

    const options = {
        scale:  1,
        glare: true,
        'max-glare': 0.2,
        'data-tilt-glare': true,
        max: 8,
        speed: 250,
        perspective: 1650,
        reverse: false,
        transition: true,
        // easing: "cubic-bezier(.03,.98,.52,.99)",
    }
    return (
        <>
            <Container className="containerRow" style={{marginBottom: '9rem'}}>
                {SixCardsInfo.map((card, i) => {
                    return (
                        <Tilt className='card' options = {options} key={SixCardsInfo[i].id}>
                            <img src={card.src} className='par' alt='shake' onDragStart={preventDragHandler}/>
                        <p>{card.desc}</p>
                </Tilt>
                    )
                })}
            </Container>
        </>
    )
}

export default SixCards