import React, { lazy } from 'react';
import 'bootstrap/dist/css/bootstrap-reboot.css';
import src from './Styles/Eclogo2.png';
import {BrowserRouter, Navigate, Route, Routes} from 'react-router-dom'
import About from './Pages/About';
import Pay from './Pages/Pay';
import Mainpage from './Pages/Mainpage';
import './BackGround.css';
import Debt from './Pages/Debt';
import LogIn from './Pages/LogIn';
import SignUp from './Pages/Register';
import WithoutNav from './Pages/WithoutNav';
import WithNav from './Pages/WithNav';
import { AuthProvider, AuthContext } from './context/AuthContext';
import { FetchProvider } from './context/FetchContext';
import WithSidebar from './Pages/WithSidebar';
import SupportPageMain from './Pages/SupportPageMain';
// import DashboardInfo from './Pages/Dashboard.info';
import AccountDocs from './Pages/AccountDocs';
import Requisites from './Pages/Requisites';
import InfoPage from './Pages/Info';
import AdminPageArticles from './Pages/AdminPageArticles';
import AdminPageAllArticles from './Pages/AdminPageAllArticles';
import AdminPageNotes from './Pages/AdminPageNotes';
import DashboardNotes from './Pages/Dashboard.notes';
import AdminPageQuestions from './Pages/AdminPageQuestions';
import AdminPageVacancies from './Pages/AdminPageVacancies';
import Authorization from './Pages/Authorization';
import Maintanance from './Pages/Maintanance';
import AdminPageResolved from './Pages/AdminPageResolved';
import AdminPageCandidates from './Pages/AdminPageCandidates';
const Careers = lazy(() => import('./Pages/Careers'));
const Vacancy = lazy(() => import('./Pages/CareersVacancy'));
const DashboardContract = lazy(()=> import('./Pages/Dashboard.contract'));
const DashboardPay = lazy(()=> import('./Pages/Dashboard.pay'));
const AdminPage = lazy(()=> import('./Pages/AdminPage'));
const Article = lazy(()=> import('./Pages/Article'));
const License = lazy(()=> import('./Pages/License'));
const PasswordReset = lazy(()=> import('./Pages/PasswordReset'));
const SupportPageReset = lazy(()=> import('./Pages/SupportPageReset'));
const Settings = lazy(()=> import('./Pages/Dashboard.settings'));

const AuthenticatedRoute = ({children, ...rest}) => {
  const auth = React.useContext(AuthContext);
  return auth.isAuthenticated() && !auth.isAdmin() ? children : <Navigate to="/login"/>
};

const AdminRoute = ({children, ...rest}) => {
  const auth = React.useContext(AuthContext);
  return auth.isAuthenticated() && auth.isAdmin() ? children : <Navigate to='/login'/>
}

const AppRoutes = () => {
  return (
    <>
      <React.Suspense fallback={<img src={src} alt='fallback' className='SourcePhoto'/>}>
        <Routes>
          <Route element={<WithoutNav />}>
            <Route path='/login' element={<LogIn />} />
            <Route path='/signup' element={<SignUp />} />
            <Route path='/authorization' element={<Authorization />} />
            <Route path='/reset' element={<SupportPageReset/>} />
            <Route path='/resetpassword/:token' element={<PasswordReset/>} />
          </Route>
          <Route element={<WithNav />}>
             <Route path="/" element={<Mainpage />} />
             <Route path="/about" element={<About />} />
             <Route path="/pay" element={<Pay />} />
             <Route path="/debt" element={<Debt />} />
             <Route path="/careers" element={<Careers />} />
             <Route path="/vacancy/:id" element={<Vacancy />} />
             <Route path="/support" element={<SupportPageMain />} /> 
             <Route path="/license" element={<License />} />
             <Route path="/requisites" element={<Requisites />} />
             <Route path='/info' element={<InfoPage />} />
             <Route path='/article/:id' element={<Article />} />
          </Route>
          <Route element={<WithSidebar/>}>
            <Route
              path="/dc"
              element={
                <AuthenticatedRoute>
                  <DashboardContract/>
                </AuthenticatedRoute>
              }
            />
            {/* <Route
              path="/di"
              element={
                <AuthenticatedRoute>
                  <DashboardInfo/>
                </AuthenticatedRoute>
              }
            /> */}
            <Route
              path="/dp"
              element={
                <AuthenticatedRoute>
                  <DashboardPay/>
                </AuthenticatedRoute>
              }
            />
            <Route
              path="/docs"
              element={
                <AuthenticatedRoute>
                  <AccountDocs/>
                </AuthenticatedRoute>
              }
            />
            <Route
              path="/notes"
              element={
                <AuthenticatedRoute>
                  <DashboardNotes/>
                </AuthenticatedRoute>
              }
            />
            <Route
              path="/admin-page"
              element={
                <AdminRoute>
                  <AdminPage/>
                </AdminRoute>
              }
            />
            <Route
              path="/admin-page-resolved"
              element={
                <AdminRoute>
                  <AdminPageResolved/>
                </AdminRoute>
              }
            />
            <Route
              path="/admin-articles"
              element={
                <AdminRoute>
                  <AdminPageArticles/>
                </AdminRoute>
              }
            />
            <Route
              path="/all-articles"
              element={
                <AdminRoute>
                  <AdminPageAllArticles/>
                </AdminRoute>
              }
            />
            <Route
              path="/all-questions"
              element={
                <AdminRoute>
                  <AdminPageQuestions/>
                </AdminRoute>
              }
            />
            <Route
              path="/upload-notes"
              element={
                <AdminRoute>
                  <AdminPageNotes/>
                </AdminRoute>
              }
            />
            <Route
              path="/upload-vacancy"
              element={
                <AdminRoute>
                  <AdminPageVacancies/>
                </AdminRoute>
              }
            />
            <Route
              path="/get-candidates"
              element={
                <AdminRoute>
                  <AdminPageCandidates/>
                </AdminRoute>
              }
            />
            <Route
              path="/settings"
              element={
                <AuthenticatedRoute>
                  <Settings/>
                </AuthenticatedRoute>
              }
            />
          </Route>
        </Routes>
      </React.Suspense>
    </> 
  )
}
// const AppRoutes = () => {
//   return (
//     <>
//       {/* <Suspense fallback={<img src={src} alt='fallback' />}> */}
//         <Routes>         
//           <Route element={<WithoutNav />}>
//             <Route path='/login' element={<LogIn />} />
//             <Route path='/signup' element={<SignUp />} />
//           </Route>     
//           <Route element={<WithNav />}>
//             <Route path="/" element={<Mainpage />} />
//             <Route path="/about" element={<About />} />
//             <Route path="/pay" element={<Pay />} />
//             <Route path="/debt" element={<Debt />} />
//             <Route path="/documents" element={<Documents />} />
//             <Route path="/contacts" element={<Contacts />} />           
//           </Route>
//           <Route element={<WithSidebar />}>
//             <Route path='/dc' element={<DashboardContract/>} />
//             <Route path='/dp' element={<DashboardInfo/>} />
//             <Route path='/di' element={<DashboardPay/>} />
//           </Route>      
//         </Routes>
//       {/* </Suspense> */}
//     </> 
//   )
// }
function App() {
  return (
    <div className='body'>
      <BrowserRouter>
        <AuthProvider>
          <FetchProvider>
            <div className='body'>
              <AppRoutes />
            </div>
          </FetchProvider>
        </AuthProvider>
      </BrowserRouter>
      <script src="vanilla-tilt.js"></script>
    </div>
  );
}

export default App;