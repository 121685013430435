import React from 'react';
import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import {Navigation, Zoom} from 'swiper';
import './DocSlides.css';
import src from '../documents/svidetelstvo.jpg'
import src2 from '../documents/svidetelstvo_2.jpg'
import src3 from '../documents/svid_pristav1.jpg'
import src4 from '../documents/Napka2023.png'
import src5 from '../documents/dogovor.pdf'
import src6 from '../documents/policy.pdf'
import src7 from '../documents/List.pdf'
import srcL from '../Styles/ECLOGO1.png'
import Tooltip from '@mui/material/Tooltip'

export default function DocSlides() {
    const preventDragHandler = (e) => {
        e.preventDefault()
    }

    const width = window.innerWidth
    const breakpoint = 480

    return (
        <>
            <Swiper
            slidesPerView={1}
            spaceBetween={25}
            slidesPerGroup={1}
            loop={true}
            loopFillGroupWithBlank={true}
            navigation = {true}
            modules={[Navigation,Zoom]}
            className='swiper1'
            allowTouchMove= {false}
            zoom = { width < breakpoint ? {maxRatio: 1.75} : {maxRatio: 2.5}}
            >    
                <SwiperSlide className='swiper-slide1'>
                    <Tooltip title={<h1 style={{fontFamily:'Overpass, sans-serif', fontSize:'11pt', letterSpacing:'0.25px', padding:'0.75rem', width:'325px', fontWeight:'300', height:'min-content'}}>Нажмите дважды чтобы увеличить, зажимайте ЛКМ для перемещения</h1>} placement='top' disableTouchListener={true}>
                        <div className='swiper-zoom-container'>
                            <img src={src} alt='svidetelstvo' onDragStart={preventDragHandler}/>
                        </div>
                    </Tooltip>
                </SwiperSlide>
                <SwiperSlide className='swiper-slide1'>
                    <Tooltip title={<h1 style={{fontFamily:'Overpass, sans-serif', fontSize:'11pt', letterSpacing:'0.25px', padding:'0.75rem', width:'325px', fontWeight:'300', height:'min-content'}}>Нажмите дважды чтобы увеличить, зажимайте ЛКМ для перемещения</h1>} placement='top' disableTouchListener={true}>
                        <div className='swiper-zoom-container'>
                            <img src={src2} alt='svidetelstvo2' onDragStart={preventDragHandler} loading="lazy"/>
                        </div>
                    </Tooltip>
                </SwiperSlide>
                <SwiperSlide className='swiper-slide1'>
                    <Tooltip title={<h1 style={{fontFamily:'Overpass, sans-serif', fontSize:'11pt', letterSpacing:'0.25px', padding:'0.75rem', width:'325px', fontWeight:'300', height:'min-content'}}>Нажмите дважды чтобы увеличить, зажимайте ЛКМ для перемещения</h1>} placement='top' disableTouchListener={true}>
                        <div className='swiper-zoom-container'>
                            <img src={src3} alt='svid_pristav' onDragStart={preventDragHandler} loading="lazy"/>
                        </div>
                    </Tooltip>    
                </SwiperSlide>
                <SwiperSlide className='swiper-slide1'>
                    <Tooltip title={<h1 style={{fontFamily:'Overpass, sans-serif', fontSize:'11pt', letterSpacing:'0.25px', padding:'0.75rem', width:'325px', fontWeight:'300', height:'min-content'}}>Нажмите дважды чтобы увеличить, зажимайте ЛКМ для перемещения</h1>} placement='top' disableTouchListener={true}>
                        <div className='swiper-zoom-container'>
                            <img src={src4} alt='napka' style={{width:'75%'}} onDragStart={preventDragHandler} loading="lazy"/>
                        </div>
                    </Tooltip>
                </SwiperSlide>
                <SwiperSlide className='swiper-slide1'>
                    <div className='swiper-nz-container'>
                        <img src={srcL} alt='logo' loading="lazy"/>
                        <Tooltip title='Посмотреть PDF' placement='bottom' >
                            <a href={src5} target='_blank' rel='noreferrer' onDragStart={preventDragHandler}>Сертификат о страховании</a>
                        </Tooltip>
                    </div>
                </SwiperSlide>
                <SwiperSlide className='swiper-slide1'>
                    <div className='swiper-nz-container'>
                        <img src={srcL} alt='logo' loading="lazy"/>
                        <Tooltip title='Посмотреть PDF' placement='bottom'>
                            <a href={src6} target='_blank' rel='noreferrer' onDragStart={preventDragHandler}>Политика</a>
                        </Tooltip>
                    </div>
                </SwiperSlide>
                <SwiperSlide className='swiper-slide1'>
                    <div className='swiper-nz-container'>
                        <img src={srcL} alt='logo' loading="lazy"/>
                        <Tooltip title='Посмотреть PDF' placement='bottom'>
                            <a href={src7} target='_blank' rel='noreferrer' onDragStart={preventDragHandler}>Лист записи об изменении наименования</a>
                        </Tooltip>
                    </div>
                </SwiperSlide>
            </Swiper>
        </>
    )
}

