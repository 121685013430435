import React from "react"
import ButnotP from "../Styles/butnotP"
import RedditTextField from "./reddit-textfield"
import '../Styles/PayPage.css'
import '../Styles/Debt_form.css'
import html2canvas from 'html2canvas'
import { FaDownload } from "react-icons/fa"
import { MdArrowBack } from "react-icons/md"

const INN='1831167427'
const Account='40702810568000021825'
const BIC='049401601'
const CorrAccount='30101810400000000601'
const KPP='183201001'
const Recipient='Общество с ограниченной ответственностью Профессиональная коллекторская организация "Экспресс Коллекшн"'
const Bank='УДМУРТСКОЕ ОТДЕЛЕНИЕ N8618 ПАО СБЕРБАНК'

const requisites = {
  INN: INN,
  Account: Account,
  BIC: BIC,
  CorrAccount: CorrAccount,
  KPP: KPP,
  Recipient: Recipient,
  Bank: Bank,
}

const downloadImage = (blob, fileName) => {
  const fakelink = window.document.createElement('a')
  fakelink.style = 'display:none'
  fakelink.download = fileName
  fakelink.href = blob
  document.body.appendChild(fakelink)
  fakelink.click()
  document.body.removeChild(fakelink)
  fakelink.remove()
}

const handleCaptureClick = async (el, imageFileName) => {
  const canvas = await html2canvas(el);
  const dataURL = canvas.toDataURL('image/png', 1.0);
  downloadImage(dataURL, imageFileName);
};

const TableP = (props) => {
    const exportedRef = React.createRef()

    return <>
        <div ref={exportedRef} className='RequisitesTable' style={!props.hide ? {transform:'translateX(120px)', opacity:'0', zIndex:'0', transition:'all 320ms cubic-bezier(0.4, 0, 0.2, 1) 0ms'} : {transform:'translateX(0)', opacity:'100',zIndex:'100', transition:'all 320ms cubic-bezier(0.4, 0, 0.2, 1) 320ms'}}> 
          <div id='onetwo-one'>{requisites.Bank}</div>
          <p id='three-one'>БИК</p>
          <div id='four-one'>{requisites.BIC}</div>
          <div style={{display:'flex', flexDirection:'column'}} id='one-two'>
            <span>ИНН</span>
            <div>{requisites.INN}</div>
          </div>
          <div style={{display:'flex', flexDirection:'column'}} id='two-two'>
            <span>КПП</span>
            <div>{requisites.KPP}</div>
          </div>
          <p id='three-two'>К/сч</p>
          <div id='four-two'>{requisites.CorrAccount}</div>
          <div style={{display:'flex', flexDirection:'column'}} id='onetwo-three'>
            <span>Получатель</span>
            {requisites.Recipient}
          </div>
          <p id='three-three'>Р/сч</p>
          <div id='four-three'>{requisites.Account}</div>
          <div id='onefour-four'>{props.reqs}</div>
          {props.qr && <div id='five'>{props.qr}</div>}
        </div>
        <div className="flex-buttons">
          <button name='payback' id='ButtonPayBack' onClick={props.handleClick} style={!props.hide ? {transform:'translateX(120px)', opacity:'0', zIndex:'0', transition:'all 320ms cubic-bezier(0.4, 0, 0.2, 1) 100ms'} : {transform:'translateX(0)', opacity:'100',zIndex:'100', transition:'all 320ms cubic-bezier(0.4, 0, 0.2, 1) 100ms'}}><MdArrowBack style={{marginRight:'2px', paddingBottom:'2px', scale:'1.25'}}/> Создать новую квитанцию</button>
          <button onClickCapture={() => handleCaptureClick(exportedRef.current, 'Реквизиты Экспресс Коллекшн')} name='download' id='ButtonDownload' onClick={props.handleCaptureClick} style={!props.hide ? {transform:'translateX(120px)', opacity:'0', zIndex:'0', transition:'all 320ms cubic-bezier(0.4, 0, 0.2, 1) 100ms'} : {transform:'translateX(0)', opacity:'100',zIndex:'100', transition:'all 320ms cubic-bezier(0.4, 0, 0.2, 1) 100ms'}}>Скачать квитанцию <FaDownload style={{marginLeft:'3px', paddingBottom:'1px'}}/></button>
        </div>
    </>
}

const FormP = ({handleSubmit, hide, userdata, handleChange}) => {
    return <div className='formGroupD' id='paypage' style={ hide ? {transform:'translateX(-120px)', opacity:'0', zIndex:'0', pointerEvents:'all', transition:'all 320ms cubic-bezier(0.4, 0, 0.2, 1)'} : {transform:'translateX(0)', opacity:'100',zIndex:'6000', transition:'all 320ms cubic-bezier(0.4, 0, 0.2, 1) 320ms'}}>
      <label className='FormLabel' id='FormLabelP'>Сгенерируйте квитанцию на погашение задолженности</label>
      <form className='ECformD' onSubmit={handleSubmit}>
        <div >
          <RedditTextField id='name' type='text' label='ФИО полностью' required variant='filled' name='name' InputLabelProps={{ shrink: true }} value={userdata.name} onChange={handleChange}/>
        </div> 
        <div>
          <RedditTextField id='date' label='Дата рождения' required type='date' InputLabelProps={{ shrink: true }} variant='filled' name='date' value={userdata.date} onChange={handleChange} InputProps={{ disableUnderline:true }} inputProps={{ min: "1950-05-01", max: "2023-05-04"}}/>
        </div>
        <div>
          <RedditTextField variant='filled' type='text' name='dogovor' InputLabelProps={{ shrink: true }} label='Номер кредитного договора' value={userdata.dogovor} onChange={handleChange} id='dogovor'/>
        </div>
        <ButnotP type='submit' id='ButnotP2' name='Продолжить' style={{width:'200px', height:'59px', fontSize:'12pt', borderRadius:'5px'}}/> 
      </form> 
    </div>
}
  
export default React.memo(function TwoStep({hide, handleChange, handleSubmit, userdata, reqs, handleClick, qr, handleCaptureClick}) {
    return (
      <>
        <FormP hide={hide} handleChange={handleChange} handleSubmit={handleSubmit} userdata={userdata}/>
        <TableP qr={qr} hide={hide} requisites={requisites} handleClick={handleClick} reqs={reqs} handleCaptureClick={handleCaptureClick}/>
      </>
    )
})