import React from 'react'
import { useNavigate } from 'react-router-dom'
const AuthContext = React.createContext();
const { Provider } = AuthContext;

const AuthProvider = ({ children }) => {
    const history = useNavigate()

    const token = localStorage.getItem('token')
    const userInfo = localStorage.getItem('userInfo')
    const expiresAt = localStorage.getItem('expiresAt')
    const mail = localStorage.getItem('mail')

    const [authState, setAuthState] = React.useState({
        token,
        expiresAt,
        userInfo: userInfo ? JSON.parse(userInfo) : {},
    })

    const [mailState, setMailState] = React.useState({
        mail: mail ? JSON.parse(mail) : {}
    })

    const setMailInfo = ({ mail }) => {
        localStorage.setItem('mail', JSON.stringify(mail));
        setMailState({
            mail
        })
    }

    const setAuthInfo = ({ token, userInfo, expiresAt }) => {
        localStorage.setItem('token', token);
        localStorage.setItem(
            'userInfo',
            JSON.stringify(userInfo)
        )
        localStorage.setItem('expiresAt', expiresAt)
        setAuthState({
            token,
            userInfo,
            expiresAt,
        })
    };

    const logout = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('userInfo');
        localStorage.removeItem('expiresAt');
        setAuthState({
            token: null,
            expiresAt: null,
            userInfo: null
        });
        history('/login');
    };
    
    const isAuthenticated = () => {
        if (!authState.token && !authState.expiresAt) {
          return false;
        }
        return (
          new Date().getTime() / 1000 < authState.expiresAt
        );
    };

    const isAdmin = () => {
        if (authState.userInfo.role) {
            return authState.userInfo.role === 'admin';
        } else {
            return authState.userInfo.dataValues.role === 'admin'
        }
    };

    return (
        <Provider
            value={{
                authState,
                mailState,
                setAuthState: authInfo => setAuthInfo(authInfo),
                setMailState: mailInfo => setMailInfo(mailInfo),
                isAuthenticated,
                logout,
                isAdmin,
            }}
        >
            {children}
        </Provider>
    );
};

export {AuthContext, AuthProvider}