import React from 'react';
import { Link } from "react-router-dom";
import { AuthContext } from '../context/AuthContext';
// import { AuthContext } from "../context/AuthContext";
import src from '../Styles/ECLOGOSVG.svg'
import './Sidebar.css'

const navItems = [
    {
        label: 'Договоры',
        path: 'dc',
        allowedRoles: ['user']
    },
    // {
    //     label: 'История платежей',
    //     path: 'di', 
    //     allowedRoles: ['user']
    // },
    {
        label: 'Оплатить',
        path: 'dp',
        allowedRoles: ['user']
    },
    {
        label: 'Подать заявку',
        path: 'docs', 
        allowedRoles: ['user']
    },
    {
        label: 'Готовые справки',
        path: 'notes', 
        allowedRoles: ['user']
    },
    {
        label: 'Редакция',
        path: 'admin-articles', 
        allowedRoles: ['admin']
    },
    {
        label: 'Все посты',
        path: 'all-articles', 
        allowedRoles: ['admin']
    },
    {
        label: 'Все обращения',
        path: 'admin-page', 
        allowedRoles: ['admin']
    },
    {
        label: 'Отработанные заявки',
        path: 'admin-page-resolved', 
        allowedRoles: ['admin']
    },
    {
        label: 'Выдать справку',
        path: 'upload-notes', 
        allowedRoles: ['admin']
    },
    {
        label: 'Вакансии',
        path: 'upload-vacancy', 
        allowedRoles: ['admin']
    },
    {
        label: 'Кандидаты',
        path: 'get-candidates', 
        allowedRoles: ['admin']
    },
    {
        label: 'Вопросы клиентов',
        path: 'all-questions', 
        allowedRoles: ['admin']
    },
]

const NavItem = ({ navItem }) => {
    // const location = useLocation();
    // const isCurrentRoute = location.pathname === `/${navItem.path}`
    return (
        <Link to={navItem.path}>
            <div className='sbLink'>
                <span>
                    {navItem.label}
                </span>
            </div>
        </Link>
    )
}

const NavItemContainer = ({ children }) => (
    <div>{children}</div>
)

const Sidebar = () => {
    const auth = React.useContext(AuthContext)
    const {role} = auth.authState.userInfo.role ? auth.authState.userInfo : auth.authState.userInfo.dataValues
    return (
        <section className='SidebarSection'>
            <div className='SidebarLogo'>
                <a href='/'>
                    <img src={src} alt='dashboard-logo'/>
                </a> 
            </div>
            <div className='SidebarItems'>
                {navItems.map((navItem, i) =>(
                    <NavItemContainer key={i}>
                        {navItem.allowedRoles.includes(role) && (
                            <NavItem navItem={navItem} />
                        )}
                    </NavItemContainer>
                ))}
            </div>
        </section>
    )
}

export default Sidebar