import React from 'react'
import { BsEye } from 'react-icons/bs'
// import { MdDone, MdEdit, MdFlag } from 'react-icons/md'
// import { TextField } from '@mui/material'
import '../Styles/AdminPage.css'
import dayjs from 'dayjs'
import { Button, Container, Form, Navbar, NavDropdown } from 'react-bootstrap'
// import { BsPencilSquare } from 'react-icons/bs'
// import { NavLink } from 'react-router-dom'
// import ImageList from '@mui/material/ImageList';
// import ImageListItem from '@mui/material/ImageListItem';
import { FetchContext } from '../context/FetchContext'
import { useVirtualizer } from '@tanstack/react-virtual'
import { Modal } from 'react-bootstrap'
const linky = process.env.REACT_APP_LINKY

function ModalX({filenames}) {
    const fetchContext1 = React.useContext(FetchContext)
    const [show, setShow] = React.useState(false)
    const handleClose = () => setShow(false)
    const handleShow = () => setShow(true)

    const sendFile = async(NoteId) => {
        try {
            const { data } = await fetchContext1.authAxios.get(
                `api/download-notes/${NoteId}`,                 // было download-cv, не понятно для чего еще один api метод
                {responseType: 'arraybuffer'}
            )
            const blob = new Blob([data], {type: 'application/msword'})
            const downloadUrl = window.URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = downloadUrl;
            link.download = NoteId;
            document.body.appendChild(link);
            link.click();
            link.remove();
            setTimeout(() => URL.revokeObjectURL(downloadUrl), 7000);
        } catch (err) {
            console.log(err)
        }
    }
    const sendFilePDF = async(NoteId) => {
        try {
            const { data } = await fetchContext1.authAxios.get(
                `api/download-cv/${NoteId}`,
                {responseType: 'arraybuffer'}
            )
            const blob = new Blob([data], {type: 'application/pdf'})
            const downloadUrl = window.URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = downloadUrl;
            link.download = NoteId;
            document.body.appendChild(link);
            link.click();
            link.remove();
            setTimeout(() => URL.revokeObjectURL(downloadUrl), 7000);
        } catch (err) {
            console.log(err)
        }
    }

    return (
        <>
            <Button variant="primary" onClick={handleShow} style={{margin:'1rem', marginBottom: '2rem', marginLeft:'0rem', width:'320px'}}>
                Посмотреть файлы
            </Button>
            <Modal show={show} onHide={handleClose} contentClassName="modal-height" aria-labelledby="example-custom-modal-styling-title">
                <Modal.Body>
                    {filenames && filenames.length > 0 ? (
                        filenames.map((item, index) => (
                            <div key={index} className="modal-body">
                                <span>{item.filename}</span>
                                <button onClick={() => sendFile(item.filename)}>Скачать файл</button>
                            </div>
                        ))
                    ) : (
                        <p>Файлы не найдены.</p>
                    )}
 {/*                   {filenames  && filenames.length && filenames.map((item) => {
                        return (
                            <div key={item.filename} className="modal-body">
                                {item.mimetype === 'image/png' || item.mimetype === 'image/jpg' || item.mimetype === 'image/jpeg' || item.mimetype === 'image/heic' || item.mimetype === 'image/webp' ? <img src={`data:image/*;base64,${item.base64}`} loading='lazy' alt={item.filename} style={{position:'relative', minWidth:'320px', width:'100%', minHeight:'320px', height:'100%', objectFit:'scale-down'}} className="img-fluid" /> : ' '}
                                {item.mimetype === 'application/pdf' && <button onClick={() => sendFilePDF(item.filename)}>скачать</button> }
                                {item.mimetype === 'application/msword' || item.mimetype === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ? <button onClick={() => sendFile(item.filename)}>скачать</button> : ''}
                            </div>
                        )
                    })}*/}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Закрыть
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

// const CheckBoxx = ({request, checked, setChecked, setRequest}) => {
//     const handleChecked = (checked) => {
//         const newRequests = [...request]
//         if (checked) {
//             newRequests.splice(newRequests.indexOf(checked), 1)
//             setRequest(newRequests)
            
//         }
//     }   
//     return (
//         <Form.Check type='checkbox' id='check-api-policy1' style={{position:'relative', display:'flex', alignSelf:'flexEnd'}}>
//             <Form.Check.Input type={'checkbox'} id='check-api-p1' defaultChecked={checked} onClick={setChecked} onChange={(request) => handleChecked(checked, request)} isValid style={{position:'relative', display:'flex', alignSelf:'flexEnd', maxWidth:'24px', maxHeight:'24px', marginTop:'4px'}}></Form.Check.Input>
//             {/* <Form.Check.Label style={{color:'black', fontWeight:'600', letterSpacing:'0px', fontFamily:'Overpass, sans-serif'}}>Я ознакомлен с правилами подачи обращений</Form.Check.Label> */}
//         </Form.Check>
//     )
// }
// setRequest(({values}) => ({values: [...values, value]}))
        // } else {
            // setRequest(({values}) => ({values: values.filter(e => e !== value)}))
        // }
const CommentItem = ({id, filename, name, email, type, question}) => {
    
    return (
        <>
            <div className='AFirstRow'>
                <div>Запрос №{id}</div>
                {name ? <div>, клиент {name}</div> : <div>от пользователя {email}</div>}
            </div>
            <div className='ASecondRow'>
                <div className='LeftPart'>
                    {filename && filename.length ? <ModalX filenames={filename}/> : <p> </p>}
                        
                            {/* <div style={{fontWeight:'600'}}>{type}, файлы: {Object.keys(filename).map(file => filename.filename[file].join(', '))}</div>  */}
                            {/* <ImageList sx={{ width: 552, height: 164 }} cols={3} rowHeight={164}>
                                {filename.map((item) => (
                                    <ImageListItem key={item.filename}>
                                        {<img
                                            src={`data:image/*;base64,${item.base64}`}
                                            srcSet={`data:image/*;base64,${item.base64}`}
                                            alt={item.filename}
                                            loading="lazy"
                                            style={{width:'164px', height:'164px',resize:'contain'}}
                                        />}
                                    </ImageListItem>
                                    
                                ))}
                            </ImageList> */}
                        
                        
                    <div style={{fontWeight:'600'}}>{type}</div>
                    <div>Q: {question}</div>
                </div>
                <div className='RightPart'>
                    {/* <MdFlag style={{width: '32px'}}/> */}
                    {/* <CheckBoxx checked={checked} setRequest={setRequest} request={request} setChecked={setChecked}/> */}
                    {/* <MdDone style={{width:'32px'}}/> */}
                    {/* <MdEdit style={{width:'32px'}}/> */}
                </div>
            </div>
        </>
    )
}



// const TextComment = React.memo(({value, id, setter, commentLoading}) => {
//     const handleChange = (e) => {
//         const { value: inputValue } = e.target
//         setter(inputValue)
//     }
    
//     return (
//         <div className='Cleft'>
//             <TextField multiline value={value} onChange={handleChange} label='Оставить комментарий' minRows={1} maxRows={3} id={id} variant='outlined' name='Comment' InputLabelProps={{shrink: true}} inputProps={{style:{ color:'black', marginTop:0, marginBottom:-6}}} sx={{'& label':{mt:-0.5, ml:0.1, background:'white', outline:'8px solid white','&.Mui-focused':{ background:'white', outline:'8px solid white',color:'#28a745',marginLeft:0.1, fontSize:'medium',marginTop:-0.5,}}, my:2, width:'320px', '& .MuiOutlinedInput-root.Mui-focused':{'& .MuiOutlinedInput-notchedOutline': {border:'1px solid #28a745',boxShadow:'0 0 0 0.2rem rgba(40, 167, 69, 0.25)'}}, '& .MuiOutlinedInput-root:hover':{'& .MuiOutlinedInput-notchedOutline': {border:'1px solid #28a745'}}}}></TextField>
//             <button type='submit' style={{width:'50px', height:'50px'}}>{commentLoading === true ? (<Spinner animation="border" style={{marginTop:'6px'}}/>) : (<BsArrow90DegLeft/>)}</button>
//         </div>
//     )
// })

function AdminFeed({request, virtualRows, totalHeight, adminRef, setComment, comment, checked, setChecked, setRequest}) {
    return (
        <>
            <Container className='AdminFeed' ref={adminRef}>
                {request.length > 0 ? (
                    <div className='AdminFeedItems' style={{height: totalHeight, display:'block', position:'relative', width:'100%', overfflowX:'hidden'}}>
                        {virtualRows.map(({index, size, start}) => {
                            const virtualRow = request[index]
                            if (!virtualRow) return null
                            return (
                                <div key={virtualRow.ReqId} className='AdminFeedItem' style={{position:'absolute', top:'0', left:'0', width:'100%', height:`${size}px`, transform: `translateY(${start}px)`, borderBottom:'1px solid #bbb'}} index={index}>
                                    <CommentItem
                                        id = {virtualRow.ReqId}
                                        name = {virtualRow.Name}
                                        email = {virtualRow.Email}
                                        type = {virtualRow.Type}
                                        question = {virtualRow.Question}
                                        filename = {virtualRow.File}
                                        
                                        // checked={checked}
                                        // setChecked={setChecked}
                                        // setRequest={setRequest}
                                        // request={request}
                                    />
                                    {virtualRow.Comment ? (<div className='ASecondRow'>
                                        <div className='LeftPart'>
                                            <div style={{fontWeight:'600'}}>комментарий</div>
                                            <div>{virtualRow.Comment}</div>
                                        </div>
                                    </div>) : ''}
                                    <div className='ACommentRow'>
                                        {/* <div className='Cleft'> */}
                                            {/* <form onSubmit={(values) => handleComment(values, virtualRow.ReqId)} className='Cleft'> */}
                                                {/* <TextComment commentLoading={commentLoading} value={comment.Comment} setter={setComment} id={`Comment-${virtualRow.ReqId}`}/> */}
                                                {/* {commentSuccess ? <div>{commentSuccess}</div> : ''}
                                                {commentError ? <div>{commentError}</div> : ''} */}
                                            {/* </form> */}
                                        {/* </div> */}
                                        <div className='Cright'>
                                            <div>{dayjs(virtualRow.createdAt).format('D MMM, YYYY h:mm A')}</div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                ) : (
                    <div></div>
                )}
            </Container>
        </>
    )
}

function AdminPageResolved() {
    const fetchContext = React.useContext(FetchContext)
    const [AdminLine, setAdminLine] = React.useState([])
    const [request, setRequest] = React.useState([])
    const [sorted, setSorted] = React.useState({sorted: 'date', reversed: false})
    const [searchPhrase, setSearchPhrase] = React.useState('')
    const [comment, setComment] = React.useState('')
    const [checked, setChecked] = React.useState(false)

    const adminRef = React.useRef(null)
    const rowVirtualizer = useVirtualizer({
        count: request ? request.length : 1,
        // size: articles ? articles.length : 1,
        getScrollElement: React.useCallback(() => adminRef.current,[]),
        estimateSize: React.useCallback(()=>462,[]),
        overscan: 5,
    })

    React.useEffect(() => {
        const getDashboardData = async () => {
            try {

                const { data } = await fetchContext.authAxios.get(
                    `api/admin-reqs-done`,
                    {timeout: 12000}
                );
                setAdminLine(data);
                setRequest(data);
                // console.log(AdminLine.length)
            } catch (error) {
                console.log(error)
            }
        };
        getDashboardData();
    }, [fetchContext]);

    // const handleComment = async(e, id) => {
    //     e.preventDefault()
    //     const credentials = {
    //         Comment: comment,
    //         Id: id
    //     }
    //     try {
    //         if (credentials.Comment.length > 0) {
    //             setCommentLoading(true)
    //             // const {data} = await fetchContext.authAxios.post('api/set-comment', credentials)
    //             setCommentError('')
    //             setTimeout(() => {
    //                 setCommentSuccess(data.message)
    //                 setCommentError('')
    //                 setCommentLoading(false)
    //                 alert(commentSuccess)
    //                 setComment('')
    //                 window.location.reload(true)
    //             }, 1200)
    //         } else {
    //             setCommentLoading(false)
    //             alert('Комментарий не может быть пустым')
    //         }
    //     } catch (error) {
    //         setCommentLoading(false)
    //         const {data} = error.response
    //         setCommentError(data.message)
    //         alert(commentError)
    //         setCommentSuccess('')
    //     }
    // }

    const sortByDate = () => {
        // setRequest(AdminLine)
        setSorted({sorted:'createdAt', reversed: !sorted.reversed})
        const requestCopy = [...request]
        requestCopy.sort((reqA,reqB) => {
            // if (sorted.reversed) {
            //     return reqA.n - reqB.n
            // }
            return reqA.createdAt > reqB.createdAt ? -1 : 1
        })
        setRequest(requestCopy)
    }
    const sortByDateR = () => {
        // setRequest(AdminLine)
        setSorted({sorted:'createdAt', reversed: sorted.reversed})
        const requestCopy = [...request]
        requestCopy.sort((reqA,reqB) => {
            return reqB.createdAt > reqA.createdAt ? -1 : 1
        })
        setRequest(requestCopy)
    }
    // const Flagged = (x) => {
    //     if (x.flagged) {
    //         return setRequest.flagged(false) 
    //     }
    //     return setRequest.flagged(true)
    // }
    // const sortedByflag = () => {
    //     setSorted({sorted:'flagged',reversed: !sorted.reversed})
    //     const requestCopy = [...request]
    //     requestCopy.sort((reqA,reqB) => {
    //         if (sorted.reversed) {
    //             return reqA.flagged - reqB.flagged
    //         }
    //         return reqB.flagged - reqA.flagged
    //     })
    //     setRequest(requestCopy)
    // }
    const search = (event) => {
        event.preventDefault()
        if (AdminLine) {
            const matchedRequests = AdminLine.filter((request) => {
                return request.Type.toLowerCase().includes(event.target.value.toLowerCase())
            })
            setRequest(matchedRequests)
            setSearchPhrase(event.target.value)
        }
    }

    return (
        <React.Suspense>
            <div className='AdminContainer'>
            <Navbar bg='light' expand='lg' style={{minWidth:'100%', display:'flex', position:'relative'}}>
                <Container fluid>
                    <Navbar.Toggle aria-controls="navbarScroll"/>
                    <Navbar.Collapse id='navbarScroll' style={{justifyContent: 'space-between'}}>
                        <Form className="d-flex" style={{marginLeft:'5px'}}>
                            <Form.Control
                                type="search"
                                placeholder="Искать вопрос"
                                className="me-2"
                                aria-label="Search"
                                value={searchPhrase}
                                onChange={search}
                            />
                        </Form>
                        <span style={{fontWeight:'400', pointerEvents:'none'}}>Сортировка:</span>
                        <NavDropdown title='По дате' id='navbarscroll-date' style={{fontWeight:500, color:'#321e1e', fontSize:'smaller', marginRight:'12rem'}}>
                            <NavDropdown.Item style={{color:'black', fontWeight:400}} onClick={sortByDateR}>Вначале ранние</NavDropdown.Item>
                            <NavDropdown.Item style={{color:'black', fontWeight:400}} onClick={sortByDate}>Вначале новые</NavDropdown.Item>
                        </NavDropdown>
                        {/* <NavDropdown title='По статусу' id='navbarscroll-date' style={{fontWeight:500, color:'#321e1e', fontSize:'smaller'}}>
                            <NavDropdown.Item style={{color:'black', fontWeight:400}}>Разрешенные</NavDropdown.Item>
                            <NavDropdown.Item style={{color:'black', fontWeight:400}}>Неразрешенные</NavDropdown.Item>
                        </NavDropdown>
                        <NavDropdown title='Помеченные' id='navbarscroll-date' style={{fontWeight:500, color:'#321e1e', fontSize:'smaller'}}>
                            <NavDropdown.Item style={{color:'black', fontWeight:400}} onClick={sortedByflag}>Вначале</NavDropdown.Item>
                            <NavDropdown.Item style={{color:'black', fontWeight:400}}>Вконце</NavDropdown.Item>
                        </NavDropdown> */}
                        {/* <Nav className='' navbarScroll> */}
                        <a href={linky} target='__blank'><BsEye style={{scale:'1.5', marginRight:'0.3rem', color:'#42a5f5'}}/></a>
                        {/* </Nav> */}
                    </Navbar.Collapse>
                </Container>
            </Navbar>
                <AdminFeed request={request} virtualRows={rowVirtualizer.getVirtualItems()} totalHeight={rowVirtualizer.getTotalSize()} adminRef={adminRef} setComment={setComment} comment={comment} checked={checked} setChecked={setChecked} setRequest={setRequest}/>
            </div>
        </React.Suspense>
    )
}

export default React.memo(AdminPageResolved)
