import React from 'react'
import Footer from '../components/Footer'
import '../Styles/RequisitesPage.css'
import { tel } from '../Styles/colors'

function Requisites() {
    return (
        <React.Suspense>
            <h1 className='RQh1'>Реквизиты</h1>
            <div className='Requisites2cols'>
                <section className='RequisitesCol1'>
                    <span>Если Ваша задолженность была переуступлена ООО ПКО «Экспресс Коллекшн», денежные средства в погашение задолженности по договору необходимо перечислять на расчётный счёт ООО ПКО «Экспресс Коллекшн» по следующим реквизитам:</span>
                    <h3>Перевод на рассчётный счёт ООО ПКО «Экспресс Коллекшн»</h3>
                    <div className='RQtable'>
                        <div className='RQtableRow'>
                            <p>Получатель</p>
                            <p>Общество с ограниченной ответственностью Профессиональная коллекторская организация «Экспресс Коллекшн»</p>
                        </div>
                        <hr/>
                        <div className='RQtableRow'>
                            <p>Назначение платежа</p>
                            <p>Погашение задолженности по договору №(номер договора) (Ваши ФИО)</p>
                        </div>
                        <hr/>
                        <div className='RQtableRow'>
                            <p>ИНН</p>
                            <p>1831167427</p>
                        </div>
                        <hr/>
                        <div className='RQtableRow'>
                            <p>КПП</p>
                            <p>183201001</p>
                        </div>
                        <hr/>
                        <div className='RQtableRow'>
                            <p>БИК</p>
                            <p>049401601</p>
                        </div>
                        <hr/>
                        <div className='RQtableRow'>
                            <p>Р/сч</p>
                            <p>40702810568000021825</p>
                        </div>
                        <hr/>
                        <div className='RQtableRow'>
                            <p>К/сч</p>
                            <p>30101810400000000601</p>
                        </div>
                        <hr/>
                        <div className='RQtableRow'>
                            <p>Банк Получателя</p>
                            <p>Удмуртское отделение №8618 ПАО Сбербанк</p>
                        </div>
                    </div>
                </section>
                <section className='RequisitesCol2'>
                    <div className='RequisitesCol2wrapper'>
                        <h3>Анонимный звонок</h3>
                        <span>Вы можете получить бесплатную и анонимную консультацию по любым вопросам погашения задолженности. Мы подскажем, что надо сделать, чтобы получить рассрочку с прощением части долга.</span>
                        <div style={{display:'flex', flexDirection:'column',gap:'0', marginBottom:'32px', color:'#d9b173'}}>
                            <h3><a id='LPtel' href='tel:83412940707'>{tel}</a></h3>
                            <span>Пн-Пт, с 7-00 до 16-00 по МСК</span>
                        </div>
                        <a href='/debt'>
                            <button id='RQbutton'>Узнать задолженность безопасно</button>
                        </a>
                        <span>или пишите нам на почту <br/> <a href='mailto:collect@ec18.ru'>collect@ec18.ru</a></span>
                    </div>
                </section>
            </div>
            <Footer/>
        </React.Suspense>
    )
}

export default React.memo(Requisites)