import React from "react";
import { FaPlane, FaVk } from "react-icons/fa";
import Footer from "../components/Footer";
import '../Styles/InfoPage.css'
import { BsArrowRightShort } from "react-icons/bs";
import RedditTextField from "../components/reddit-textfield";
import { publicFetch } from "../util/fetch";
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat'
import 'dayjs/locale/ru'
import { Spinner } from "react-bootstrap";
import { useVirtualizer } from '@tanstack/react-virtual'
import src from '../Styles/ECLOGOCROP.png'
import { useNavigate } from 'react-router-dom';

dayjs.extend(customParseFormat)
dayjs.locale('ru')

// const getVirtualRowStyles = ({start}) => ({
//     position: 'relative',
//     top: 0,
//     left: 0,
//     width: '100%',
//     height: `700px`,
//     transform: `translateX(${start}px)`,
//     '-ms-transform': `translateX(${start}px)`,
//     '-webkit-transform': `translateX(${start}px)`,
// })

function BlogFeed({articles, picLoading, virtualRows, totalHeight, listRef}) {

    const handleReadMoreClick = (postId, index) => {
        sessionStorage.setItem('postIndex', index);
        // Логика перехода к статье
    };

    return (
        <>
            <div className="BlogWrapper" ref={listRef} >
                <div style={{height: totalHeight, display:'block', position:'relative', width:'100%', overfflowX:'hidden'}}>
                    {articles ? virtualRows.map(({index, size, start}) => {
                        const virtualRow = articles[index];
                        if (!virtualRow) return null
                        return (
                            <div key={virtualRow.postId} style={{position:'absolute', top:'0', left:'0', width:'100%', height:`${size}px`, transform: `translateY(${start}px)`}} index={index}>
                                <div className="TopicRow">
                                    <span className="Topic">{virtualRow.topic}</span>
                                    <span className="Date">{dayjs(virtualRow.date).format('DD MMMM, YYYY')}</span>
                                </div>
                                <h3 className="iTitle">{virtualRow.title}</h3>
                                <div className="PostGrid">
                                    <span className="shortDesc">{virtualRow.shortDesc}</span>
                                    <div className="PicContainer">{picLoading ? <Spinner animation="border" style={{margin:'auto', top:'45%', display:'flex', position:'relative', color:'#d9b173'}}/> : <img src={virtualRow.postPic} alt={virtualRow.postPic}/>}</div>
                                    <a href={`/article/${virtualRow.postId}`}><button className='PostGridButton' onClick={() => handleReadMoreClick(virtualRow.postId, index)}>Читать далее<BsArrowRightShort className="PostGridIcon"/></button></a>
                                </div>
                            </div>
                        )
                    }) : ('')}
                </div>
            </div>
        </> 
    )
}

function InfoPage() {
    const [picLoading, setPicLoading] = React.useState(false)
    const [qloading, setQloading] = React.useState(false)
    const [questions, setQuestions] = React.useState('')
    const [articles, setArticles] = React.useState()
    const [values, setValues] = React.useState({
        clientQuestion: '',
    })
    const [qSuccess, setQSuccess] = React.useState('')
    const [qError, setQError] = React.useState('')

    const listRef = React.useRef(null)
    const rowVirtualizer = useVirtualizer({
        count: articles ? articles.length : 1,
        // size: articles ? articles.length : 1,
        getScrollElement: React.useCallback(() => listRef.current,[]),
        estimateSize: React.useCallback(()=>700,[]),
        overscan: 10,
    })

    React.useEffect(() => {
        const getArticlesData = async () => {
            try {
                setPicLoading(true)
                const {data} = await publicFetch.get(`/api/get-articles`)
                setArticles(data)
                setTimeout(() => {
                    setPicLoading(false)
                }, 700);
            } catch (err) {
                setPicLoading(false)
                return console.log(err)
            };
        };
        getArticlesData();
        const getQuestionsData = async () => {
            try {
                setQloading(true)
                const { data } = await publicFetch.get(`/api/client-question-answer`)
                setQuestions(data)
                
                setTimeout(() => {
                    setQloading(false)
                    console.log(data)
                }, 700)
            } catch (error) {
                setQloading(false)
                return console.log(error)
            }
        };
        getQuestionsData();
    }, []);

    React.useEffect(() => {
        const postIndex = sessionStorage.getItem('postIndex');
        if (postIndex !== null) {
            setTimeout(() => {
                rowVirtualizer.scrollToIndex(parseInt(postIndex));
                sessionStorage.removeItem('postIndex');
            }, 100); // Задержка в 100 миллисекунд
        }
    }, [rowVirtualizer]);

    const handleChange = (e) => {
        setValues({
            ...values,
            [e.target.name]: e.target.value,
        });
    };

/*    const submitQuestion = async credentials => {
        credentials.preventDefault()
        const Credentials = {
            clientQuestion : values.clientQuestion,
        }
        try {
            const {data} = await publicFetch.post(`api/client-question`, Credentials)
            setQError('');
            setTimeout(() => {
                setQSuccess(data.message);
                alert(qSuccess);
            }, 700);
        } catch (err) {
            console.log(err);
            const {data} = err.response;
            setQError(data.message);
            setQSuccess('');
            alert(qError);
        };
    };*/
    const navigate = useNavigate();
    const goToSupportPage = () => {
        navigate('/support');
    };


    /*onSubmit={values => submitQuestion(values)}*/ // Вырезал из формы для отправки
    return (
        <React.Suspense>
            <div className="InfoGrid">
                <div className="BlogFeed">
                    <div className="FeedHeader">
                        <h2>Новости</h2>
                        <a href='https://vk.com/club217586890' target='_blank' rel='noreferrer'><FaVk className="VK"></FaVk></a>
                    </div>
                    {articles ? (
                        <>
                            <BlogFeed  articles={articles} picLoading={picLoading} virtualRows={rowVirtualizer.getVirtualItems()} totalHeight={rowVirtualizer.getTotalSize()} listRef={listRef}/>
                        </>
                    ) : (<Spinner animation='border' style={{color:'#d9b173', display:'flex', position:'relative', margin:'auto'}}/>)}
                </div>
                <div className="QuestionFeed">
                    <div className="QuestionFeedWrapper">
                        <section className="QFthird">
                            <div className="QFavatar">
                                <img src={src} alt='EClogoQ'/>
                            </div>
                            <div style={{display:'flex', gap:'3px', alignItems:'center', height:'fit-content'}}>
                                <p>Экспресс Коллекшн</p>
                                <div className="QFonline"/>
                            </div>
                        </section>
                        <section className="QFup">
                            <form style={{display:'flex', flexDirection:'column', gap:'6px'}}>
                                <label>Ваш вопрос может быть полезен</label>
                                <div style={{display:'flex',flexDirection:'row', gap:'10px'}}>
                                    <RedditTextField multiline minRows={1} maxRows={1} variant='filled' value={values.clientQuestion} disabled={true} onChange={handleChange} type='text' placeholder='Задайте его нам здесь' name='clientQuestion' id='clientQuestion' InputProps={{style: {paddingTop:'10px', color:'#0b271e'}, disableUnderline:'true'}} sx={{alignSelf:'center', marginTop:'0', paddingBottom: '6px'}}/>
                                    <button type='button' id="planeButton" onClick={goToSupportPage}><FaPlane/></button>
                                </div>
                            </form>
                        </section>
                        <section className="QFdown">
                            
                            {questions && questions.map((item, index) => {
                                return (
                                    <>
                                        <div className="qMessage" key={index.id}>
                                            <div style={{display:'flex',flexDirection:'row', gap:'0.5rem', position:'relative', justifyContent:'end'}}>
                                                <span>{dayjs(item.createdAt).format('DD MMMM, hh:mm')}</span>
                                                <p>Клиент</p>
                                            </div>
                                            <div className="qBox">
                                                <span>{item.Question}</span>
                                            </div>
                                        </div>
                                        <div className="aMessage">
                                            <div className="aBox">
                                                <span>{JSON.parse(item.Answer)}</span>
                                            </div>
                                            <div style={{display:'flex',flexDirection:'row', gap:'0.5rem', position:'relative', justifyContent:'start'}}>
                                                <p>ЭК</p>
                                                <span>{dayjs(item.updatedAt).format('DD MMMM, hh:mm')}</span>
                                            </div>
                                        </div>
                                    </> 
                                )
                            })}
                            <span style={{fontSize:'small', textAlign:'center', opacity:'80%'}}>Получите ответ на свой вопрос</span>
                        </section>
                    </div>
                </div>
            </div>
            <Footer/>
        </React.Suspense>
    )
}

export default React.memo(InfoPage)