import React from 'react'
import { FetchContext } from '../context/FetchContext'
import { Button, Input, TextField } from '@mui/material'
import { Spinner } from 'react-bootstrap'
import { BsArrow90DegLeft } from 'react-icons/bs'
import { useVirtualizer } from '@tanstack/react-virtual'
import dayjs from 'dayjs'
import { FaArrowUp } from 'react-icons/fa'
import '../Styles/AdminPage.css'

const RequestItem = ({id, name, email, question, type}) => {
    return (
        <>
            <div className='AFirstRow'>
                <div>Зарпос №{id}</div>
                {name ? <div>, клиент {name} {email}</div> : <div>от пользователя {email}</div>}
            </div>
            <div className='ASecondRow'>
                <div className='LeftPart'>
                    <div style={{fontWeight:'600'}}>{type}</div>
                    <div>{question}</div>
                </div>
            </div>
        </>
    )
}

const TextComment = React.memo(({value, id, setter}) => {
    const handleChange = (e) => {
        const { value: inputValue } = e.target
        setter(inputValue)
    }
    
    return (
        <div className='Cleft'>
            <TextField multiline value={value} onChange={handleChange} label='Оставить комментарий' minRows={1} maxRows={3} id={id} variant='outlined' name='Comment' InputLabelProps={{shrink: true}} inputProps={{style:{ color:'black', marginTop:0, marginBottom:-6}}} sx={{'& label':{mt:-0.5, ml:0.1,outline:'8px solid white', backgroundColor:'white','&.Mui-focused':{ background:'white',outline:'8px solid white',color:'#28a745',marginLeft:0.1, fontSize:'medium',marginTop:-0.5,}}, my:2, width:'320px', '& .MuiOutlinedInput-root.Mui-focused':{'& .MuiOutlinedInput-notchedOutline': {border:'1px solid #28a745',boxShadow:'0 0 0 0.2rem rgba(40, 167, 69, 0.25)'}}, '& .MuiOutlinedInput-root:hover':{'& .MuiOutlinedInput-notchedOutline': {border:'1px solid #28a745'}}}}></TextField>
        </div>
    )
})

function RequestsFeed({track, document, handleChange1, adRef, request, commentLoading, virtualRows, totalHeight, handleComment, setComment, comment}) {
    return (
        <>
            <div className='AdminFeed' ref={adRef}>
                {request ? (
                    <div className='AdminFeedItems' style={{height: totalHeight, display:'block', position:'relative', width:'100%', overfflowX:'hidden'}}>
                        {virtualRows.map(({index, size, start}) => {
                            const virtualRow = request[index]
                            if (!virtualRow) return null
                            return (
                                <div key={virtualRow.ReqId} className='AdminFeedItem' style={{position:'absolute', top:'0', left:'0', width:'100%', height:`${size}px`, transform: `translateY(${start}px)`, borderBottom:'1px solid #bbb'}} index={index}>
                                    <RequestItem
                                        id = {virtualRow.ReqId}
                                        name = {virtualRow.Name}
                                        email = {virtualRow.Email}
                                        type = {virtualRow.Type}
                                        question = {virtualRow.Question}
                                    />
                                    <div className='ACommentRow' style={{margin:'1rem'}}>
                                        <div className='Cleft'>
                                            <form onSubmit={(values) => handleComment(values, virtualRow.ReqId)} className='Cleft'>
                                                <TextComment value={comment.Comment} setter={setComment} id={`comment-${virtualRow.ReqId}`}/>
                                                {/* <label htmlFor={`doc${virtualRow.ReqId}`}>Документы для скачивания</label> */}
                                                <div style={{display:'flex', flexDirection:'column', gap:'1rem', margin:'1rem', alignSelf:'center', justifyContent:'space-between'}}>
                                                    <Button id={`upd${virtualRow.ReqId}`} startIcon={<FaArrowUp style={{width:'12px'}}/>} variant='contained' component='label' style={{alignSelf:'start', background:'hsl(122 39% 49% / 0.7)', height:'42px', width:'320px', fontSize:'8.5pt', position:'relative', color:'white', fontWeight:'300'}}>
                                                        Прикрепить файл
                                                        <Input type='file' id={`upd${virtualRow.ReqId}`} name='doc' onChange={e =>  handleChange1(e, virtualRow.ReqId)} hidden/>
                                                    </Button>
                                                    {document && (track === virtualRow.ReqId) ? <p style={{display:'flex',position:'relative',color:'#d9b173', fontSize:'small', paddingBottom:'1rem'}}>{document.name}</p>: ''}
                                                    {/* <p style={{color:'white', fontSize:'small'}}>{file.name}<MdClose style={{color:'red', size:'52px', marginLeft:'10px'}} onClick={(event) => handleCloseFile(event)} /></p> : ''} */}
                                                </div>
                                                <button type='submit' style={{width:'50px', height:'50px'}}>{commentLoading === true ? (<Spinner animation="border" style={{marginTop:'6px'}}/>) : (<BsArrow90DegLeft/>)}</button>
                                                
                                            </form>
                                            
                                        </div>
                                        <div className='Cright'>
                                            <div>{dayjs(virtualRow.createdAt).format('D MMM, YYYY h:mm A')}</div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                ) : (
                    <Spinner animation="border" style={{margin:'auto', display:'flex', position:'relative', marginTop:'30%'}}/>
                )}
            </div>
        </>
    ) 
}

function AdminPageNotes() {
    const fetchContext = React.useContext(FetchContext)
    // const [allRequests, setAllRequests] = React.useState([])
    const [commentLoading, setCommentLoading] = React.useState(false)
    const [commentSuccess, setCommentSuccess] = React.useState('')
    const [commentError, setCommentError] = React.useState('')
    const [comment, setComment] = React.useState('')
    const [request, setRequest] = React.useState('')
    const [track, setTrack] = React.useState('')

    const [document, setDocument] = React.useState([])
    const handleChange1 = (e, id) => {
        e.preventDefault()
        setDocument(e.target.files[0])
        setTrack(id)
        // console.log(document)
    }

    const adRef = React.useRef(null)
    const rowVirtualizer = useVirtualizer({
        count: request ? request.length : 1,
        getScrollElement: React.useCallback(() => adRef.current,[]),
        estimateSize: React.useCallback(()=>278,[]),
        overscan: 2,
    })

    React.useEffect(() => {
        const getQuestionData = async () => {
            try {
                const { data } = await fetchContext.authAxios.get(
                    `api/notes-request`
                );
                // setAllRequests(data);
                return setRequest(data);
            } catch (err) {
                console.log(err)
            }
        };
        getQuestionData();
    }, [fetchContext]);

    const handleComment = async(e, id) => {
        e.preventDefault()
        const formData = new FormData()
        
        try {
            if (document && comment.length > 0) {
                formData.append('comment', comment)
                formData.append('note', document)
                setCommentLoading(true)
                const { data } = await fetchContext.authAxios1.post(`api/upload-notes/${id}`, formData)
                setCommentError('')
                setTimeout(() => {
                    setCommentSuccess(data.message)
                    setCommentError('')
                    setCommentLoading(false)
                    alert(commentSuccess)
                    setComment('')
                    window.location.reload(true)
                }, 1200)
            } else if (document && !comment) {
                formData.append('document', document)
                setCommentLoading(true)
                const { data } = await fetchContext.authAxios1.post(`api/upload-notes/${id}`, formData)
                setCommentError('')
                setTimeout(() => {
                    setCommentSuccess(data.message)
                    setCommentError('')
                    setCommentLoading(false)
                    alert(commentSuccess)
                    setComment('')
                    window.location.reload(true)
                }, 1200)
            } else {
                formData.append('comment', comment)
                setCommentLoading(true)
                const { data } = await fetchContext.authAxios1.post(`api/upload-notes/${id}`, formData)
                setCommentError('')
                setTimeout(() => {
                    setCommentSuccess(data.message)
                    setCommentError('')
                    setCommentLoading(false)
                    alert(commentSuccess)
                    setComment('')
                    window.location.reload(true)
                }, 1200)
            }
        } catch (error) {
            setCommentLoading(false)
            const { data } = error.response
            setCommentError(data.message)
            alert(commentError)
            setCommentSuccess('')
        }
    }

    return (
        <React.Suspense>
            <div className='AdminContainer' id='AArticles'>
                <RequestsFeed track={track} document={document} handleChange1={handleChange1} request={request} commentLoading={commentLoading} virtualRows={rowVirtualizer.getVirtualItems()} totalHeight={rowVirtualizer.getTotalSize()} adRef={adRef} handleComment={handleComment} setComment={setComment} comment={comment} />
            </div>
        </React.Suspense>
    )
}

export default AdminPageNotes