import { MenuItem } from '@mui/material'
import React from 'react'
import { Form } from 'react-bootstrap'
import ScrollDialog from '../components/Dialog'
import Footer from '../components/Footer'
import RedditTextField from '../components/reddit-textfield'
import ButnotS from '../Styles/butnotS'
import '../Styles/SupportPage.css'
import { publicFetch } from '../util/fetch'
import { useDropzone } from "react-dropzone";
import { MdClose } from "react-icons/md";
import '../components/DropZone.css'
import { Modal } from 'react-bootstrap'

const questionType = [
  {
  id: 1,
  value: 'Не могу зайти в личный кабинет'
  },
  {
  id: 2,
  value: 'Забыл пароль'
  },
  {
  id: 3,
  value: 'Получить справку об отсутствии задолженности'
  },
  {
  id: 4,
  value: 'Не помню логин'
  },
  {
  id: 5,
  value: 'Документы'
  },
  {
  id: 6,
  value: 'Исполнительное происзводство (ИП)'
  },
  {
  id: 7,
  value: 'Возврат средств'
  },
  {
  id: 8,
  value: 'Выкуп долга'
  },
  {
  id: 9,
  value: 'Передать информацию в БКИ'
  },
  {
  id: 10,
  value: 'Пожаловаться'
  },
  {
  id: 11,
  value: 'Другое...'
  },
]

const SupportPageMain = () => {
  const [type, setType] = React.useState('')
  const [file, setFile] = React.useState([])
  const [open, setOpen] = React.useState(false)
  const [supportSuccess, setSupportSuccess] = React.useState()
  const [supportError, setSupportError] = React.useState()
  const [checked, setChecked] = React.useState(false)
  const [checked1, setChecked1] = React.useState(false)
  const [setter, setSetter] = React.useState()

  const [isLoading, setIsLoading] = React.useState(false);

  const handleChange = (e) => setType(e.target.value);
  const handleClose = () => setOpen(false);
  const handleOpen = () => setOpen(true);
  
  const [supportData, setSupportData] = React.useState({
    questiontype: '',
    question: '',
    email: '',
    name: '',
    surname: '',
    patronymic: '',
    ip: '',
    file: '',
  })

  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(supportData.email);
  }
  // const handleCloseFile = (event) => {
  //   event.traget.value = null
  // }
  const handleChange1 = (e) => {
    e.preventDefault()
    const value = e.target.value
    setSupportData({
      ...supportData,
      [e.target.name]: value
    })
  };

  const submitCredentials = async (credentials) => {
    credentials.preventDefault()
    const formData = new FormData()
    Object.keys(file).forEach(index => formData.set(file[index].path, file[index]))
    for (const pair of formData.entries()) {
      console.log(pair)
    };
    formData.append('questiontype', type)
    formData.append('question', supportData.question)
    formData.append('email', supportData.email)
    formData.append('name', supportData.name)
    formData.append('surname', supportData.surname)
    formData.append('patronymic', supportData.patronymic)
    formData.append('ip', supportData.ip)
    // const formData2 = formData.append('file', file)
    console.log(file);
    setIsLoading(true);
    try {
      if (checked === true) {
        // setLoginLoading(true)
        const { data } = await publicFetch.post(`api/support`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
        setSupportSuccess(data.message)
        setSupportError('')
        setIsLoading(false);
        handleShow();
      } else {
        setSupportError(`Чтобы продолжить, Вы должны быть согласны с условиями обработки персональных данных`)
        setIsLoading(false);
        handleShow();
      }
    } catch (error) {
      const { data } = error.response;
      setSupportError(data.message);
      setSupportSuccess('');
      setIsLoading(false);
      handleShow();
    }
  }

  const CheckBox = ({setChecked1}) => {
    return (
      <Form.Check type='checkbox' id='check-api-policy1'>
        <Form.Check.Input type={'checkbox'} id='check-api-p1' defaultChecked={checked1} onClick={setChecked1} isValid style={{maxWidth:'24px', maxHeight:'24px', marginTop:'4px'}}></Form.Check.Input>
        <Form.Check.Label style={{color:'black', fontWeight:'600', letterSpacing:'0px', fontFamily:'Overpass, sans-serif'}}>Я ознакомлен с правилами подачи обращений</Form.Check.Label>
      </Form.Check>
    )
  }
  
  const formatBytes = (bytes, decimals = 2) => {
    if (!+bytes) return '0 Bytes'
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
  };

  const onDrop = React.useCallback(acceptedFiles => {
    if (acceptedFiles) {
        // acceptedFiles.forEach((file) => {
        //     const formData = new FormData()
        //     formData.append('file', file)
        //     setImages([...images, ...formData])
        // })
        setFile([...file, ...acceptedFiles])
        // console.log(images)
    } else {
        setFile('')
    }  
  }, [file]);

  const { getRootProps, getInputProps, isDragActive, acceptedFiles } = useDropzone({
    onDrop,
   /* accept: {
      'image/!*': ['.png', '.pdf', '.jpeg', '.jpg'],
      // 'application/pdf': ['.pdf']
    }*/
  });

  const formData2 = new FormData()
      // eslint-disable-next-line no-unused-vars
  const fileObjects = acceptedFiles.map((file) => {
    console.log(file)
    return formData2.append('assets', file, file.name)
  })

  const removeFile = files => () => {
    const newFiles =[...file]
    newFiles.splice(newFiles.indexOf(files), 1)
    console.log(file.length)
    setFile(newFiles)
  }

  const files = file.map((file) => (
    <li key={file.path} >
        <span>{file.path}</span> - {formatBytes(file.size)}
        <MdClose style={{color: 'hsl(4 90% 58% / 0.7)', marginTop:'3px'}} onClick={removeFile(file)}/>
    </li>
  ));

  React.useEffect(() => {
    const getArticleData = async () => {
        try {
            const {data} = await publicFetch.get(`api/get-articles-mp`)
            setSetter(data)
        } catch (err) {
            return console.log(err)
        }
    };
    getArticleData()
  }, []);


  const [show, setShow] = React.useState(false)
  const handleCloseModal = () => {
    if (supportSuccess) {
      // Если есть сообщение об успехе, перезагрузите страницу
      window.location.reload();
    } else {
      // Иначе, просто закройте модальное окно
      setShow(false);
    }
  };
  const handleShow = () => setShow(true)

  return (
    <React.Suspense>
      <div className='TwoRowsSupport' style={{minHeight:'105vh'}}>
        {checked1 === true ? (<div className='SupportContainer' >
          <form onSubmit={(values) => submitCredentials(values)} className='SupportContainer'>
            <p>Добро пожаловать в <br/>интернет приёмную</p>
              <RedditTextField select required value={type} onChange={handleChange} id='questiontype' label='Выберите тему обращения...' variant='filled' name='questiontype' open={open} onClose={handleClose} onOpen={handleOpen}>
                {questionType.map((keyName) => {
                  return (
                    <MenuItem key={keyName.id} value={keyName.value}>
                      {keyName.value}
                    </MenuItem>
                  )
                })}
              </RedditTextField>
              {type==='Забыл пароль' ? <a href='reset' rel='noreferrer' style={{textDecoration: 'none', color:'#81d4fa'}}>Восстановить  пароль</a> : ''}
              <div className='SupportRowColumn'>
              <RedditTextField onChange={handleChange1} required type='text' id='surname-support' variant='filled' name='surname' value={supportData.surname} label='Ваша фамилия'/>
                <RedditTextField onChange={handleChange1} required type='text' id='name-support' variant='filled' name='name' value={supportData.name} label='Ваше имя'/>
                <RedditTextField onChange={handleChange1} type='text' id='patronymic-support' variant='filled' name='patronymic' value={supportData.patronymic} label='Ваше отчество'/>
              </div>
              <div style={{display:'flex', flexDirection:'row', gap:'12px'}}>
                <RedditTextField onChange={handleChange1} type='numeric' id='ip-support' name='ip' variant='filled' value={supportData.ip} label='№ исполнительного производства'/>
              </div>
              <RedditTextField multiline required value={supportData.question} onChange={handleChange1} minRows={2} maxRows={2} id='question' label='Расскажите, как мы можем вам помочь?' variant='filled' name='question' InputLabelProps={{right:'unset'}}></RedditTextField>
              <RedditTextField required error={isValidEmail ? false : true} value={supportData.email} onChange={handleChange1} type='email' id='email1' label='Укажите корректный email для связи' variant='filled' name='email' ></RedditTextField>
              <section className="DropContainer" id='DropzoneSupport'>
                  <div {...getRootProps({ className: "dropzone", id:'DropzoneS' })}  >
                      <input type='file' name='files' id='files' multiple className="input-zone" {...getInputProps()} onDrop={onDrop}/>
                      <div className="text-center">
                          {isDragActive ? (
                              <p>
                                  Отпустите чтобы загрузить
                              </p>
                          ) : (
                              <p>
                                  Перенесите файлы сюда, или нажмите на любую область зоны чтобы выбрать
                              </p>
                          )}
                          <button type="button" id='DropSupport' onClick={open}>
                              Выбрать файлы
                          </button>
                      </div>
                  </div>
                  {file.length > 0 ? (
                      <aside className="A-side" style={{boxShadow: 'inset 0 0px 7px hsl(153, 36%, 36%, 0.75)'}}>
                          <ul>{files}</ul>
                      </aside>
                  ) : (
                      <aside className="A-side">
                          <ul>{files}</ul>
                      </aside>
                  )} 
              </section>
              <ScrollDialog checked={checked} setChecked={setChecked} id='CheckBoxS'/>
              <hr style={{height:'1px', width:'500px',color:'#d9b173'}}/>
              {isLoading && (
                  <div className="spinner"></div>
              )}
              {/*              {supportSuccess && (
                  <p className='supportMessageS'>{supportSuccess}</p>
              )}
              {supportError && (
                  <p className='supportMessageE'>{supportError}</p>
              )}*/}
              <ButnotS type='submit' id='ButnotSS' name='Продолжить' onClick={handleShow} style={{width:'175px', height:'40px', fontSize:'13pt', borderRadius:'5px', alignSelf:'start'}}/>
            <Modal show={show} onHide={handleCloseModal}>
              <Modal.Body style={{minWidth: 'auto', textAlign: 'center'}}>
                {supportSuccess && (
                    <div>
                      <p className='supportMessageS'>{supportSuccess}</p>
                      <button onClick={handleCloseModal} className='ModalButton'>Закрыть</button>
                    </div>
                )}
                {supportError && (
                    <div>
                      <p className='supportMessageE'>{supportError}</p>
                      <button onClick={handleCloseModal} className='ModalButton'>Закрыть</button>
                    </div>
                )}
              </Modal.Body>
            </Modal>
{/*            <button type='submit' onClick={handleShow}>MODAL</button>*/}
          </form>
        </div>) : (
          <div className='FrequentQuestions' id='FQAlignPolicy'>
            <div className='FQAlign' >
              <h3>Добро пожаловать в интернет приёмную</h3>
              <p>1. При написании обращения просим Вас грамотно и по существу интересующих вопросов сформулировать текст обращения – это ускорит его обработку и подготовку ответа.</p>
              <p>2. Пожалуйста, помните, что обращения граждан без указания фамилии, имени, отчества или полного почтового адреса, рассмотрению не подлежат. Они будут приняты к сведению.</p>
              <p>3. Также обращение не принимается к рассмотрению по существу, если в нем содержатся нецензурные либо оскорбительные выражения, угрозы жизни, здоровью и имуществу сотрудников компании, а также членов их семей, если текст обращения не поддается прочтению или не содержит конкретных заявлений, жалоб, предложений.</p>
              <p>4. Если ваше обращение корректно, оно будет зарегистрировано и предоставлен ответ в течение 10 дней. В противном случае Вы получите письмо с указанием причин отказа в рассмотрении. Отправляя обращение, Вы даете согласие на обработку своих персональных данных.</p>
              <CheckBox setChecked1={() => setChecked1(!checked1)}/>
            </div> 
          </div>
          )}
        {/*<div className='FrequentQuestions' id='FQAlignQuestions'>
          <div className='FQAlign' id='FQrows' >
            <h3 style={{color:'#0a2540'}}>Полезная информация</h3>
            {setter && setter.map((item, i) => {
              return <div key={setter[i].postId}>
                <a href={`/article/${item.postId}`}><span>{item.title}</span></a>
              </div>
            })}
          </div>
        </div>*/}
      </div>
      <Footer/>
    </React.Suspense>
  )
}

export default React.memo(SupportPageMain)