import React from 'react'
import { AuthContext } from '../context/AuthContext'
import { publicFetch } from '../util/fetch'
import { Navigate, useNavigate } from 'react-router-dom'
import { Container, Spinner } from 'react-bootstrap'
import RedditTextField from '../components/reddit-textfield'
import ButnotP from '../Styles/butnotP'
import src from '../Styles/ECLOGOSVG.svg'
import '../Styles/signUp.css'

function Authorization() {
  const authContext = React.useContext(AuthContext)
  const [signupSuccess, setSignupSuccess] = React.useState()
  const [signupError, setSignupError] = React.useState()
  const [redirectOnLogin, setRedirectOnLogin] = React.useState(false)
  const [loginLoading, setLoginLoading] = React.useState(false)
  const history = useNavigate()

  const [authCode, setAuthCode] = React.useState({
    sixdigits: '',
  })

  const handleChange = (e) => {
    e.preventDefault()
    const value = e.target.value
    setAuthCode({
      ...authCode,
      [e.target.name]: value
    })
  };

  const submitCredentials = async credentials => {
    credentials.preventDefault()
    const Credentials = {
      sixdigits: authCode.sixdigits,
      email: localStorage.getItem('mail')
    }
    try {
      setLoginLoading(true)
      const {data} = await publicFetch.post(`api/authorize`, Credentials);
      authContext.setAuthState(data);
      setSignupSuccess(data.message);
      setSignupError('');
      setTimeout(() => {
          setRedirectOnLogin(true)
      }, 1100);
    } catch (err) {
      setLoginLoading(false);
      const { data } = err.response;
      setSignupError(data.message);
      setSignupSuccess('')
    }
  }

  const pushHistory = () => {
    history('/signup')
  }

  return (
    <div style={{minHeight:'100vh', maxHeight:'200vh'}}>
      {redirectOnLogin && !authContext.isAdmin() ? <Navigate to='/dc' /> : ''}
      {redirectOnLogin && authContext.isAdmin() ? <Navigate to='/admin-page' /> : ''}
      <a href='/'><img src={src} className='imgLogo' alt='imglogo'/></a>
      <Container className='FormGroupR' style={{width:'580px'}}>
        <h1>Мы отправили проверочный код на указанный email</h1>
        <form onSubmit={values => submitCredentials(values)} className='ECformR'>
          <div >
            <RedditTextField required id='sixdigits' type='text' label='Введите код' variant='filled' name='sixdigits' value={authCode.sixdigits} onChange={handleChange} />
          </div>
          <ButnotP type='submit' id='ButnotP6' name={loginLoading === true ? (<Spinner animation="border" style={{marginTop:'6px', scale:'80%'}}/>) : (<span>Продолжить</span>)} loading={loginLoading} />
        </form>
        {signupSuccess && (
          <p id='successM' style={{fontSize:'medium', color:'#81c784'}}>{signupSuccess}</p>
        )}
        {signupError && (
          <p id='errorM' style={{fontSize:'medium', fontWeight:400, color:'#f44336'}}>{signupError}<a href='/support'  style={{marginLeft:'1rem', fontWeight:'400'}}>Связаться с нами</a></p>
        )}   
        <button onClick={pushHistory} style={{margin:'10px', paddingBottom:'1rem', background:'none', color:'#81d4fa', textDecoration:'none'}}>изменить email</button>
      </Container>
    </div>
  )
}

export default Authorization