import React from 'react'
import { BsEye } from 'react-icons/bs'
import '../Styles/AdminPage.css'
import dayjs from 'dayjs'
import { Button, Container, Form, Navbar, NavDropdown } from 'react-bootstrap'
// import { BsPencilSquare } from 'react-icons/bs'
// import { NavLink } from 'react-router-dom'
// import ImageList from '@mui/material/ImageList';
// import ImageListItem from '@mui/material/ImageListItem';
import { FetchContext } from '../context/FetchContext'
import { useVirtualizer } from '@tanstack/react-virtual'
import { Modal } from 'react-bootstrap'
const linky = process.env.REACT_APP_LINKY

function ModalX({filenames}) {
    const fetchContext1 = React.useContext(FetchContext)
    const [show, setShow] = React.useState(false)
    const handleClose = () => setShow(false)
    const handleShow = () => setShow(true)

    const sendFile = async(NoteId) => {
        try {
            const { data } = await fetchContext1.authAxios.get(
                `api/download-cv/${NoteId}`,
                {responseType: 'arraybuffer'}
            )
            const blob = new Blob([data], {type: 'application/msword'})
            const downloadUrl = window.URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = downloadUrl;
            link.download = NoteId;
            document.body.appendChild(link);
            link.click();
            link.remove();
            setTimeout(() => URL.revokeObjectURL(downloadUrl), 7000);
        } catch (err) {
            console.log(err)
        }
    }
    const sendFilePDF = async(NoteId) => {
        try {
            const { data } = await fetchContext1.authAxios.get(
                `api/download-cv/${NoteId}`,
                {responseType: 'arraybuffer'}
            )
            const blob = new Blob([data], {type: 'application/pdf'})
            const downloadUrl = window.URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = downloadUrl;
            link.download = NoteId;
            document.body.appendChild(link);
            link.click();
            link.remove();
            setTimeout(() => URL.revokeObjectURL(downloadUrl), 7000);
        } catch (err) {
            console.log(err)
        }
    }

    return (
        <>
            <Button variant="primary" onClick={handleShow} style={{margin:'1rem', marginBottom: '2rem', marginLeft:'0rem', width:'320px'}}>
                Посмотреть файлы
            </Button>
            <Modal show={show} onHide={handleClose} contentClassName="modal-height" aria-labelledby="example-custom-modal-styling-title">
                <Modal.Body>
                    {filenames && typeof filenames !== 'undefined' ? (
                            <div className="modal-body">
                                {filenames.mimetype === 'image/png' || filenames.mimetype === 'image/jpg' || filenames.mimetype === 'image/jpeg' || filenames.mimetype === 'image/heic' || filenames.mimetype === 'image/webp' ? <img src={`data:image/*;base64,${filenames.base64}`} loading='lazy' alt={filenames.filename} style={{position:'relative', minWidth:'320px', width:'100%', minHeight:'320px', height:'100%', objectFit:'scale-down'}} className="img-fluid" /> : ''}
                                {filenames.mimetype === 'application/pdf' && <button onClick={() => sendFilePDF(filenames.filename)}>скачать</button> }
                                {filenames.mimetype === 'application/msword' || filenames.mimetype === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ? <button onClick={() => sendFile(filenames.filename)}>скачать</button> : ''}
                            </div>
                    ) : ('')}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Закрыть
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

// const CheckBoxx = ({request, checked, setChecked, setRequest}) => {
//     const handleChecked = (checked) => {
//         const newRequests = [...request]
//         if (checked) {
//             newRequests.splice(newRequests.indexOf(checked), 1)
//             setRequest(newRequests)
            
//         }
//     }   
//     return (
//         <Form.Check type='checkbox' id='check-api-policy1' style={{position:'relative', display:'flex', alignSelf:'flexEnd'}}>
//             <Form.Check.Input type={'checkbox'} id='check-api-p1' defaultChecked={checked} onClick={setChecked} onChange={(request) => handleChecked(checked, request)} isValid style={{position:'relative', display:'flex', alignSelf:'flexEnd', maxWidth:'24px', maxHeight:'24px', marginTop:'4px'}}></Form.Check.Input>
//             {/* <Form.Check.Label style={{color:'black', fontWeight:'600', letterSpacing:'0px', fontFamily:'Overpass, sans-serif'}}>Я ознакомлен с правилами подачи обращений</Form.Check.Label> */}
//         </Form.Check>
//     )
// }
// setRequest(({values}) => ({values: [...values, value]}))
        // } else {
            // setRequest(({values}) => ({values: values.filter(e => e !== value)}))
        // }
const CommentItem = ({id, resume, letter, name, email, phone, surname}) => {
    
    return (
        <>
            <div className='AFirstRow'>
                <div>Кандидат на вакансию</div>
                {name ? <div>, {name} {email}</div> : <div></div>}
            </div>
            <div className='ASecondRow'>
                <div className='LeftPart'>
                    {resume && typeof resume !== 'undefined' ? <ModalX filenames={resume}/> : <p> </p>}
                    {letter && typeof letter !== 'undefined' ? <ModalX filenames={letter}/> : <p> </p>}
                        
                            {/* <div style={{fontWeight:'600'}}>{type}, файлы: {Object.keys(filename).map(file => filename.filename[file].join(', '))}</div>  */}
                            {/* <ImageList sx={{ width: 552, height: 164 }} cols={3} rowHeight={164}>
                                {filename.map((item) => (
                                    <ImageListItem key={item.filename}>
                                        {<img
                                            src={`data:image/*;base64,${item.base64}`}
                                            srcSet={`data:image/*;base64,${item.base64}`}
                                            alt={item.filename}
                                            loading="lazy"
                                            style={{width:'164px', height:'164px',resize:'contain'}}
                                        />}
                                    </ImageListItem>
                                    
                                ))}
                            </ImageList> */}
                    <div>Имя: {name} {surname}</div>
                    <div>телефон: {phone}</div>
                    <div>email: {email}</div>
                </div>
                <div className='RightPart'>
                    {/* <MdFlag style={{width: '32px'}}/> */}
                    {/* <CheckBoxx checked={checked} setRequest={setRequest} request={request} setChecked={setChecked}/> */}
                    {/* <MdDone style={{width:'32px'}}/> */}
                    {/* <MdEdit style={{width:'32px'}}/> */}
                </div>
            </div>
        </>
    )
}

function AdminFeed({request, virtualRows, totalHeight, adminRef, checked, setChecked, setRequest}) {
    return (
        <>
            <Container className='AdminFeed' ref={adminRef}>
                {request.length > 0 ? (
                    <div className='AdminFeedItems' style={{height: totalHeight, display:'block', position:'relative', width:'100%', overfflowX:'hidden'}}>
                        {virtualRows.map(({index, size, start}) => {
                            const virtualRow = request[index]
                            if (!virtualRow) return null
                            return (
                                <div key={virtualRow.ReqId} className='AdminFeedItem' style={{position:'absolute', top:'0', left:'0', width:'100%', height:`${size}px`, transform: `translateY(${start}px)`, borderBottom:'1px solid #bbb'}} index={index}>
                                    <CommentItem
                                        id = {virtualRow.AppId}
                                        name = {virtualRow.Name}
                                        surname = {virtualRow.Surname}
                                        email = {virtualRow.Email}
                                        phone = {virtualRow.Phone}
                                        resume = {virtualRow.Resume}
                                        letter = {virtualRow.Letter}
                                        // checked={checked}
                                        // setChecked={setChecked}
                                        // setRequest={setRequest}
                                        // request={request}
                                    />
                                    {virtualRow.Comment ? (<div className='ASecondRow'>
                                        <div className='LeftPart'>
                                            <div style={{fontWeight:'600'}}>комментарий</div>
                                            <div>{virtualRow.Comment}</div>
                                        </div>
                                    </div>) : ''}
                                    <div className='ACommentRow'>
                                        <div className='Cright'>
                                            <div>{dayjs(virtualRow.createdAt).format('D MMM, YYYY h:mm A')}</div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                ) : (
                    <div></div>
                )}
            </Container>
        </>
    )
}

function CandidatesPage() {
    const fetchContext = React.useContext(FetchContext)
    const [AdminLine, setAdminLine] = React.useState([])
    const [request, setRequest] = React.useState([])
    const [sorted, setSorted] = React.useState({sorted: 'date', reversed: false})
    const [searchPhrase, setSearchPhrase] = React.useState('')
    const [comment, setComment] = React.useState('')
    const [checked, setChecked] = React.useState(false)

    const cuntRef = React.useRef(null)
    const rowVirtualizer = useVirtualizer({
        count: request ? request.length : 1,
        // size: articles ? articles.length : 1,
        getScrollElement: React.useCallback(() => cuntRef.current,[]),
        estimateSize: React.useCallback(()=>442,[]),
        overscan: 5,
    })

    React.useEffect(() => {
        const getDashboardData = async () => {
            try {
                const { data } = await fetchContext.authAxios.get(
                    `api/get-candidates`,
                    {timeout: 12000}
                );
                setAdminLine(data);
                setRequest(data);
                // console.log(AdminLine.length)
            } catch (error) {
                console.log(error)
            }
        };
        getDashboardData();
    }, [fetchContext]);

    const sortByDate = () => {
        // setRequest(AdminLine)
        setSorted({sorted:'createdAt', reversed: !sorted.reversed})
        const requestCopy = [...request]
        requestCopy.sort((reqA,reqB) => {
            // if (sorted.reversed) {
            //     return reqA.n - reqB.n
            // }
            return reqA.createdAt > reqB.createdAt ? -1 : 1
        })
        setRequest(requestCopy)
    }
    const sortByDateR = () => {
        // setRequest(AdminLine)
        setSorted({sorted:'createdAt', reversed: sorted.reversed})
        const requestCopy = [...request]
        requestCopy.sort((reqA,reqB) => {
            return reqB.createdAt > reqA.createdAt ? -1 : 1
        })
        setRequest(requestCopy)
    }
    // const Flagged = (x) => {
    //     if (x.flagged) {
    //         return setRequest.flagged(false) 
    //     }
    //     return setRequest.flagged(true)
    // }
    // const sortedByflag = () => {
    //     setSorted({sorted:'flagged',reversed: !sorted.reversed})
    //     const requestCopy = [...request]
    //     requestCopy.sort((reqA,reqB) => {
    //         if (sorted.reversed) {
    //             return reqA.flagged - reqB.flagged
    //         }
    //         return reqB.flagged - reqA.flagged
    //     })
    //     setRequest(requestCopy)
    // }
    const search = (event) => {
        event.preventDefault()
        if (AdminLine) {
            const matchedRequests = AdminLine.filter((request) => {
                return request.Name.toLowerCase().includes(event.target.value.toLowerCase())
            })
            setRequest(matchedRequests)
            setSearchPhrase(event.target.value)
        }
    }

    return (
        <React.Suspense>
            <div className='AdminContainer'>
            <Navbar bg='light' expand='lg' style={{minWidth:'100%', display:'flex', position:'relative'}}>
                <Container fluid>
                    <Navbar.Toggle aria-controls="navbarScroll"/>
                    <Navbar.Collapse id='navbarScroll' style={{justifyContent: 'space-between'}}>
                        <Form className="d-flex" style={{marginLeft:'5px'}}>
                            <Form.Control
                                type="search"
                                placeholder="Искать вопрос"
                                className="me-2"
                                aria-label="Search"
                                value={searchPhrase}
                                onChange={search}
                            />
                        </Form>
                        <span style={{fontWeight:'400', pointerEvents:'none'}}>Сортировка:</span>
                        <NavDropdown title='По дате' id='navbarscroll-date' style={{fontWeight:500, color:'#321e1e', fontSize:'smaller', marginRight:'12rem'}}>
                            <NavDropdown.Item style={{color:'black', fontWeight:400}} onClick={sortByDateR}>Вначале ранние</NavDropdown.Item>
                            <NavDropdown.Item style={{color:'black', fontWeight:400}} onClick={sortByDate}>Вначале новые</NavDropdown.Item>
                        </NavDropdown>
                        <a href={linky} target='__blank'><BsEye style={{scale:'1.5', marginRight:'0.3rem', color:'#42a5f5'}}/></a>
                        {/* </Nav> */}
                    </Navbar.Collapse>
                </Container>
            </Navbar>
                <AdminFeed request={request} virtualRows={rowVirtualizer.getVirtualItems()} totalHeight={rowVirtualizer.getTotalSize()} adminRef={cuntRef} setComment={setComment} comment={comment} checked={checked} setChecked={setChecked} setRequest={setRequest}/>
            </div>
        </React.Suspense>
    )
}

export default React.memo(CandidatesPage)
