import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import '../components/Slider.css';
import "swiper/css";
import "swiper/css/effect-cards";
import { EffectCoverflow, Autoplay, Navigation } from "swiper";
import { publicFetch } from '../util/fetch';
import { BsArrowRightShort } from 'react-icons/bs';
import { Spinner } from 'react-bootstrap';

const SwiperContext1 = React.createContext()
// const loadSlides = React.lazy(() => import(/* webpackPrefetch: true */'../components/MainPageSlider.css'))

function swiperReducer1(state, action) {
    switch (action.type) {
        case 'NEXT_SLIDE': {
            return {...state, nextSlide: action.slideNext()}
        }
        default: {
            throw new Error(`unhandled type:${action.type}`)
        }
    }
}

function SwiperProvider1(props) {
    // const swiperSlide = useSwiperSlide()
    const [state, dispatch] = React.useReducer(swiperReducer1, {
        nextSlide: Swiper.realIndex
    })
    const value = [state, dispatch]
    return <SwiperContext1.Provider value={value} {...props} />
}

function useSwiperState1() {
    const context = React.useContext(SwiperContext1)
    if(!context) {
        throw new Error('useSwiperState must be within the SwiperStateProvider')
    }
    return context
}

const SlideshowP = React.memo(function SlideshowP() {
    const [state, dispatch] = useSwiperState1()
    const {nextSlide} = state
    function handleChange(event) {
        const newNextSlide = event.target.value
        dispatch({type: "NEXT_SLIDE", nextSlide: newNextSlide})
    }
    const [setter, setSetter] = React.useState('')
    const [artLoading, setArtLoading] = React.useState(false)

    React.useEffect(() => {
        const getArticleData = async () => {
            try {
                setArtLoading(true)
                const {data} = await publicFetch.get(`api/get-articles-mp`)
                
                setTimeout(() => {
                    setSetter(data)
                }, 5000);
                setArtLoading(false)
            } catch (err) {
                setArtLoading(false)
                return console.log(err)
            };
        };
        getArticleData()
    }, []);
    
    return (
        <>
            <div className='SwiperMainz'>
                <Swiper
                    autoplay={{
                        delay: 2500,
                        // disableOnInteraction: false,
                    }}
                    effect={"coverflow"}
                    breakpoints= {{
                        480: {
                            slidesPerView: 1,
                            spaceBetween: 20,
                        },
                        1200:{
                            slidesPerView:3,
                            spaceBetween:25,
                        }
                    }}
                    slidesPerGroup={1}
                    loop={true}
                    loopFillGroupWithBlank={true}
                    grabCursor={true}
                    modules={[Autoplay, Navigation, EffectCoverflow]}
                    coverflowEffect={{
                        rotate: 50,
                        stretch:0,
                        depth:100,
                        modifier:1,
                        slideShadows:false
                    }}
                    navigation={true}
                    className="swiperN"
                >
                    {setter && !artLoading ? (setter.map((item, index) => {
                        return <SwiperSlide key={setter[index].postId} id={setter[index].postId} className="swiper-slide2" value={nextSlide} onChange={handleChange} style={{zIndex:'4000'}}>
                            <div className='newsWrapper'>
                                <img src={item.postPic} alt={item.shortDesc}/>
                                <p>{item.title}</p>
                                <a href={`/article/${item.postId}`} style={{zIndex:'101'}}><button className='newsButton'>Читать далее<BsArrowRightShort className="PostGridIcon"/></button></a>
                            </div>
                        </SwiperSlide>
                    })) : (<Spinner animation="border" style={{margin:'auto', top:'2rem', display:'flex', position:'relative', color:'hsl(156 45% 11% / 0.75)'}}/>)}
                </Swiper>
            </div>
        </>
    )
})

function Slideshow() {
    return (
        <>
            <SwiperProvider1>
                <SlideshowP/>
            </SwiperProvider1>
        </>
    )
}

export default Slideshow