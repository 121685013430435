import React from 'react'
import { Button, Input, TextField } from '@mui/material'
import '../Styles/AdminPage.css'
import { FetchContext } from '../context/FetchContext'
import { FaArrowUp } from 'react-icons/fa'
import { Spinner } from 'react-bootstrap'

const TextComment = React.memo(({article, value, id, setter, label, minRows, maxRows, name, ...props}) => {
    const handleChange = (e) => {
        e.preventDefault()
        const value = e.target.value
        setter({
        ...article,
        [e.target.name]: value
        })
    }
    return (
        <TextField multiline value={value} onChange={handleChange} label={label} minRows={minRows} maxRows={maxRows} id={id} variant='outlined' name={name} InputLabelProps={{shrink: true}} inputProps={{style:{ color:'black', marginTop:-5, marginBottom:-6, }}} sx={{'& label':{mt:-0.5, ml:0.1,fontSize:'large',letterSpacing:'0.5px',background:'#fbfbfb',outline:'8px solid #fbfbfb','&.Mui-focused':{ background:'#fbfbfb',outline:'8px solid #fbfbfb',color:'#28a745',marginLeft:0.1, fontSize:'large',marginTop:-0.5,}}, my:2, width:'590px', '& .MuiOutlinedInput-root.Mui-focused':{'& .MuiOutlinedInput-notchedOutline': {border:'1px solid #28a745',boxShadow:'0 0 0 0.2rem rgba(40, 167, 69, 0.25)'}}, '& .MuiOutlinedInput-root:hover':{'& .MuiOutlinedInput-notchedOutline': {border:'1px solid #28a745'}}}} {...props}></TextField>
    )
})
// <TextField multiline value={comment.Comment} onChange={handleChange} label='Оставить комментарий' minRows={1} maxRows={3} id={`Comment-${n.ReqId}`} variant='outlined' name='Comment' InputLabelProps={{shrink: true}} inputProps={{style:{ color:'black', marginTop:-5, marginBottom:-6}}} sx={{'& label':{mt:-0.5, ml:0.1,'&.Mui-focused':{ background:'white',outline:'8px solid white',color:'#28a745',marginLeft:0.1, fontSize:'medium',marginTop:-0.5,}}, my:2, width:'320px', '& .MuiOutlinedInput-root.Mui-focused':{'& .MuiOutlinedInput-notchedOutline': {border:'1px solid #28a745',boxShadow:'0 0 0 0.2rem rgba(40, 167, 69, 0.25)'}}, '& .MuiOutlinedInput-root:hover':{'& .MuiOutlinedInput-notchedOutline': {border:'1px solid #28a745'}}}}></TextField>
function AdminPageArticles() {
    const fetchContext = React.useContext(FetchContext)
    const [doc, setDoc] = React.useState([])
    const [pic, setPic] = React.useState([])
    const [articleSuccess, setArticleSuccess] = React.useState('')
    const [articleError, setArticleError] = React.useState('')
    const [articleLoading, setArticleLoading] = React.useState(false)

    const [article, setArticle] = React.useState({
        title1: '',
        topic: '',
        shortDesc: '',
        paragraph1: '',
        paragraph2: '',
        paragraph3: '',
        paragraph4: '',
        addNotes: '',
        sourceLink:  '',
    })

    const handleSubmit = async (e) => {
        e.preventDefault()
        const formData = new FormData()
        formData.append('title1', article.title1)
        formData.append('topic', article.topic)
        formData.append('shortDesc', article.shortDesc)
        formData.append('paragraph1', article.paragraph1)
        formData.append('paragraph2', article.paragraph2)
        formData.append('paragraph3', article.paragraph3)
        formData.append('paragraph4', article.paragraph4)
        formData.append('addNotes', article.addNotes)
        formData.append('sourceLink', article.sourceLink)
        formData.append('pic', pic)
        formData.append('doc', doc)
        try {
            setArticleLoading(true)
            const {data} = await fetchContext.authAxios1.post('api/set-article', formData)
            setArticleSuccess(data.message)
            alert(articleSuccess)
            setArticleError('')
            setTimeout(() => {
                setArticleLoading(false)
            }, 1000)
        } catch (error) {
            const {data} = error.response
            setArticleError(data.message)
            alert(articleError)
            setArticleSuccess('')
        }
    }
    const handleChange2 = (event) => {
        event.preventDefault()
        setDoc(event.target.files[0])
        console.log(doc)
    }
    const handleChange3 = (e) => {
        e.preventDefault()
        setPic(e.target.files[0])
        console.log(pic)
    }
    return (
        <React.Suspense>
            <div className='AdminContainer' id='AArticles'>
                <div className='AdminFeed'>
                    <form onSubmit={(values) => handleSubmit(values)} className='AdminFeedItems' id='BArticles'>
                        <span>для быстрого переключения между полями нажмите клавишу tab и shift+tab</span>
                        <span>для переноса на новую строку используйте символ | ,(обычно над клавишей enter)</span>
                        <TextComment article={article} required name='title1' id='title1' minRows={1} maxRows={1} value={article.title1} setter={setArticle} label='Заголовок'/>
                        <TextComment article={article} required name='topic' id='topic' minRows={1} maxRows={1} value={article.topic} setter={setArticle} label='Выберите тему поста'/>
                        <TextComment article={article} required name='shortDesc' id='shortDesc' minRows={1} maxRows={2} value={article.shortDesc} setter={setArticle} label='Короткое описание в списке статей'/>
                        <TextComment article={article} name='sourceLink' id='sourceLink' minRows={1} maxRows={2} value={article.sourceLink} setter={setArticle} label='Ссылки на источники, через "|"'/>
                        <TextComment article={article} required name='paragraph1' id='paragraph1' minRows={3} value={article.paragraph1} setter={setArticle} label='Параграф 1'/>
                        <TextComment article={article} name='paragraph2' id='paragraph2' minRows={3} value={article.paragraph2} setter={setArticle} label='Параграф 2'/>
                        <TextComment article={article} name='paragraph3' id='paragraph3' minRows={3} value={article.paragraph3} setter={setArticle} label='Параграф 3'/>
                        <TextComment article={article} name='paragraph4' id='paragraph4' minRows={3} value={article.paragraph4} setter={setArticle} label='Параграф 4'/>
                        <TextComment article={article} name='addNotes' id='addNotes' minRows={2} value={article.addNotes} setter={setArticle} label='Комментарий после поста'/>
                        <label htmlFor='doc'>Прикрепите документы для скачивания</label>
                        <div style={{display:'flex', flexDirection:'row', gap:'1rem', marginLeft:'1rem', alignSelf:'center', justifyContent:'space-between'}}>
                            <Button startIcon={<FaArrowUp style={{width:'12px', paddingTop:'10px'}}/>} variant='contained' component='label' style={{alignSelf:'start', background:'hsl(122 39% 49% / 0.7)', height:'40px', width:'320px', fontSize:'8.5pt', position:'relative'}}>
                                Прикрепить файл
                                <Input type='file' id='doc' name='doc' onChange={handleChange2} hidden/>
                            </Button>
                            {doc && <p style={{color:'#d9b173', fontSize:'small'}}>{doc.name}</p>}
                            {/* <p style={{color:'white', fontSize:'small'}}>{file.name}<MdClose style={{color:'red', size:'52px', marginLeft:'10px'}} onClick={(event) => handleCloseFile(event)} /></p> : ''} */}
                        </div>
                        <label htmlFor='pic'>Прикрепите обложку статьи *</label>
                        <div style={{display:'flex', flexDirection:'row', gap:'1rem', marginLeft:'1rem', alignSelf:'center', justifyContent:'space-between'}}>
                            <Button startIcon={<FaArrowUp style={{width:'12px', paddingTop:'10px'}}/>} variant='contained' component='label' style={{alignSelf:'start', background:'hsl(122 39% 49% / 0.7)', height:'40px', width:'320px', fontSize:'8.5pt', position:'relative'}}>
                                Прикрепить файл
                                <Input required type='file' id='pic' name='pic' onChange={handleChange3} hidden/>
                            </Button>
                            {pic && <p style={{color:'#d9b173', fontSize:'small'}}>{pic.name}</p>}
                        </div>
                        <button id='articleSubmission' type='submit'>{articleLoading === true ? (<Spinner animation="border" style={{marginTop:'1px', scale:'80%'}}/>) : (<span>Опубликовать статью на сайте</span>)}</button>
                    </form>
                </div>
            </div>
        </React.Suspense>
    )
}

export default React.memo(AdminPageArticles)