import React from 'react'
import '../Styles/LogIn.css'
import '../BackGround.css'
import ButnotP from '../Styles/butnotP'
import { Container, Spinner } from 'react-bootstrap'
import src from '../Styles/ECLOGOSVG.svg'
import { AuthContext } from '../context/AuthContext'
import { publicFetch } from '../util/fetch'
import { Navigate } from 'react-router-dom'
import RedditTextField from '../components/reddit-textfield'
import { IconButton, InputAdornment } from '@mui/material'
import { MdVisibility, MdVisibilityOff } from 'react-icons/md'

function LogIn() {
  const authContext = React.useContext(AuthContext)
  const [redirectOnLogin, setRedirectOnLogin] = React.useState(false)
  const [loginSuccess, setLoginSuccess] = React.useState()
  const [loginError, setLoginError] = React.useState()
  const [loginLoading, setLoginLoading] = React.useState(false)
  const [showPassword, setShowPassword] = React.useState(false)
  const handleClickShowPassword = () => setShowPassword(!showPassword)
  const handleMouseDownPassword = () => setShowPassword(!showPassword)
  const [userdata,setUserdata] = React.useState({
    email: '',
    password: ''
  })

  const handleChange = (e) => {
    e.preventDefault()
    const value = e.target.value
    setUserdata({
      ...userdata,
      [e.target.name]: value
    })
  };

  const submitCredentials = async (credentials, e) => {
    credentials.preventDefault()
    const Credentials = {
      email : userdata.email,
      password : userdata.password
    } 
    try {
        setLoginLoading(true)
        const { data } = await publicFetch.post(`api/login`, Credentials);
        authContext.setAuthState(data)
        setLoginSuccess(data.message)
        setLoginError('')
        setTimeout(() => {
          setRedirectOnLogin(true)
        }, 1100);
    } catch (error) {
      setLoginLoading(false);
      const { data } = error.response;
      setLoginError(data.message);
      setLoginSuccess('');
    }
  }

  return (
    <div style={{minHeight:'100vh', maxHeight:'200vh'}}>
      {redirectOnLogin && !authContext.isAdmin() ? <Navigate to='/dc' /> : ''}
      {redirectOnLogin && authContext.isAdmin() ? <Navigate to='/admin-page' /> : ''}
      <a href='/'><img src={src} className='imgLogo' alt='imglogo'/></a>
      <Container className='FormGroup' style={{width:'550px'}}>
        <h1>Войти в личный кабинет</h1>
        <form onSubmit={values => submitCredentials(values)} className='ECform'>
          <div >
            <label htmlFor='email'>Имя пользователя</label>
            <RedditTextField id='email' type='text' label='email' variant='filled' name='email' value={userdata.email} onChange={handleChange} />
          </div>
          <div >
            <label htmlFor='password'><a href='/reset'>Забыли пароль?</a></label>
            <RedditTextField id='password' type={showPassword ? "text" : "password"} label='Пароль' variant='filled'  name='password' value={userdata.password} onChange={handleChange} InputProps={{
              disableUnderline: true,
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="password-visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? <MdVisibility /> : <MdVisibilityOff />}
                  </IconButton>
                </InputAdornment>
              )
            }}/> 
          </div >
          <ButnotP type='submit' id='ButnotP2' name={loginLoading === true ? (<Spinner animation="border" style={{marginTop:'6px', scale:'80%'}}/>) : (<span>Продолжить</span>)} loading={loginLoading} />
        </form>
        {loginSuccess && (
            <p id='successML' style={{fontSize:'medium', maxWidth:'100%', color:'#81c784'}}>{loginSuccess}</p>
        )}
        {loginError && (
            <p id='errorML' style={{fontSize:'medium', fontWeight:400, color:'#f44336'}}>{loginError}<a href='/support' style={{marginLeft:'1rem', fontWeight:'400'}}>Связаться с нами</a></p>
        )}
        <p id='NoAccount'>Нет аккаунта? <a href='/signup' style={{margin:'10px'}}>Зарегистрироваться</a></p>
      </Container>
    </div>
  )
}

export default LogIn
