
import { alpha, styled, TextField } from '@mui/material'
import React from 'react'

const RedditTextField = styled((props) => (
    <TextField InputProps={{ disableUnderline: true, margin: 'dense' }} {...props} />
))(({ theme }) => ({
    '& .MuiFilledInput-root': {
      border: '1px solid #e2e2e1',
      overflow: 'hidden',
      borderRadius: 4,
      letterSpacing: '0.5px',
      WebkitFontSmoothing: 'antialiased',
      MozOsxFontSmoothing: 'grayscale',
      backgroundColor: theme.palette.mode === 'light' ? '#fcfcfb' : '#2b2b2b',
      transition: theme.transitions.create([
        'border-color',
        'background-color',
        'box-shadow', 
        'color',
      ]),
      '&:hover': {
        backgroundColor: 'transparent',
        color: '#ffffff',
      },
      '&.Mui-focused': {
        backgroundColor: 'transparent',
        boxShadow: `${alpha(theme.palette.success.light, 0.3)} 0 0 0 4px`,
        borderColor: theme.palette.success.dark,
        color: '#d9b173'
      },
      // '& .MuiFilledInput-input': {},
    },
    '& .MuiInputLabel-filled': {
      letterSpacing: '0.3px',
      fontFamily: 'IBM Plex Sans',
      WebkitFontSmoothing: 'antialiased',
      MozOsxFontSmoothing: 'grayscale',
      '&.Mui-focused': {
        color: 'white',
        fontSize: 'medium',
        letterSpacing: '0.3px',
      },
    },
}))

export default RedditTextField