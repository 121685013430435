import React from 'react'
import '../Styles/AboutPage.css'
import Footer from '../components/Footer';
import { BsPhone } from 'react-icons/bs';
import { MdEmail } from 'react-icons/md';
import { tel } from '../Styles/colors';

function About() {

  // eslint-disable-next-line no-unused-vars
  const [scrollY, setScrollY] = React.useState(0);
  const width = window.innerWidth
  const breakpoint = 480

  React.useLayoutEffect(() => {
    function logit() {
      const bg = document.getElementById('back2backZoom')
      width > breakpoint && (bg.style.backgroundSize = 118 +window.scrollY/35+'%')
      width < breakpoint && (bg.style.backgroundSize = 150 + Math.log2(1 - window.scrollY/2) +'%')
      setScrollY(window.pageYOffset);
    };
    function watchScroll() {
      window.addEventListener("scroll", logit);
    };
    watchScroll();
    // Remove listener (like componentWillUnmount)
    return () => {
      window.removeEventListener("scroll", logit);
    };
  }, [width]);

  return (
    <React.Suspense>    
      <div id='back2backZoom' ></div>
      <section className='AboutSection'>
        <h2>Мы встретим Вас в нашем офисе – достойно</h2>
        <p>Получить устную консультацию или обсудить иные интересующие вопросы Вы можете в офисе нашего агенства, который расположен непосредственно в районе набережной Ижевского пруда, по адресу: <br/><br/>пр. Дерябина, 3, Ижевск, республика Удмуртия, 426006</p>
        <div className='AboutContacts'>
          <a href='tel:83412940707'><BsPhone className='AboutIcon' id='AboutPhone'/>{tel}</a>
          <a href='mailto:collect@ec18.ru'><MdEmail className='AboutIcon' id='AboutMail'/>collect@ec18.ru</a>
        </div>
      </section>
      <Footer/>
    </React.Suspense>
  )
}

export default React.memo(About)