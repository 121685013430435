import React from 'react';
import NaviBar from '../components/Navibar';
import { Outlet } from 'react-router';
import ScrollButton from '../components/TopScroll';

const WithNav = () => {
  return (
    <>
      <NaviBar />
      <Outlet />
      <ScrollButton/>
    </>
  );
}

export default WithNav
