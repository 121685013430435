import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import '../components/MainPageSlider.css';
import "swiper/css";
import "swiper/css/virtual";
import "swiper/css/effect-fade";
import { Autoplay, EffectFade, Navigation, Virtual } from "swiper";
import family from '../images/family1.jpg';
// import leaves from '../images/leaves.jpg';
import leaves3 from '../images/leaves3.jpg';
import mountain from '../images/mountain1.jpg';
import forest from '../images/forest.jpg';
// import kacheli from '../images/kacheli.jpgnpm run buid

const images = [
    {
        id: 1,
        images: forest,
        alt: 'forest',
        h2: 'Платите на выгодных Вам условиях с рассрочкой',
        span: 'Рассчитайте сумму долга на условиях рассрочки',
        link: '#tabs-ec',
    },
    {
        id: 2,
        images: family,
        alt: 'family',
        h2: 'Спишем от 30 до 50 % задолженности при условии разовой оплаты',
        span: 'Рассчитайте общую сумму задолженности на своих условиях при внесении оплаты единоразовым платежом',
        link: '#tabs-ec',
    },
    {
        id: 3,
        images: leaves3,
        alt: 'leaves3',
        h2: 'Оплата онлайн без комиссии',
        span: 'Вам доступна оплата по QR-коду, реквизитам или через терминал',
        link: '/pay',
    },
    {
        id: 4,
        images: mountain,
        alt: 'mountain',
        h2: 'Управление долгом в личном кабинете',
        span: 'Здесь можно проверить наличие скидки, получить реквизиты к Вашему договору, посмотреть остаток или получить справку об отсутствии задолженности. бонус при регистрации – 100 рублей',
        link: '/login',
    },
    // {
    //     id: 5,
    //     images: leaves,
    //     alt: 'leaves'
    // },
    // {
    //     id: 6,
    //     images: wallet,
    //     alt: 'wallet'
    // },
    // {
    //     id: 7,
    //     images: kacheli,
    //     alt:'kacheli'
    // },
]

const SwiperContext = React.createContext()
// const loadSlides = React.lazy(() => import(/* webpackPrefetch: true */'../components/MainPageSlider.css'))

function swiperReducer(state, action) {
    switch (action.type) {
        case 'NEXT_SLIDE': {
            return {...state, nextSlide: action.slideNext()}
        }
        default: {
            throw new Error(`unhandled type:${action.type}`)
        }
    }
}

function SwiperProvider(props) {
    // const swiperSlide = useSwiperSlide()
    const [state, dispatch] = React.useReducer(swiperReducer, {
        nextSlide: Swiper.realIndex
    })
    const value = [state, dispatch]
    return <SwiperContext.Provider value={value} {...props} />
}

function useSwiperState() {
    const context = React.useContext(SwiperContext)
    if(!context) {
        throw new Error('useSwiperState must be within the SwiperStateProvider')
    }
    return context
}

function MainpageSlider() {
    const [state, dispatch] = useSwiperState()
    const {nextSlide} = state
    const [isLoaded, setIsLoaded] = React.useState(false);
    function handleChange(event) {
        const newNextSlide = event.target.value
        dispatch({type: "NEXT_SLIDE", nextSlide: newNextSlide})
    }

    const handleImageLoaded = () => {
        setIsLoaded(true);
    }
    
    return (
        <>
            <Swiper 
                autoplay={{
                    disableOnInteraction: false,
                    // delay: 4200,
                }}
                loop={true}
                effect={"fade"}
                spaceBetween={0}
                // speed={690}
                navigation={true}
                // loopFillGroupWithBlank={true}
                grabCursor={true}
                modules={[Autoplay, EffectFade, Virtual, Navigation]}
                className="mySwiperM"
                // virtual
                onSwiper={handleImageLoaded}
                // onDragEnterCapture={() => loadSlides}
                // loading='lazy' onLoad={handleImageLoaded}
            >
                {images.map((item, index) => {
                    return <SwiperSlide key={images[index].id} virtualIndex={index} id={images.id} className="mySwiperSlideM" value={nextSlide} onChange={handleChange}>
                        {isLoaded &&
                            <>
                                <img src={item.images} alt={item.alt} />
                                <div className='sensesTooLit'>
                                    <h2>{item.h2}</h2>
                                    <span>{item.span}</span>
                                    <a href={item.link}><button id='STL'>Подробнее</button></a>
                                </div>
                            </>
                        }
                    </SwiperSlide>
                })}
            </Swiper>
        </>
    )
}

function MainpageSliderP() {
    return (
        <>
            <SwiperProvider>
                <MainpageSlider/>
            </SwiperProvider>
        </>
    )
}

export default React.memo(MainpageSliderP)


    