
import React from 'react';
import {FaArrowRight} from 'react-icons/fa';
import './ButtonP.css';

function ButnotP(props) {
    const preventDragHandler = (e) => {
        e.preventDefault()
    }
    return (
        <>
            <button className={'butnot-prime'} id={props.id} style={props.style}><span className='text' onDragStart={preventDragHandler}>{props.name}</span><FaArrowRight className='fa'></FaArrowRight></button>
        </>
    )
}

export default React.memo(ButnotP)

