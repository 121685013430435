import React from 'react'
import { FetchContext } from '../context/FetchContext'
import '../Styles/Dashboard.css'

function DashboardNotes() {
    const exportedRef = React.useRef()
    const fetchContext = React.useContext(FetchContext)
    const [dashNotes, setDashNotes] = React.useState()
    
    React.useEffect(() => {
        const getDashboardNotes = async () => {
            try {
                const { data } = await fetchContext.authAxios.get(
                    `api/notes/`,
                );
                setDashNotes(data);
                console.log(data)
            } catch (err) {
                console.log(err)
            }
        }
        getDashboardNotes();
    }, [fetchContext.authAxios]);

    const sendFile = async(NoteId) => {
        try {
            const { data } = await fetchContext.authAxios.get(
                `api/download-notes/${NoteId}`,
                {responseType: 'arraybuffer'}
            )
            const blob = new Blob([data], {type: 'application/pdf'})
            const downloadUrl = window.URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = downloadUrl;
            link.download = NoteId;
            document.body.appendChild(link);
            link.click();
            link.remove();
            setTimeout(() => URL.revokeObjectURL(downloadUrl), 7000);
        } catch (err) {
            console.log(err)
        }
    }

    return (
        <React.Suspense>
            <div className='ContractContainerOther' id='InfoN'>
                {dashNotes ? (
                    <div className='DocCol'>
                        <p className='PayTP'>Здесь отобразятся полученные справки</p>
                        {dashNotes.map((note, i) => {
                            return (
                                <>
                                    <div key={dashNotes[i].NotesId} style={{display:'flex', flexDirection:'row', gap:'1rem', justifyContent:'space-between'}}>
                                        <p style={{fontWeight:'bold', letterSpacing:'0.3px', fontFamily:'Overpass, sans-serif', padding:'5px'}} ref={exportedRef}>{note.Filename}</p>
                                        <button onClick={() => sendFile(note.Filename)}>скачать</button>
                                    </div>
                                    <span style={{fontWeight:'400', letterSpacing:'0.3px', marginTop:'0.5rem', marginBottom:'1.5rem', display:'flex', position:'relative'}}>{note.Comment}</span>
                                </>
                                
                            )
                        })}
                    </div>
                ) : (
                    ''
                )}
            </div>
        </React.Suspense>
    )
}

export default DashboardNotes