import React from 'react'
import Marquee from "react-fast-marquee";
import src1 from '../images/PartnersLogos/alfa1.png'
import src2 from '../images/PartnersLogos/mts.png'
import src3 from '../images/PartnersLogos/otkritie.png'
import src4 from '../images/PartnersLogos/cetelem.png'
import src5 from '../images/PartnersLogos/rosselhozbank.png'
import src6 from '../images/PartnersLogos/sber.png'
import src7 from '../images/PartnersLogos/mfkcfp.png'
import src8 from '../images/PartnersLogos/skorost-finance.png'
import src9 from '../images/PartnersLogos/otp-bank1.png'
import src10 from '../images/PartnersLogos/otp-finance.png'
import './Marquee.css'

const MarqueeItems = [
    {
        src: src1,
        alt: 'alfa',
        id: 1,
    },
    {
        src: src2,
        alt: 'mts',
        id: 2,
    },
    {
        src: src3,
        alt: 'otkritie',
        id: 3,
    },
    {
        src: src4,
        alt: 'cetelem',
        id: 4,
    },
    {
        src: src5,
        alt: 'rosselhozbank',
        id: 5,
    },
    {
        src: src6,
        alt: 'sber',
        id:  6,
    },
    {
        src: src7,
        alt: 'mfkfcp',
        id: 7,
    },
    {
        src: src8,
        alt: 'skorost-finance',
        id: 8,
    },
    {
        src: src9,
        alt: 'otp-bank',
        id: 9,
    },
    {
        src: src10,
        alt: 'otp-finance',
        id: 10,
    },
]

function MarqueePartners() {
    const preventDrag = (e) => {
        e.preventDefault()
    }

    return (
        <Marquee
            speed={25}
            gradientColor={[217,177,115]}
            style={{background:'#d9b173', overflowY:'hidden'}}
        >
            {MarqueeItems.map((item,index) => {
                return <div key={MarqueeItems[index].id} className="partnersMarquee">
                    <img src={item.src} alt={item.alt} onDragStart={preventDrag}/>
                </div>
            })}
        </Marquee>
    )
}

export default React.memo(MarqueePartners)