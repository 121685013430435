import React from 'react'
import { FetchContext } from '../context/FetchContext'
import { TextField } from '@mui/material'
import '../Styles/AdminPage.css'
import { Spinner } from 'react-bootstrap'

const TextComment = React.memo(({defaultValue, update, value, id, setter, label, minRows, maxRows, name, ...props}) => {
    const handleChange = (e) => {
        // e.preventDefault()
        const value = e.target.value
        setter({
          ...update,
          [e.target.name]: value
        })
    }

    return (
        <TextField multiline defaultValue={defaultValue} value={value} onChange={handleChange} label={label} minRows={minRows} maxRows={maxRows} id={id} variant='outlined' name={name} InputLabelProps={{shrink: true}} inputProps={{style:{ color:'black', marginTop:-5, marginBottom:-6, }}} sx={{'& label':{mt:-0.5, ml:0.1,fontSize:'large',letterSpacing:'0.5px',background:'#fbfbfb',outline:'8px solid #fbfbfb','&.Mui-focused':{ background:'#fbfbfb',outline:'8px solid #fbfbfb',color:'#28a745',marginLeft:0.1, fontSize:'large',marginTop:-0.5,}}, my:2, width:'590px', '& .MuiOutlinedInput-root.Mui-focused':{'& .MuiOutlinedInput-notchedOutline': {border:'1px solid #28a745',boxShadow:'0 0 0 0.2rem rgba(40, 167, 69, 0.25)'}}, '& .MuiOutlinedInput-root:hover':{'& .MuiOutlinedInput-notchedOutline': {border:'1px solid #28a745'}}}} {...props}></TextField>
        // <TextField multiline value={comment.Comment} onChange={handleChange} label='Оставить комментарий' minRows={1} maxRows={3} id={`Comment-${n.ReqId}`} variant='outlined' name='Comment' InputLabelProps={{shrink: true}} inputProps={{style:{ color:'black', marginTop:-5, marginBottom:-6}}} sx={{'& label':{mt:-0.5, ml:0.1,'&.Mui-focused':{ background:'white',outline:'8px solid white',color:'#28a745',marginLeft:0.1, fontSize:'medium',marginTop:-0.5,}}, my:2, width:'320px', '& .MuiOutlinedInput-root.Mui-focused':{'& .MuiOutlinedInput-notchedOutline': {border:'1px solid #28a745',boxShadow:'0 0 0 0.2rem rgba(40, 167, 69, 0.25)'}}, '& .MuiOutlinedInput-root:hover':{'& .MuiOutlinedInput-notchedOutline': {border:'1px solid #28a745'}}}}></TextField>
    )
})

function AdminPageVacancies() {
    const fetchContext = React.useContext(FetchContext)
    const [vacancies, setVacancies] = React.useState('')
    const [uploadLoading, setUploadLoading] = React.useState(false)
    const [update, setUpdate] = React.useState({
        Position: '',
        Department: '',
        Description: '',
        Requirements: '',
        Functions: '',
        Salary: '',
        Benefits: '',
        Jobtype: '',
        ShortDesc: '',
    })
    
    React.useEffect(() => {
        const getVacanciesList = async () => {
            const {data} = await fetchContext.authAxios.get(`/api/get-vacancies-admin`)
            setVacancies(data)
            console.log(data)
        }
        getVacanciesList();
    }, [fetchContext.authAxios])

    const handleSubmit = async(e, id) => {
        e.preventDefault()
        const Credentials = {
            Position: update.Position,
            Department: update.Department,
            Description: update.Description,
            Requirements: update.Requirements,
            Functions: update.Functions,
            Salary: update.Salary,
            Benefits: update.Benefits,
            Jobtype: update.Jobtype,
            ShortDesc: update.ShortDesc,
        }
        try {
            setUploadLoading(true)
            const {data} = await fetchContext.authAxios.patch(`api/correct-vacancy/${id}`, Credentials)
            setUploadLoading(false)
            alert(data.message)
        } catch (err) {
            setUploadLoading(false)
            const {data} = err.response
            alert(data.message)
        }
    }

    const handleSubmit1 = async(e) => {
        e.preventDefault()
        const Credentials = {
            Position: update.Position,
            Department: update.Department,
            Description: update.Description,
            Requirements: update.Requirements,
            Functions: update.Functions,
            Salary: update.Salary,
            Benefits: update.Benefits,
            Jobtype: update.Jobtype,
            ShortDesc: update.ShortDesc,
        }
        try {
            setUploadLoading(true)
            const {data} = await fetchContext.authAxios.post(`api/new-vacancy`, Credentials)
            setUploadLoading(false)
            alert(data.message)
            window.location.reload(true)
        } catch (err) {
            setUploadLoading(false)
            const {data} = err.response
            alert(data.message)
            window.location.reload(true)
        }
    }

    const handleDelete = async (e, id) =>{
        e.preventDefault()
        try {
            const {data} = await fetchContext.authAxios1.delete(`api/admin-delete-vacancies/${id}`);
            setVacancies(
                vacancies.filter(
                    id => id !== data.deleteVacancy.id
                )
            );
            alert(data.message)
            return window.location.reload(true)
        } catch (err) {
            const {data} = err.response
            alert(data.message)
            return window.location.reload(true)
        }
    }

    return (
        <React.Suspense>
            <div className='AdminContainer' id='AVacancies'>
                <div className='AdminFeed'>
                    <h2 style={{margin:'auto', posiion:'relative', display:'flex', justifyContent:'center', paddingTop:'2rem'}}>Внести правки в опубликованные вакансии</h2>
                    {vacancies && vacancies.map((item, index) => {
                        return (
                            <form key={index.JobId} className='AdminFeedItems' id='BVacancies' onSubmit={(values) => handleSubmit(values, item.JobId)}>
                                <TextComment update={update} required name='Position' id='Position' minRows={1} maxRows={1} defaultValue={item.Position} setter={setUpdate} label='Позиция'/>
                                <TextComment update={update} required name='Department' id='Department' minRows={1} maxRows={1} defaultValue={item.Department} setter={setUpdate} label='Отдел'/>
                                <TextComment update={update} required name='Description' id='Description' minRows={1} maxRows={10} defaultValue={item.Description} setter={setUpdate} label='Описание вакансии'/>
                                <TextComment update={update} required name='Requirements' id='Requirements' minRows={1} maxRows={10} defaultValue={item.Requirements} setter={setUpdate} label='Требования к работе'/>
                                <TextComment update={update} required name='Functions' id='Functions' minRows={1} maxRows={10} defaultValue={item.Functions} setter={setUpdate} label='Функции'/>
                                <TextComment update={update} required name='Benefits' id='Benefits' minRows={1} maxRows={10} defaultValue={item.Benefits} setter={setUpdate} label='Преимущества вакансии'/>
                                <TextComment update={update} required name='Salary' id='Salary' minRows={1} maxRows={1} defaultValue={item.Salary} setter={setUpdate} label='Зарплата'/>
                                <TextComment update={update} required name='Jobtype' id='Jobtype' minRows={1} maxRows={1} defaultValue={item.Jobtype} setter={setUpdate} label='Тип занятости'/>
                                <TextComment update={update} required name='ShortDesc' id='ShortDesc' minRows={1} maxRows={3} defaultValue={item.ShortDesc} setter={setUpdate} label='Краткое описание в карточках'/>
                                <div style={{display: 'flex', flexDirection:'row', gap:'3.25rem', justifyContent:'center', alignItems:'center'}}>
                                    <button type='button' onClick={e => handleDelete(e, item.JobId)} style={{height:'42px', borderRadius:'5px', paddingLeft: '10px', paddingRight:'10px'}}>Удалить</button>
                                    <button id='articleSubmission' type='submit'>{uploadLoading === true ? (<Spinner animation="border" style={{marginTop:'1px', scale:'80%'}}/>) : (<span>Опубликовать вакансию на сайте</span>)}</button>
                                </div>
                            </form>
                        )
                    })}
                    <h2 style={{margin:'auto', posiion:'relative', display:'flex', justifyContent:'center', paddingTop:'2rem'}}>Опубликовать новую вакансию</h2>
                    <span style={{margin:'auto', posiion:'relative', display:'flex', justifyContent:'center', paddingTop:'1rem'}}>для переноса на новую строку используйте символ | ,(обычно над клавишей enter)</span>
                    <form onSubmit={(values) => handleSubmit1(values)} className='AdminFeedItems' id='BVacancies'>
                        <TextComment update={update} required name='Position' id='Position1' minRows={1} maxRows={1} value={update.Position} setter={setUpdate} label='Позиция'/>
                        <TextComment update={update} required name='Department' id='Department1' minRows={1} maxRows={1} value={update.Department} setter={setUpdate} label='Отдел'/>
                        <TextComment update={update} required name='Description' id='Description1' minRows={1} maxRows={10} value={update.Description} setter={setUpdate} label='Описание вакансии'/>
                        <TextComment update={update} required name='Requirements' id='Requirements1' minRows={1} maxRows={10} value={update.Requirements} setter={setUpdate} label='Требования к работе'/>
                        <TextComment update={update} required name='Functions' id='Functions1' minRows={1} maxRows={10} value={update.Functions} setter={setUpdate} label='Функции'/>
                        <TextComment update={update} required name='Benefits' id='Benefits1' minRows={1} maxRows={10} value={update.Benefits} setter={setUpdate} label='Преимущества вакансии'/>
                        <TextComment update={update} required name='Salary' id='Salary1' minRows={1} maxRows={1} value={update.Salary} setter={setUpdate} label='Зарплата'/>
                        <TextComment update={update} required name='Jobtype' id='Jobtype1' minRows={1} maxRows={1} value={update.Jobtype} setter={setUpdate} label='Тип занятости'/>
                        <TextComment update={update} required name='ShortDesc' id='ShortDesc1' minRows={1} maxRows={3} value={update.ShortDesc} setter={setUpdate} label='Краткое описание в карточках'/>
                        <button id='articleSubmission' type='submit'>{uploadLoading === true ? (<Spinner animation="border" style={{marginTop:'1px', scale:'80%'}}/>) : (<span>Опубликовать вакансию на сайте</span>)}</button>
                    </form>
                </div>
            </div>
        </React.Suspense>
    )
}

export default AdminPageVacancies