import React from "react"
import "./Tabs-motion.css"
import {motion} from 'framer-motion'

function TabItem({ isOpen, ...props }) {
    const [contentHeight, setContentHeight] = React.useState(0);
    const contentRef = React.useRef(null);

    React.useEffect(() => {
        const updateContentHeight = () => {
            if (contentRef.current) {
                setContentHeight(contentRef.current.offsetHeight);
            }
        };

        const resizeObserver = new ResizeObserver(updateContentHeight);

        if (contentRef.current) {
            resizeObserver.observe(contentRef.current);
        }

        return () => {
            resizeObserver.disconnect();
        };
    }, []);

    const variants = {
        open: { opacity: 1, height: contentHeight },
        closed: { opacity: 0, height: 0 },
    };

    return (
        <motion.div
            layout
            initial="closed"
            animate={isOpen ? "open" : "closed"}
            variants={variants}
            style={{ overflow: "hidden" }}
        >
            <div ref={contentRef}>{props.children}</div>
        </motion.div>
    );
}

const actionTypes = {toggle_index: 'toggle_index'}
function accordionReducer(openIndexes, action) {
    switch (action.type) {
        case actionTypes.toggle_index: {
            const closing = openIndexes.includes(action.index)
            return closing
                ? openIndexes.filter((i) => i !== action.index)
                : [...openIndexes, action.index]
        }
        default: {
            throw new Error(`Unhandled type in accordionReducer: ${action.type}`)
        }
    }
}
  
function useAccordion({reducer = accordionReducer} = {}) {
    const [openIndexes, dispatch] = React.useReducer(reducer, [0])
    const toggleIndex = (index) =>
        dispatch({type: actionTypes.toggle_index, index})
    return {openIndexes, toggleIndex}
}

// export {useAccordion, accordionReducer, actionTypes}

function preventCloseReducer(openIndexes, action) {
    if (action.type === actionTypes.toggle_index) {
        const closing = openIndexes.includes(action.index)
        const isLast = openIndexes.length < 2
        if (closing && isLast) {
            return openIndexes
        }
    }
}
  
function singleReducer(openIndexes, action) {
    if (action.type === actionTypes.toggle_index) {
        const closing = openIndexes.includes(action.index)
        if (!closing) {
            return [action.index]
        }
    }
}
  
function combineReducers(...reducers) {
    return (state, action) => {
        for (const reducer of reducers) {
            const result = reducer(state, action)
            if (result) return result
        }
    }
}
function useTabs({reducer = () => {}} = {}) {
    return useAccordion({
        reducer: combineReducers(
            reducer,
            preventCloseReducer,
            singleReducer,
            accordionReducer,
        ),
    })
}

function TabsTest({items, ...props}) {
    const {openIndexes, toggleIndex} = useTabs()
    return (
        <div className="tabs1" id={props.id}>
            <div className="tabsList1">
                {items.map((item, index) => (
                    <div
                        className={`tabHead1 ${openIndexes.includes(index) ? 'active2' : null}`}
                        key={item.id}
                        isOpen={openIndexes.includes(index)}
                        onClick={() => toggleIndex(index)}
                    >
                        {item.bs}
                        {item.title}
                    </div>
                ))}
            </div>
            <div className="tabContent1">
                {items.map((item, index) => (
                    <TabItem isOpen={openIndexes.includes(index)} key={item.id}>
                        {items[index].contents}
                    </TabItem>
                ))}
            </div>
        </div>
    )
}

export default TabsTest