import React from 'react'
import '../Styles/signUp.css'
import '../BackGround.css'
import ButnotP from '../Styles/butnotP'
import { Container, Spinner } from 'react-bootstrap'
import src from '../Styles/ECLOGOSVG.svg'
import { useNavigate } from 'react-router-dom'
import { AuthContext } from '../context/AuthContext'
import { publicFetch } from '../util/fetch'
import RedditTextField from '../components/reddit-textfield'
import { IMaskInput } from 'react-imask'
import PropTypes from 'prop-types';
import { IconButton, InputAdornment } from '@mui/material'
import { MdVisibility, MdVisibilityOff } from 'react-icons/md'
import ScrollDialog from '../components/Dialog'

const TextMaskCustom1 = React.forwardRef(function TextMaskCustom(props, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="+7 (#00) 000-0000"
      definitions={{
        '#': /[1-9]/,
      }}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  );
});
TextMaskCustom1.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

function SignUp() {
  const authContext = React.useContext(AuthContext)
  const history = useNavigate()
  const [signupSuccess, setSignupSuccess] = React.useState()
  const [signupError, setSignupError] = React.useState()
  const [loginLoading, setLoginLoading] = React.useState(false)
  const [checked, setChecked] = React.useState(false)

  const [userdata, setUserdata] = React.useState({
    username:  '',
    surname:  '',
    patronymic:  '',
    birthdate: '',
    phone:  '',
    email:  '',
    password:  '',
  });

  const handleChange = (e) => {
    e.preventDefault()
    const value = e.target.value
    setUserdata({
      ...userdata,
      [e.target.name]: value
    })
  };

  const handleChange1 = (e) => {
    // e.preventDefault()
    const value = e.target.value
    setUserdata({
      ...userdata,
      [e.target.name]: value
    })
  };

  const submitCredentials = async (credentials, e) => {
    credentials.preventDefault()
    const Credentials = {
      username : userdata.username,
      surname : userdata.surname,
      patronymic : userdata.patronymic,
      birthdate : userdata.birthdate,
      phone : userdata.phone,
      email : userdata.email,
      password : userdata.password
    }
    try {
      if (checked === true) {
        setLoginLoading(true)
        const { data } = await publicFetch.post(`api/signup`, Credentials);
        console.log(data)
        setTimeout(() => {
          setLoginLoading(false)
          setSignupSuccess(data.message)
          authContext.setMailState(data)
        }, 3000)
        setSignupError('')
        setTimeout(() => {
          history('/authorization')
        }, 1000);
      } else {
        setSignupError(`Чтобы продолжить, Вы должны быть согласны с условиями обработки персональных данных`)
      }
    } catch (error) {
      setLoginLoading(false)
      // setTimeout(() => {
      const {data} = error.response
      setSignupError(data.message)
      // }, 3200)
      setSignupSuccess('')
    } 
  }

  const [showPassword, setShowPassword] = React.useState(false)
  const handleClickShowPassword = () => setShowPassword(!showPassword)
  const handleMouseDownPassword = () => setShowPassword(!showPassword)
  
  return (
    <>
      <a href='/'><img src={src} className='imgLogo' alt='imglogo'/></a>
      <Container className='FormGroupR' style={{width:'580px'}}>
        <h1>Зарегистрироваться в личном кабинете</h1>
        <form onSubmit={values => submitCredentials(values)} className='ECformR'>
          <div >
            <RedditTextField required id='surname' type='text' label='Фамилия' variant='filled' name='surname' value={userdata.surname} onChange={handleChange} />
          </div>
          <Container className='InputRow'>
            <div >
                <RedditTextField required id='username' type='text' label='Имя' variant='filled' name='username' value={userdata.username} onChange={handleChange} />
              </div>
              <div >
                <RedditTextField id='patronymic' type='text' label='Отчество (при наличии)' variant='filled' name='patronymic' value={userdata.patronymic} onChange={handleChange}/>
              </div>
          </Container>
          <div>
            <RedditTextField required id='birthdate' name='birthdate' type='date' label='Дата рождения' variant='filled' value={userdata.birthdate} onChange={handleChange} InputProps={{ disableUnderline:true }} inputProps={{ min: "1922-01-01", max: "2023-01-01"}} InputLabelProps={{shrink:true}}/>
          </div>
          <Container className='InputRow'>
            <div>
              <RedditTextField id='phone'  label='Контактный номер' variant='filled' name='phone' value={userdata.phone} onChange={handleChange1} InputProps={{inputComponent: TextMaskCustom1, disableUnderline: true, }} />
            </div>
            <div >
              <RedditTextField required id='email' type='email' label='Ваш email' variant='filled' name='email' value={userdata.email} onChange={handleChange} />
            </div>
          </Container>
          <div>
            <RedditTextField required id='password' type={showPassword ? "text" : "password"} label='Придумайте пароль' variant='filled' name='password'  value={userdata.password} onChange={handleChange} InputProps={{
              disableUnderline: true,
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="password-visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? <MdVisibility /> : <MdVisibilityOff />}
                  </IconButton>
                </InputAdornment>
              )
            }}/> 
          </div >
          <ButnotP type='submit' id='ButnotP1' name={loginLoading === true ? (<Spinner animation="border" style={{marginTop:'6px', scale:'80%'}}/>) : (<span>Продолжить</span>)} loading={loginLoading} />
          <ScrollDialog checked={checked} setChecked={setChecked} id='CheckBoxR'/>
        </form>
        {signupSuccess && (
          <p id='successM' style={{fontSize:'medium', color:'#81c784'}}>{signupSuccess}</p>
        )}
        {signupError && (
          <p id='errorM' style={{fontSize:'medium', fontWeight:400, color:'#f44336'}}>{signupError}<a href='/support'  style={{marginLeft:'1rem', fontWeight:'400'}}>Связаться с нами</a></p>
        )}   
        <p id='NoAccountR'>Есть аккаунт? <a href='/login' style={{margin:'10px'}}>Войти</a></p>
      </Container>
    </>
  )
}

export default SignUp