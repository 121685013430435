import React from 'react'
import { Container } from 'react-bootstrap'
import src from '../Styles/ECLOGOSVG.svg'
import './EasyPay.css'

const EasyInfo = [
  {
    id: 1,
    H3: 'Банковской картой',
    SPAN: 'по Qr-коду, реквизитам или через терминал',
    HREF: '/pay',
  },
  {
    id: 2,
    H3: 'Через личный кабинет',
    SPAN: 'без регистрации в пару кликов',
    HREF: '/login',
  },
]

// React.memo(function EasyCard(props)
const EasyCard = (props) => {
  return (
    // <React.Suspense>
      <div className='EPCard'>
        <img src={src} alt='right' ></img>
        <h3>{props.H3}</h3>
        <span>{props.SPAN}</span>
        <a href={props.HREF}>
          <button className='EPButton'>Погасить</button>
        </a>
      </div>
    // </React.Suspense>
  )
}

const ToggleContext = React.createContext()

function Toggle({onToggle, children}) {
  const [on,setOn] = React.useState(false)
  const toggle = () => setOn(on => !on)
  return (
    <ToggleContext.Provider value={{on, toggle}}>
      {children}
    </ToggleContext.Provider>
  )
}
function useToggle() {
  return React.useContext(ToggleContext)
}
// function ToggleOn({children}) {
//   const {on} = useToggle()
//   return on ? children : null
// }
// function ToggleOff ({children}) {
//   const {on} = useToggle()
//   return on ? null : children
// }
function ToggleCard (props) {
  const {on, toggle} = useToggle()
  return <EasyCard on={on} onMouseEnter={toggle} {...props}/>
}

const EasyPay = () => {
  return (
      <Toggle>
          <Container className='EPContainer'>
              {EasyInfo.length > 0 && (
                  <div key={EasyInfo[0].id}>
                      <ToggleCard H3={EasyInfo[0].H3} SPAN={EasyInfo[0].SPAN} HREF={EasyInfo[0].HREF}/>
                  </div>
              )}
          </Container>
      </Toggle>
/*    <Toggle>
        <Container className='EPContainer'>
          {EasyInfo.map((easy, i) => {
            return (
              <div key={EasyInfo[i].id}>
                <ToggleCard H3={easy.H3} SPAN={easy.SPAN} HREF={easy.HREF}/>
              </div>
            )
          })}
        </Container>
    </Toggle>*/
  )
}

export default React.memo(EasyPay)