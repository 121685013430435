import React from 'react'
import {motion} from 'framer-motion'
import './OfferSteps.css'

const icon = {
    hidden: {
      pathLength: 0,
      fill: "rgba(244, 241, 224, 0)"
    },
    visible: {
      pathLength: 1,
      fill: "rgba(244, 241, 224, 1)"
    }
};
  
export const Ex1 = () => (
    <div className="container1">
      <motion.svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512 512"
        className="item1"
      >
        <motion.path
          d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352c79.5 0 144-64.5 144-144s-64.5-144-144-144S64 128.5 64 208s64.5 144 144 144z"
          variants={icon}
          initial="hidden"
          animate="visible"
          transition={{
            default: { delay: 0.5, duration: 0.7, ease: "easeInOut" },
            fill: { delay: 0.5, duration: 1, ease: [1, 0, 0.8, 1] }
          }}
        />
      </motion.svg>
    </div>
);
export const Ex2 = () => (
    <div className="container1">
      <motion.svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 448 512"
        className="item1"
      >
        <motion.path
          d="M216.1 408.1C207.6 418.3 192.4 418.3 183 408.1L119 344.1C109.7 335.6 109.7 320.4 119 311C128.4 301.7 143.6 301.7 152.1 311L200 358.1L295 263C304.4 253.7 319.6 253.7 328.1 263C338.3 272.4 338.3 287.6 328.1 296.1L216.1 408.1zM128 0C141.3 0 152 10.75 152 24V64H296V24C296 10.75 306.7 0 320 0C333.3 0 344 10.75 344 24V64H384C419.3 64 448 92.65 448 128V448C448 483.3 419.3 512 384 512H64C28.65 512 0 483.3 0 448V128C0 92.65 28.65 64 64 64H104V24C104 10.75 114.7 0 128 0zM400 192H48V448C48 456.8 55.16 464 64 464H384C392.8 464 400 456.8 400 448V192z"
          variants={icon}
          initial="hidden"
          animate="visible"
          transition={{
            default: { delay: 1, duration: 0.7, ease: "easeInOut" },
            fill: { delay: 1, duration: 1, ease: [1, 0, 0.8, 1] }
          }}
        />
      </motion.svg>
    </div>
);
export const Ex3 = () => (
    <div className="container1">
      <motion.svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 384 512"
        className="item1"
      >
        <motion.path
          d="M374.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-320 320c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l320-320zM128 128c0-35.3-28.7-64-64-64S0 92.7 0 128s28.7 64 64 64s64-28.7 64-64zM384 384c0-35.3-28.7-64-64-64s-64 28.7-64 64s28.7 64 64 64s64-28.7 64-64z"
          variants={icon}
          initial="hidden"
          animate="visible"
          transition={{
            default: { delay: 1.5, duration: 0.7, ease: "easeInOut" },
            fill: { delay: 1.5, duration: 1, ease: [1, 0, 0.8, 1] }
          }}
        />
      </motion.svg>
    </div>
);
export const Ex4 = () => (
    <div className="container1">
      <motion.svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 576 512"
        className="item1"
      >
        <motion.path
          d="M64 0C28.7 0 0 28.7 0 64V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V428.7c-2.7 1.1-5.4 2-8.2 2.7l-60.1 15c-3 .7-6 1.2-9 1.4c-.9 .1-1.8 .2-2.7 .2H240c-6.1 0-11.6-3.4-14.3-8.8l-8.8-17.7c-1.7-3.4-5.1-5.5-8.8-5.5s-7.2 2.1-8.8 5.5l-8.8 17.7c-2.9 5.9-9.2 9.4-15.7 8.8s-12.1-5.1-13.9-11.3L144 381l-9.8 32.8c-6.1 20.3-24.8 34.2-46 34.2H80c-8.8 0-16-7.2-16-16s7.2-16 16-16h8.2c7.1 0 13.3-4.6 15.3-11.4l14.9-49.5c3.4-11.3 13.8-19.1 25.6-19.1s22.2 7.8 25.6 19.1l11.6 38.6c7.4-6.2 16.8-9.7 26.8-9.7c15.9 0 30.4 9 37.5 23.2l4.4 8.8h8.9c-3.1-8.8-3.7-18.4-1.4-27.8l15-60.1c2.8-11.3 8.6-21.5 16.8-29.7L384 203.6V160H256c-17.7 0-32-14.3-32-32V0H64zM256 0V128H384L256 0zM549.8 139.7c-15.6-15.6-40.9-15.6-56.6 0l-29.4 29.4 71 71 29.4-29.4c15.6-15.6 15.6-40.9 0-56.6l-14.4-14.4zM311.9 321c-4.1 4.1-7 9.2-8.4 14.9l-15 60.1c-1.4 5.5 .2 11.2 4.2 15.2s9.7 5.6 15.2 4.2l60.1-15c5.6-1.4 10.8-4.3 14.9-8.4L512.1 262.7l-71-71L311.9 321z"
          variants={icon}
          initial="hidden"
          animate="visible"
          transition={{
            default: { delay: 2, duration: 0.7, ease: "easeInOut" },
            fill: { delay: 2, duration: 1, ease: [1, 0, 0.8, 1] }
          }}
        />
      </motion.svg>
    </div>
);