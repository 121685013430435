import {FormControl, FormControlLabel, FormGroup, FormHelperText, Radio, RadioGroup, TextField,} from '@mui/material';
import React from 'react';
import ButnotS from '../Styles/butnotS';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import './Calculator.css'
import {tel} from '../Styles/colors';


const theme = createTheme({
    palette: {
        secondary: {
            // This is green.A700 as hex.
            main: '#11cb5f',
        },
    },
});

function CalculatorR() {

    const [checked, setChecked] = React.useState('six')
    const [summ, setSumm] = React.useState({
        sum: ''
    });
    const [calc, setCalc] = React.useState([]);
    const [showCustomMonths, setShowCustomMonths] = React.useState(false);
    const [customMonths, setCustomMonths] = React.useState('');

    const handleChange = (e) => {
        setChecked(e.target.value);
        setCalc([]);

        if (e.target.value === 'overtwelve') {
            setShowCustomMonths(true);
        } else {
            setShowCustomMonths(false);
        }

    };

    const handleChange1 = (event) => {
        event.preventDefault()
        const value = event.target.value
        setSumm({
            ...summ,
            [event.target.name]: value
        })
    };

    function divideAmount(totalAmount, totalMonths) {
        const amountNMonths = Math.floor(totalAmount / totalMonths);
        const amountLastMonth = totalAmount - amountNMonths * (totalMonths - 1);
        const result = Array(totalMonths - 1).fill(amountNMonths);
        result.push(amountLastMonth);
        return result;
    }

    function calculateFutureDate(monthsToAdd) {
        const currentDate = new Date();
        const futureDate = new Date(currentDate);
        futureDate.setMonth(currentDate.getMonth() + monthsToAdd);
        return futureDate;
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        const result = [];

        if (checked !== 'overtwelve' || (showCustomMonths && customMonths > 0)) {
            const totalMonths = checked === 'six' ? 6 : checked === 'twelve' ? 12 : customMonths;
            const discount = checked === 'six' ? 0.8 : checked === 'twelve' ? 0.9 : 1;
            const basePayment = summ.summ * discount;
            const installment = divideAmount(basePayment, totalMonths);

            const currentDate = new Date();

            installment.forEach((item, i) => {
                const futureDate = calculateFutureDate(i);
                result.push({
                    month: `${(futureDate.getMonth() + 1).toString().padStart(2, '0')}.${futureDate.getFullYear()}`,
                    sum: `${item.toFixed(2)} руб.`,
                });
                currentDate.setMonth(currentDate.getMonth() + 1);
                futureDate.setMonth(futureDate.getMonth() + 1);
            });
        } else {
            const calcOverTwelve = Number(summ.summ).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
            return setCalc([`${calcOverTwelve} руб. Вам доступна бонусная накопительная система.`]);
        }
        setCalc(result);
    };

    return (
        <>
            <form onSubmit={values => handleSubmit(values)}>
                <FormControl required>
                    <FormGroup sx={{
                        color: 'rgb(244, 241, 224)',
                        fontWeight: '300',
                        fontSize: 'x-large',
                        marginTop: '-1.25rem',
                    }}>
                        <div className='calcWrapper'>
                            <label htmlFor='summ'>Сумма долга:</label>
                            <TextField
                                required
                                value={summ.summ}
                                type='number'
                                onChange={handleChange1}
                                color='success'
                                variant='standard'
                                id='summ'
                                name='summ'
                                sx={{
                                '& .MuiInputBase-input': {
                                    color: '#d9b173',
                                    fontWeight: '500',
                                    letterSpacing: '0.5px',
                                    fontSize: 'large',
                                }, position: 'relative', top: '3px', minWidth: '60px', maxWidth: '160px'
                            }}/>
                        </div>
                        <div>
                            <FormHelperText sx={{marginLeft: '0rem'}}>Выберите удобный период
                                рассрочки:</FormHelperText>
                            <RadioGroup row={true} name='use-radio-group' value={checked} onChange={handleChange}
                                        defaultValue='six' className='checkOps'>
                                <ThemeProvider theme={theme}>
                                    <FormControlLabel value='six' control={<Radio disableRipple color='secondary' sx={{
                                        color: 'rgb(244, 241, 224)',
                                        marginRight: '0.25rem',
                                        position: 'relative'
                                    }}/>} label='до 6 мес.' sx={{color: 'rgb(232, 188, 123)'}}/>
                                    <FormControlLabel value='twelve' control={<Radio disableRipple color='secondary'
                                                                                     sx={{
                                                                                         color: 'rgb(244, 241, 224)',
                                                                                         marginRight: '0.25rem',
                                                                                         position: 'relative'
                                                                                     }}/>} label='до 12 мес.'
                                                      sx={{color: 'rgb(232, 188, 123)'}}/>
                                    <FormControlLabel value='overtwelve' control={<Radio disableRipple color='secondary'
                                                                                         sx={{
                                                                                             color: 'rgb(244, 241, 224)',
                                                                                             marginRight: '0.25rem',
                                                                                             position: 'relative'
                                                                                         }}/>} label='свыше 12 мес.'
                                                      sx={{color: 'rgb(232, 188, 123)'}}/>
                                </ThemeProvider>
                            </RadioGroup>
                        </div>
                        <div className='calcBottomWrapperO'>
                            {showCustomMonths && checked === 'overtwelve' ? (
                                <>
                                    <TextField
                                        label="Введите количество месяцев"
                                        type="number"
                                        value={customMonths}
                                        onChange={(e) => setCustomMonths(e.target.value)}
                                        inputProps = {{
                                            min: "13",
                                            required: true
                                        }}
                                        InputLabelProps={{ style: { color: 'white', fontSize: '14px'}}}
                                        InputProps ={{style:{color:'white'}}}
                                    />
                                    <ButnotS type="submit" name="Рассчитать задолженность" id="c1" style = {{marginBottom:"10px"}}/>
                                </>
                            ) : (
                                <ButnotS type="submit" name="Рассчитать задолженность" id="c1" style = {{marginBottom:"10px"}}/>
                            )}
                            {
                                checked === 'six' && calc.length > 0 ? (
                                <div>
                                    <table border='1' className="table_for_arrears">
                                        <thead>
                                        <tr>
                                            <th>Месяц</th>
                                            <th>Сумма</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {calc.map((item) =>
                                            <tr>
                                                <td>{item.month}</td>
                                                <td>{item.sum}</td>
                                            </tr>
                                        )}
                                        </tbody>
                                    </table>
                                    <br/>
                                    Для подтверждения свяжитесь с нами:
                                    <br/>
                                    Телефон:
                                    <a href="tel:83412940707">{tel}</a>
                                    <br/>
                                    Email:
                                    <a href='mailto:collect@ec18.ru'>collect@ec18.ru</a>
                                </div>
                                ): null
                            }
                            {
                                checked === 'twelve' && calc.length > 0 ? (
                                    <div>
                                        <table border='1' className="table_for_arrears">
                                            <thead>
                                            <tr>
                                                <th>Месяц</th>
                                                <th>Сумма</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {calc.map((item) =>
                                                <tr>
                                                    <td>{item.month}</td>
                                                    <td>{item.sum}</td>
                                                </tr>
                                            )}
                                            </tbody>
                                        </table>
                                        <br/>
                                        Для подтверждения свяжитесь с нами:
                                        <br/>
                                        Телефон:
                                        <a href="tel:83412940707">{tel}</a>
                                        <br/>
                                        Email:
                                        <a href='mailto:collect@ec18.ru'>collect@ec18.ru</a>
                                    </div>
                                ) : null
                            }
                            {
                                checked === 'overtwelve' && calc.length > 0 ? (
                                    <div>
                                        <table border='1' className="table_for_arrears">
                                            <thead>
                                            <tr>
                                                <th>Месяц</th>
                                                <th>Сумма</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {calc.map((item) =>
                                                <tr>
                                                    <td>{item.month}</td>
                                                    <td>{item.sum}</td>
                                                </tr>
                                            )}
                                            </tbody>
                                        </table>
                                        Вам доступна бонусная накопительная система.
                                        <br/>
                                        Для подтверждения свяжитесь с нами:
                                        <br/>
                                        Телефон:
                                        <a href="tel:83412940707">{tel}</a>
                                        <br/>
                                        Email:
                                        <a href='mailto:collect@ec18.ru'>collect@ec18.ru</a>
                                    </div>
                                ) : null
                            }
                        </div>
                    </FormGroup>
                </FormControl>
            </form>
        </>
    )
}

export default CalculatorR