import React, {useState} from 'react';
import {FaArrowCircleUp, FaVk} from 'react-icons/fa';
import styled from '@emotion/styled'
import whatsapp from '../Icons/whatsapp.svg'
import telegram from '../Icons/telegram.svg'
import support from '../Icons/headset-solid.svg'
import './TopScroll.css'
import { Tooltip } from '@mui/material';
  
const ScrollButton = () => {
    const Button = styled.div`
        position: fixed; 
        width: 100%;
        z-index: 5000;
        @media(min-width: 1200px) {
            left: 96%;
            bottom: 70px;
            height: 16px;
            font-size: 2.25rem;
            cursor: pointer;
            color: hsla(0, 0%, 80%, 0.712);
        };
        @media (max-width: 480px) {
            left: 70.1%;
            bottom: 56px;
            height: 16px;
            font-size: 2.2rem;
            cursor: pointer;
            color: rgba(199, 199, 199, 0.595);
        };
    `
    const CircleMsg = styled.div`
        display: flex;
        flex-direction: column;
        position: fixed;
        overflow-y: hidden;
        overflow-x: visible;
        transition: 0.32s cubic-bezier(0.4, 0, 0.2, 1);
        -webkit-transition: .32s cubic-bezier(0.4, 0, 0.2, 1);
        -moz-transition: .32s cubic-bezier(0.4, 0, 0.2, 1);
        -ms-transition: .32s cubic-bezier(0.4, 0, 0.2, 1);
        -o-transition: .32s cubic-bezier(0.4, 0, 0.2, 1);
        z-index: 5000;
        transform: translate3d(0, 0, 0);
        -ms-transform: translate3d(0, 0, 0);
        will-change: transform;
        @media(min-width: 1200px) {
          width: 64px;
          height: 64px;
          left: 95.2%;
          bottom:100px;
          padding-top: 3.5rem;
          background: linear-gradient(135deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0));
          backdrop-filter: blur(15px);
          -webkit-backdrop-filter: blur(15px);
          border-radius: 100px;
          justify-content: space-evenly;
          gap: 14px;
          text-align: center;
          align-items: center;
          filter: drop-shadow(
            0 1px 1px #00000033
          );
          cursor: pointer;
        }
        @media (max-width:480px) {
          width: 64px;
          height: 64px;
          left:82%;
          bottom: 10px;
          background: linear-gradient(135deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0));
          backdrop-filter: blur(15px);
          -webkit-backdrop-filter: blur(15px);
          border-radius: 100px;
          justify-content: space-evenly;
          padding-top: 3.3rem;
          gap: 11px;
          text-align: center;
          align-items: center;
          filter: drop-shadow(
            0 1px 1px #00000033
          );
        }
    `
  const [visible, setVisible] = useState(false);
  
  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 500){
      setVisible(true)
    } 
    else if (scrolled <= 300){
      setVisible(false)
    }
  };
  
  const scrollToTop = () =>{
    window.scrollTo({
      top: 0, 
      behavior: 'smooth'
      /* you can also use 'auto' behaviour
         in place of 'smooth' */
    });
  };
  
  React.useLayoutEffect(() => {
    function watchScroll() {
      window.addEventListener('scroll', toggleVisible);
    };
    watchScroll();
    return () => {
      window.removeEventListener("scroll", toggleVisible);
    };
  }, []);
  
  const preventDragHandler = (e) => {
    e.preventDefault()
  };

  return (
    <>
      <div style={{display: visible ? 'inline' : 'none', flexDirection:'column', gap:'10px'}}>
        <CircleMsg className='MsgCircle' id='MsgCircle'>
          <Tooltip title='WhatsApp' placement='left' disableInteractive={true}>
            <a href='https://wa.me/79090559988'>
              <img src={whatsapp} alt='whatsapp' onDragStart={preventDragHandler}/>
            </a>
          </Tooltip>
          <Tooltip title='Telegram' placement='left' disableInteractive={true}>
            <a href='https://telegram.me/expresscollection'>
              <img src={telegram} alt='telegram' onDragStart={preventDragHandler}/>
            </a>
          </Tooltip>
          <Tooltip title='Сообщество VK' placement='left' disableInteractive={true}>
            <a href='https://vk.com/club217586890'>
              <FaVk onDragStart={preventDragHandler} style={{fontSize:'42px'}}/>
            </a>
          </Tooltip>
          <Tooltip title='Интернет приемная' placement='left' disableInteractive={true}>
            <a href='/support'>
              <img src={support} alt='support' onDragStart={preventDragHandler} style={{scale:'0.9'}}/>
            </a>
          </Tooltip>
        </CircleMsg>
        <Button>
          <FaArrowCircleUp onClick={scrollToTop} 
            style={{display: visible ? 'inline' : 'none'}} />
        </Button>
      </div>
    </>
  );
}
  
export default React.memo(ScrollButton);