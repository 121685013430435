import { Tooltip } from '@mui/material';
import React from 'react'
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import { FetchContext } from '../context/FetchContext';
import './Profile.css'

function Profile() {
    const fetchContext = React.useContext(FetchContext)
    const [profileData, setProfileData] = React.useState()
    const auth = React.useContext(AuthContext)
    const history = useNavigate()
    const handleClick = () => {history('/support')}
    const handleSettings = () => {history('/settings')}

    const getProfileData = async () => {
        try {
            const { data } = await fetchContext.authAxios.get(
                `api/profile-data`,
                {timeout: 5000},
            );
            setProfileData(Object.values(data));
        } catch (error) {
            console.log(error)
        }
    };

    React.useEffect(() => {
        getProfileData();
    }, [fetchContext]);

    const StringToHsl = (str) => {
        var hash = 0
        for (let i = 0; i < str.length; i++) {
            hash = str.charCodeAt(i) + ((hash << 5) - hash);
            // setHash(getHash)
        }
        var h = hash % 360
        return `hsl(${h}, 40%, 80%)`
    }

    const StringAvatar = (str) => { 
        // console.log(str.str.slice(0, 3))
        const str1 = str.str.slice(0,3).join('').replace(/[,]+/g, '')
        // console.log(str)
        return (
            <div className='AvatarC'>
                {str && Object.keys(str).map((keyName, i) => {
                    return (
                        <div key={i}>
                            <div className='pBack' style={{backgroundColor: `${StringToHsl(str1)}`}}>
                                <p>{str[keyName][0][0]}</p>
                            </div>
                        </div> 
                    )
                })} 
            </div>
        )
    }

    const Username = (str) => {
        return (
            <div>
                {str && typeof str !== 'undefined' && Object.keys(str).map((keyName, i) => {
                    return (
                        <div key={i} className='usernameA'>
                            <p>{str[keyName][1]}</p>
                            <p>{str[keyName][0]}</p>
                            <p>{str[keyName][2]}</p>
                        </div>
                    )
                })}
            </div>
        )
    }
    
    const RegPlace = (str) => {
        return (
            <div>
                {str && typeof str !== 'undefined' && Object.keys(str).map((keyName, i)=> {
                    return (
                        <div key={i} >
                            <p>{str[keyName][3]}</p>
                        </div>
                    )
                })}
            </div>
        )
    }

    const Birthdate = (str) => {
        const birthstring = (keyName) => str[keyName][4].slice(8) + '.' + str[keyName][4].slice(5,7) + '.' + str[keyName][4].slice(0,4)
        return (
            <div>
                {str && typeof str !== 'undefined' && Object.keys(str).map((keyName, i)=> {
                    return (
                        <div key={i} >
                            {str && typeof str === 'undefined' ? ' ' : <p>{birthstring(keyName)}</p>}
                        </div>
                    )
                })}
            </div>
        )
    }

    return (
        <React.Suspense>
            <div className='profileContainer'>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" className='svg1'>
                    <path d="M137.4 374.6c12.5 12.5 32.8 12.5 45.3 0l128-128c9.2-9.2 11.9-22.9 6.9-34.9s-16.6-19.8-29.6-19.8L32 192c-12.9 0-24.6 7.8-29.6 19.8s-2.2 25.7 6.9 34.9l128 128z"/>
                </svg>
                {profileData ? (
                    <div className='mainColumn'>
                        <div className='pWrapper'>
                            <StringAvatar str={profileData}/>
                            <Username str={profileData}/>
                        </div>
                        <div className='regContainer'>
                            <div className='regCol'>
                                <p style={{fontWeight:'600', fontFamily:'Overpass, sans-serif', paddingTop:'4px', paddingBottom:'2px'}}>Дата рождения</p>
                                <Birthdate str={profileData}/>
                                <p style={{fontWeight:'600', fontFamily:'Overpass, sans-serif', paddingTop:'4px', paddingBottom:'2px'}}>Адрес регистрации</p>
                                <RegPlace str={profileData}/>
                            </div>
                            <div className='regCol'>
                                <Tooltip title='Настройки' placement='left' disableInteractive={true} onClick={handleSettings}>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                        <path d="M495.9 166.6c3.2 8.7 .5 18.4-6.4 24.6l-43.3 39.4c1.1 8.3 1.7 16.8 1.7 25.4s-.6 17.1-1.7 25.4l43.3 39.4c6.9 6.2 9.6 15.9 6.4 24.6c-4.4 11.9-9.7 23.3-15.8 34.3l-4.7 8.1c-6.6 11-14 21.4-22.1 31.2c-5.9 7.2-15.7 9.6-24.5 6.8l-55.7-17.7c-13.4 10.3-28.2 18.9-44 25.4l-12.5 57.1c-2 9.1-9 16.3-18.2 17.8c-13.8 2.3-28 3.5-42.5 3.5s-28.7-1.2-42.5-3.5c-9.2-1.5-16.2-8.7-18.2-17.8l-12.5-57.1c-15.8-6.5-30.6-15.1-44-25.4L83.1 425.9c-8.8 2.8-18.6 .3-24.5-6.8c-8.1-9.8-15.5-20.2-22.1-31.2l-4.7-8.1c-6.1-11-11.4-22.4-15.8-34.3c-3.2-8.7-.5-18.4 6.4-24.6l43.3-39.4C64.6 273.1 64 264.6 64 256s.6-17.1 1.7-25.4L22.4 191.2c-6.9-6.2-9.6-15.9-6.4-24.6c4.4-11.9 9.7-23.3 15.8-34.3l4.7-8.1c6.6-11 14-21.4 22.1-31.2c5.9-7.2 15.7-9.6 24.5-6.8l55.7 17.7c13.4-10.3 28.2-18.9 44-25.4l12.5-57.1c2-9.1 9-16.3 18.2-17.8C227.3 1.2 241.5 0 256 0s28.7 1.2 42.5 3.5c9.2 1.5 16.2 8.7 18.2 17.8l12.5 57.1c15.8 6.5 30.6 15.1 44 25.4l55.7-17.7c8.8-2.8 18.6-.3 24.5 6.8c8.1 9.8 15.5 20.2 22.1 31.2l4.7 8.1c6.1 11 11.4 22.4 15.8 34.3zM256 336c44.2 0 80-35.8 80-80s-35.8-80-80-80s-80 35.8-80 80s35.8 80 80 80z"/>
                                    </svg>
                                </Tooltip>
                                <Tooltip title='Выход' placement='left' disableInteractive={true}>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" onClick={auth.logout}>
                                        <path d="M534.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L434.7 224 224 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l210.7 0-73.4 73.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l128-128zM192 96c17.7 0 32-14.3 32-32s-14.3-32-32-32l-64 0c-53 0-96 43-96 96l0 256c0 53 43 96 96 96l64 0c17.7 0 32-14.3 32-32s-14.3-32-32-32l-64 0c-17.7 0-32-14.3-32-32l0-256c0-17.7 14.3-32 32-32l64 0z"></path>
                                    </svg>
                                </Tooltip>
                                <Tooltip title='Поддержка' placement='left' disableInteractive={true}>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" onClick={handleClick}>
                                        <path d="M256 48C141.1 48 48 141.1 48 256v40c0 13.3-10.7 24-24 24s-24-10.7-24-24V256C0 114.6 114.6 0 256 0S512 114.6 512 256V400.1c0 48.6-39.4 88-88.1 88L313.6 488c-8.3 14.3-23.8 24-41.6 24H240c-26.5 0-48-21.5-48-48s21.5-48 48-48h32c17.8 0 33.3 9.7 41.6 24l110.4 .1c22.1 0 40-17.9 40-40V256c0-114.9-93.1-208-208-208zM144 208h16c17.7 0 32 14.3 32 32V352c0 17.7-14.3 32-32 32H144c-35.3 0-64-28.7-64-64V272c0-35.3 28.7-64 64-64zm224 0c35.3 0 64 28.7 64 64v48c0 35.3-28.7 64-64 64H352c-17.7 0-32-14.3-32-32V240c0-17.7 14.3-32 32-32h16z"></path>
                                    </svg>
                                </Tooltip>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className='regContiner'>
                        <div className='regCol'>
                            <Tooltip title='Выход' placement='right'>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" onClick={auth.logout} style={{position:'absolute', top:'1.25rem', left:'1.25rem'}}>
                                    <path d="M534.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L434.7 224 224 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l210.7 0-73.4 73.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l128-128zM192 96c17.7 0 32-14.3 32-32s-14.3-32-32-32l-64 0c-53 0-96 43-96 96l0 256c0 53 43 96 96 96l64 0c17.7 0 32-14.3 32-32s-14.3-32-32-32l-64 0c-17.7 0-32-14.3-32-32l0-256c0-17.7 14.3-32 32-32l64 0z"></path>
                                </svg>
                            </Tooltip>
                        </div>
                    </div>
                )}
            </div>
        </React.Suspense>
    )
}

export default React.memo(Profile)