import React from 'react'
import "../components/Tabs-motion.css"
import terminal from '../images/terminal.png'
import Footer from '../components/Footer';
import '../Styles/PayPage.css';
import {QRCodeSVG} from 'qrcode.react';
import {motion} from 'framer-motion'
import { BsCreditCard } from 'react-icons/bs';
import { SiWebmoney } from 'react-icons/si';
import TwoStep from '../components/PayReqs';
import moment from 'moment'

const INN='1831167427'
const Account='40702810568000021825'
const BIC='049401601'
const CorrAccount='30101810400000000601'
const KPP='183201001'
const Recipient='Общество с ограниченной ответственностью Профессиональная коллекторская организация "Экспресс Коллекшн"'
const Bank='УДМУРТСКОЕ ОТДЕЛЕНИЕ N8618 ПАО СБЕРБАНК'

const requisites = {
  INN: INN,
  Account: Account,
  BIC: BIC,
  CorrAccount: CorrAccount,
  KPP: KPP,
  Recipient: Recipient,
  Bank: Bank,
}

function TabItem({ isOpen, children, ...props}) {
  const variants = {
      open: {opacity: 1, top: 0},
      closed: {opacity: 0, top:0},
  }
  props = {
      animate: isOpen ? 'open' : 'closed',
      ...props,
  }
  
  return (
      <motion.div
          layout
          initial='closed'
          animate = {isOpen ? 'open' : 'closed'}
          variants = {variants}
          transition = {{duration: 0.1}}
          {...props}
      >{children}</motion.div>
  )
}

const actionTypes = {toggle_index: 'toggle_index'}
function accordionReducer(openIndexes, action) {
    switch (action.type) {
        case actionTypes.toggle_index: {
            const closing = openIndexes.includes(action.index)
            return closing
                ? openIndexes.filter((i) => i !== action.index)
                : [...openIndexes, action.index]
        }
        default: {
            throw new Error(`Unhandled type in accordionReducer: ${action.type}`)
        }
    }
}
  
function useAccordion({reducer = accordionReducer} = {}) {
    const [openIndexes, dispatch] = React.useReducer(reducer, [0])
    const toggleIndex = (index) =>
        dispatch({type: actionTypes.toggle_index, index})
    return {openIndexes, toggleIndex}
}

function preventCloseReducer(openIndexes, action) {
    if (action.type === actionTypes.toggle_index) {
        const closing = openIndexes.includes(action.index)
        const isLast = openIndexes.length < 2
        if (closing && isLast) {
            return openIndexes
        }
    }
}
  
function singleReducer(openIndexes, action) {
    if (action.type === actionTypes.toggle_index) {
        const closing = openIndexes.includes(action.index)
        if (!closing) {
            return [action.index]
        }
    }
}
  
function combineReducers(...reducers) {
    return (state, action) => {
        for (const reducer of reducers) {
            const result = reducer(state, action)
            if (result) return result
        }
    }
}

function useTabs({reducer = () => {}} = {}) {
    return useAccordion({
      reducer: combineReducers(
        reducer,
        preventCloseReducer,
        singleReducer,
        accordionReducer,
      ),
    })
}

function Pay () {
  const [qr, setQr] = React.useState()
  const [hide, setHide] = React.useState(false)
  const [reqs, setReqs] = React.useState('')
  const [error, setError] = React.useState()
  const {openIndexes, toggleIndex} = useTabs()

  const [userdata, setUserdata] = React.useState({
    name: '',
    date: '',
    dogovor: '',
  })

  React.useEffect(() => {
    document.title = 'Экспресс Коллекшн';
  }, []);

  const handleChange = (e) => {
    e.preventDefault()

    const value = e.target.value
    setUserdata({
      ...userdata,
      [e.target.name]: value
    })
  };

  const handleClick = () => setHide(false)

  const handleSubmit = async (Credentials) => {
    Credentials.preventDefault()
    const credentials = {
      name: userdata.name,
      date: userdata.date,
      dogovor: userdata.dogovor
    }
    try {
      setHide(!hide)
      reqs.length > 0 && setReqs('')
      
      const credentialsNew = credentials.dogovor !== undefined ? `Погашение задолженности по договору №${userdata.dogovor} (${userdata.name} ${moment(userdata.date).format('DD-MM-YYYY')})` : `Погашение задолженности, ${userdata.name}, ${userdata.date}`
      setTimeout(() => {
        setReqs(credentialsNew)
        const qrString = `ST00012|Name=${requisites.Recipient}|PersonalAcc=${requisites.Account}|BankName=${requisites.Bank}|BIC=${requisites.BIC}|CorrespAcc=${requisites.CorrAccount}|PayeeINN=${requisites.INN}|KPP=${requisites.KPP}|Purpose=${credentialsNew}|Sum=`
        setError('')
        setTimeout(() => {
          setQr(
            <QRCodeSVG
              value= {qrString}
              size={256}
              bgColor={'transparent'}
              fgColor={'#0f281e'}
              level={'H'}
              includeMargin={false}
            />
          )
        }, 3000);
      }, 1200);
    } catch (error) {
      setHide(false)
      setError(`${error.message}`)
    }
  }
  
  const items = [
    {
      id : (
        1
      ),
      title: (
        <h3>Оплата по реквизитам или через QR-код</h3>
        ),
      bs: (
        <div>
          <BsCreditCard className='BiTime'/>
        </div>
      ),
      contents: (
        <div className='tabContainer2'>
          <TwoStep hide={hide} handleChange={handleChange} qr={qr} userdata={userdata} reqs={reqs} handleClick={handleClick} handleSubmit={(values) => handleSubmit(values)}/>
        </div>
      ),
    },
    {
      id : (
        2
      ),
      title: (
        <h3>Оплата в терминале</h3>
        ),
      bs: (
        <div>
          <SiWebmoney className='BiTime'/>
        </div>
      ),
      contents: (
        <div className='tabContainer2'>
          <div className='TerminalRow'>
            <span>Оплатить задолженность также легко через терминал – для этого зайдите в окно поиска, в раздел оплаты задолженности и введите ООО "Экспресс Коллекшн".</span>
            <img src={terminal} alt='terminal'/>
          </div>
        </div>
      ),
    },
  ]

  

  return (
    <> 
      <div className='BlockAndRoll' >
          <div className='PaymentContainer'>
            <React.Suspense fallback={<p>Loading...</p>}> 
              <div className='tabs2'>
                <div className="tabsList2">
                  {items.map((item, index) => (
                      <div className={`tabHead2 ${openIndexes.includes(index) ? 'active3' : null}`}
                          key={item.id}
                          isOpen={openIndexes.includes(index)}
                          onClick={() => toggleIndex(index)}
                      >
                          {item.bs}
                          {item.title}
                      </div>
                  ))}
                </div>
                <div className="tabContent2" >
                  {items.map((item, index) => (
                      <TabItem style={{position: 'absolute', overflowY:'hidden', maxWidth:'100%', right:'0', left:'0'}}
                          key={item.id}
                          isOpen={openIndexes.includes(index)}
                      >
                          {items[index].contents}
                      </TabItem>
                  ))}
                </div>
              </div>  
              {error && <div>{error}</div>}
            </React.Suspense>
          </div>
      </div>
      <div id='FooterP'>
        <Footer/>
      </div>
    </>
  )
}

export default React.memo(Pay)