import axios from 'axios';

const publicFetch = axios.create({
    //baseURL: process.env.REACT_APP_API_URL,
    baseURL: '/api',
    //timeout: 1000,
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json; charset=utf-8'
    }
});

export { publicFetch }