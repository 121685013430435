import { TextField } from '@mui/material'
import dayjs from 'dayjs'
import React from 'react'
import { Spinner } from 'react-bootstrap'
import { useVirtualizer } from '@tanstack/react-virtual'
import '../Styles/AdminPage.css'
import { BsArrow90DegLeft } from 'react-icons/bs'
import { FetchContext } from '../context/FetchContext'

const RequestItem = ({id, question}) => {
    return (
        <>
            <div className='AFirstRow'>
                <div>Зарпос №{id}</div>
            </div>
            <div className='ASecondRow'>
                <div className='LeftPart'>
                    <div style={{fontWeight:'600'}}>{question}</div>
                </div>
            </div>
        </>
    )
}

const TextComment = React.memo(({value, id, setter, commentLoading}) => {
    const handleChange = (e) => {
        const { value: inputValue } = e.target
        setter(inputValue)
    }
    
    return (
        <div className='Cleft'>
            <TextField multiline value={value} onChange={handleChange} label='Дать публичный ответ' minRows={1} maxRows={3} id={id} variant='outlined' name='Comment' InputLabelProps={{shrink: true}} inputProps={{style:{ color:'black', marginTop:0, marginBottom:-6}}} sx={{'& label':{mt:-0.5, ml:0.1,outline:'8px solid white', backgroundColor:'white','&.Mui-focused':{ background:'white',outline:'8px solid white',color:'#28a745',marginLeft:0.1, fontSize:'medium',marginTop:-0.5,}}, my:2, width:'320px', '& .MuiOutlinedInput-root.Mui-focused':{'& .MuiOutlinedInput-notchedOutline': {border:'1px solid #28a745',boxShadow:'0 0 0 0.2rem rgba(40, 167, 69, 0.25)'}}, '& .MuiOutlinedInput-root:hover':{'& .MuiOutlinedInput-notchedOutline': {border:'1px solid #28a745'}}}}></TextField>
            <button type='submit' style={{width:'50px', height:'50px'}}>{commentLoading === true ? (<Spinner animation="border" style={{marginTop:'6px'}}/>) : (<BsArrow90DegLeft/>)}</button>
        </div>
    )
})

const QuestionBase = ({questionRef, request, virtualRows, totalHeight, Comment, handleComment, setComment, commentLoading}) => {
    return (
        <div className='AdminFeed' ref={questionRef}>
            {request ? (
                <div className='AdminFeedItems' style={{height: totalHeight, display:'block', position:'relative', width:'100%', overfflowX:'hidden'}}>
                    {virtualRows.map(({index, size, start}) => {
                        const virtualRow = request[index]
                        if (!virtualRow) return null
                        return (
                            <div key={virtualRow.id} className='AdminFeedItem' style={{position:'absolute', top:'0', left:'0', width:'100%', height:`${size}px`, transform: `translateY(${start}px)`, borderBottom:'1px solid #bbb'}} index={index}>
                                <RequestItem
                                    id = {virtualRow.id}
                                    question = {virtualRow.Question}
                                />
                                <div className='ACommentRow' style={{margin:'1rem'}}>
                                    <div className='Cleft'>
                                        <form onSubmit={(values) => handleComment(values, virtualRow.id)} className='Cleft'>
                                            <TextComment commentLoading={commentLoading} value={Comment.Comment} setter={setComment} id={`comment-${virtualRow.id}`}/>
                                        </form>
                                    </div>
                                    <div className='Cright'>
                                        <div>{dayjs(virtualRow.createdAt).format('D MMM, YYYY h:mm A')}</div>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            ) : (
                <Spinner animation="border" style={{margin:'auto', display:'flex', position:'relative', marginTop:'30%'}}/>
            )}
        </div>
    )
}

function AdminPageQuestions() {
    const fetchContext = React.useContext(FetchContext)
    const questionRef = React.useRef(null)
    const [request, setRequest] = React.useState('')
    const [commentError, setCommentError] = React.useState('')
    const [commentSuccess, setCommentSuccess] = React.useState('')
    const [commentLoading, setCommentLoading] = React.useState(false)
    const [comment, setComment] = React.useState('')

    const rowVirtualizer = useVirtualizer({
        count: request ? request.length : 1,
        getScrollElement: React.useCallback(() => questionRef.current,[]),
        estimateSize: React.useCallback(()=>278,[]),
        overscan: 4,
    })

    React.useEffect(() => {
        const getAllQuestions = async () => {
            try {
                const {data} = await fetchContext.authAxios.get(
                    `api/questions-request`
                )
                setRequest(data)
            } catch (err) {
                console.log(err)
            }
        };
        getAllQuestions();
    },[fetchContext])

    const handleComment = async(e, id) => {
        e.preventDefault()
        const Credentials = {
            Comment: comment
        }
        try {
            if (comment.length > 0) {
                setCommentLoading(true)
                const { data } = await fetchContext.authAxios.patch(`api/answer-question/${id}`, Credentials)
                setTimeout(() => {
                    setCommentSuccess(data.message);
                    setCommentError('')
                    setCommentLoading(false)
                    alert(commentSuccess)
                    setComment('')
                    window.location.reload(true)
                }, 1200)
            }
        } catch (error) {
            setCommentLoading(false)
            setCommentSuccess('')
            const { data } = error.response
            setCommentError(data.message)
            alert(commentError)
        }
    }
    
    return (
        <React.Suspense>
            <div className='AdminContainer' id='AQuestions'>
                <QuestionBase handleComment={handleComment} questionRef={questionRef} request={request} virtualRows={rowVirtualizer.getVirtualItems()} totalHeight={rowVirtualizer.getTotalSize()} Comment={comment} setComment={setComment} commentLoading={commentLoading}/>
            </div>
        </React.Suspense>
    )
}

export default React.memo(AdminPageQuestions)