
import { Button, Input } from '@mui/material'
import { useVirtualizer } from '@tanstack/react-virtual'
import React from 'react'
import { FaArrowUp } from 'react-icons/fa'
import { FetchContext } from '../context/FetchContext'
import '../Styles/AdminPage.css'

function AllArticlezzz ({allArticles, virtualRows, totalHeight, AllRef, handleSubmit, handleDelete, handleChange, handleChange2, handleChange3, doc, track, pic}) {
  return (
    <>
      <div className='AdminFeed' id='allFeed' ref={AllRef}>
        {allArticles ? (
          <div className='AdminFeedItems' style={{height: totalHeight, display:'block', position:'relative', width:'100%', overfflowX:'hidden', marginTop:'0'}}>
            {virtualRows.map(({index, size, start}) => {
              const virtualRow = allArticles[index]
              if (!virtualRow) return null
                return (
                  <div key={virtualRow.postId} className='AdminFeedItem' style={{position:'absolute', top:'0', left:'0', width:'100%', height:`${size}px`, transform: `translateY(${start}px)`, borderBottom:'5px solid #fbfbfb'}} index={index}>
                    <form style={{display:'flex', flexDirection:'column'}} onSubmit={values => handleSubmit(values, virtualRow.postId)}>
                      <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between'}}>
                        <h2>{virtualRow.postId}. {virtualRow.title}</h2>
                        <button type='button' onClick={e => handleDelete(e, virtualRow.postId)} style={{height:'42px', borderRadius:'5px', paddingLeft: '10px', paddingRight:'10px'}}>Удалить</button>
                      </div>
                      <label htmlFor={`title${virtualRow.postId}`}>Заголовок</label>
                      <textarea id={`title${virtualRow.postId}`} name='title' onChange={handleChange} defaultValue={virtualRow.title}></textarea>
                      <label htmlFor={`topic${virtualRow.postId}`}>Тема поста</label>
                      <textarea id={`topic${virtualRow.postId}`} name='topic' onChange={handleChange} defaultValue={virtualRow.topic}></textarea>
                      <label htmlFor={`short${virtualRow.postId}`}>Короткое описание в списке статей</label>
                      <textarea id={`short${virtualRow.postId}`} name='shortDesc' onChange={handleChange} defaultValue={virtualRow.shortDesc}></textarea>
                      <label htmlFor={`link${virtualRow.postId}`}>Ссылки, через символ |</label>
                      <textarea id={`link${virtualRow.postId}`} name='sourceLink' onChange={handleChange} defaultValue={virtualRow.sourceLink}></textarea>
                      <label htmlFor={`par1${virtualRow.postId}`}>Параграф 1</label>
                      <textarea id={`par1${virtualRow.postId}`} name='p1' onChange={handleChange} defaultValue={virtualRow.paragraph1}></textarea>
                      <label htmlFor={`par2${virtualRow.postId}`}>Параграф 2</label>
                      <textarea id={`par2${virtualRow.postId}`} name='p2' onChange={handleChange} defaultValue={virtualRow.paragraph2}></textarea>
                      <label htmlFor={`par3${virtualRow.postId}`}>Параграф 3</label>
                      <textarea id={`par3${virtualRow.postId}`} name='p3' onChange={handleChange} defaultValue={virtualRow.paragraph3}></textarea>
                      <label htmlFor={`par4${virtualRow.postId}`}>Параграф 4</label>
                      <textarea id={`par4${virtualRow.postId}`} name='p4' onChange={handleChange} defaultValue={virtualRow.paragraph4}></textarea>
                      <label htmlFor={`comment${virtualRow.postId}`}>Комментарий после поста</label>
                      <textarea id={`comment${virtualRow.postId}`} name='addNotes' onChange={handleChange} defaultValue={virtualRow.addNotes}></textarea>
                      <div style={{marginTop:'1.5rem', display:'flex', flexDirection:'row', justifyContent:'space-between', maxHeight:'40px'}}>
                        <label htmlFor={`doc${virtualRow.postId}`}>Документы для скачивания</label>
                        <div style={{display:'flex', flexDirection:'row', gap:'1rem', marginLeft:'1rem', alignSelf:'center', justifyContent:'space-between'}}>
                          <Button id={`upd${virtualRow.postId}`} startIcon={<FaArrowUp style={{width:'12px'}}/>} variant='contained' component='label' style={{alignSelf:'start', background:'hsl(122 39% 49% / 0.7)', height:'40px', width:'320px', fontSize:'8.5pt', position:'relative', color:'white', fontWeight:'300'}}>
                            Прикрепить файл
                            <Input type='file' id={`upd${virtualRow.postId}`} name='doc' onChange={e =>  handleChange2(e, virtualRow.postid)} hidden/>
                          </Button>
                          {doc && track === virtualRow.postId ? <p style={{color:'#d9b173', fontSize:'small'}}>{doc.name}</p>: ''}
                          {/* <p style={{color:'white', fontSize:'small'}}>{file.name}<MdClose style={{color:'red', size:'52px', marginLeft:'10px'}} onClick={(event) => handleCloseFile(event)} /></p> : ''} */}
                        </div>
                      </div>
                      <div style={{marginTop:'0.75rem', marginBottom:'1.5rem', display:'flex', flexDirection:'row', justifyContent:'space-between', maxHeight:'40px'}}>
                        <label htmlFor={`pic${virtualRow.postId}`}>Обложка поста</label>
                        <div style={{display:'flex', flexDirection:'row', gap:'1rem', marginLeft:'1rem', alignSelf:'center', justifyContent:'space-between'}}>
                          <Button id={`upp${virtualRow.postId}`} startIcon={<FaArrowUp style={{width:'12px'}}/>} variant='contained' component='label' style={{alignSelf:'start', background:'hsl(122 39% 49% / 0.7)', height:'40px', width:'320px', fontSize:'8.5pt', position:'relative', color:'white', fontWeight:'300'}}>
                            Прикрепить файл
                            <Input type='file' id={`upp${virtualRow.postId}`} name='pic' onChange={e => handleChange3(e, virtualRow.postId)} hidden/>
                          </Button>
                          {pic && track === virtualRow.postId ? <p style={{color:'#d9b173', fontSize:'small'}}>{pic.name}</p> : ''}
                          {/* <p style={{color:'white', fontSize:'small'}}>{file.name}<MdClose style={{color:'red', size:'52px', marginLeft:'10px'}} onClick={(event) => handleCloseFile(event)} /></p> : ''} */}
                        </div>
                      </div>
                      <button type='submit' id='aa1' style={{borderRadius:'5px', height:'42px', marginBottom:'3rem'}}>Обновить</button>
                    </form>
                  </div>
                )
            })}
          </div>
        ) : (
          ''
        )}
      </div>
    </>
  )
}

function AdminPageAllArticles() {
  const fetchContext = React.useContext(FetchContext)
  const  [allArticles, setAllArticles] = React.useState('')
  const [doc, setDoc] = React.useState([])
  const [pic, setPic] = React.useState([])
  const [articleData, setArticleData] = React.useState({
    title: '',
    topic: '',
    shortDesc: '',
    sourceLink: '',
    p1: '',
    p2: '',
    p3: '',
    p4: '',
    addNotes: '',
  })
  const [track, setTrack] = React.useState('')
  const AllRef = React.useRef(null)
  const rowVirtualizer = useVirtualizer({
    count: allArticles ? allArticles.length : 1,
    getScrollElement: React.useCallback(() => AllRef.current,[]),
    estimateSize: React.useCallback(() => 1250,[]),
    overscan: 2,
  })  
  const handleChange = (e) => {
    e.preventDefault()
    const value = e.target.value
    setArticleData({
      ...articleData,
      [e.target.name]: value
    })
  };  
  React.useEffect(() => {
    const GetAllArticles = async () => {
      try {
        const { data } = await fetchContext.authAxios.get(
          `api/admin-all-articles`
        );
        setAllArticles(data);
      } catch (err) {
        console.log(err)
      }
    };
    GetAllArticles();
  }, [fetchContext.authAxios]);

  const handleSubmit = async (credentials, id) => {
    credentials.preventDefault()
    const formData = new FormData()
    formData.append('title', articleData.title)
    formData.append('topic', articleData.topic)
    formData.append('shortDesc', articleData.shortDesc)
    formData.append('sourceLink', articleData.sourceLink)
    formData.append('p1', articleData.p1)
    formData.append('p2', articleData.p2)
    formData.append('p3', articleData.p3)
    formData.append('p4', articleData.p4)
    formData.append('addNotes', articleData.addNotes)
    formData.append('pic', pic)
    formData.append('doc', doc)
    try {
      const {data} = await fetchContext.authAxios1.patch(`api/admin-patch-articles/${id}`, formData)
      alert(data.message)
    } catch (err) {
      const {data} = err.response
      alert(data.message)
    }
  }

  const handleChange2 = (e, id) => {
    e.preventDefault()
    setDoc(e.target.files[0])
    setTrack(id)
  }
  const handleChange3 = (e, id) => {
    e.preventDefault()
    setPic(e.target.files[0])
    setTrack(id)
  }

  const handleDelete = async (e, id) =>{
    e.preventDefault()
    try {
      const {data} = await fetchContext.authAxios1.delete(`api/admin-delete-articles/${id}`);
      setAllArticles(
        allArticles.filter(
          id => id !== data.deleteArticle.id
        )
      );
      alert(data.message)
    } catch (err) {
      const {data} = err.response
      return alert(data.message)
    }
  }

  return (
    <React.Suspense>
      <div className='AdminContainer' id='AllArticles'>
        <AllArticlezzz allArticles={allArticles} virtualRows={rowVirtualizer.getVirtualItems()} totalHeight={rowVirtualizer.getTotalSize()} AllRef={AllRef} handleSubmit={handleSubmit} handleDelete={handleDelete} handleChange={handleChange} handleChange2={handleChange2} handleChange3={handleChange3} doc={doc} track={track} pic={pic} />
      </div>
    </React.Suspense>
  )
}

export default React.memo(AdminPageAllArticles)