import {Fade, InputLabel, MenuItem, Select, styled, Tooltip, tooltipClasses, Typography} from '@mui/material'
import React from 'react'
import { Container, Spinner } from 'react-bootstrap'
import RedditTextField from '../components/reddit-textfield'
import '../Styles/Dashboard.css'
import { BsFileEarmarkArrowDown, BsQuestionCircleFill } from 'react-icons/bs'
import { FetchContext } from '../context/FetchContext'
import { useDropzone } from "react-dropzone";
import { MdClose } from "react-icons/md";
import '../components/DropZone.css'

const questionType = [
    {
    id: 1,
    value: 'Скан паспорта'
    },
    {
    id: 2,
    value: 'Заявление'
    },
    {
    id: 3,
    value: 'Военный билет'
    },
    {
    id: 4,
    value: 'Договор'
    },
    {
    id: 5,
    value: 'Чек об оплате'
    },
    {
    id: 6,
    value: 'Свидетельство о смерти'
    },
    {
    id: 7,
    value: 'Получить справку об отсутствии задолженности'
    },
    {
    id: 7,
    value: 'Другое...'
    },
]

const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      background: 'linear-gradient(135deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0))',
      backdropFilter: 'blur(15px)',
      backgroundColor: 'rgba(255, 255, 255, 0.295)',
      paddingLeft: '1.2rem',
      paddingRight: '1.2rem',
      paddingBottom:'1rem',
      paddingTop: '1rem',
      left: '0.18rem',
      color: '#321e1e',
      maxWidth: 250,
      fontSize: theme.typography.pxToRem(12),
      fontWeight: '400',
      border: '1px solid #dadde9',
      letterSpacing: '0.5px',
    },
}));

// const formHeaders = formData.getHeaders()
// images.forEach((file, i) => {
//     filez.append(`file`, file[i])
// })
// const pushUp = []
// const filez = new FormData()
// for (let i=0; i<images.length; i++) {
// const extension = images[i].type.split('/'[1])
// const imageFile = new File([images[i]], `${Date.now()}.${extension}`, {
//     type: images[i].type
// })
// console.log(imageFile)
// pushUp.push(images[i])
// }
// filez.append('file', pushUp)
// console.log(filez)
// for (var key of filez.entries()) {
// 	console.log(key[0] + ', ' + key[1])
// }

function AccountDocs() {
    const fetchContext = React.useContext(FetchContext)
    const [doctype, setDocType] = React.useState('')
    const [open, setOpen] = React.useState(false)
    const [responseSuccess, setResponseSuccess] = React.useState('')
    const [responseError, setResponseError] = React.useState('')
    const width = window.innerWidth
    const breakpoint = 480
    const [docData, setDocData] = React.useState({
        question: '',
        other: ''
    })

    const [requisites, setRequisites] = React.useState()

    React.useEffect(() => {
        const getRequisites = async () => {
            try {
                const { data } = await fetchContext.authAxios.get(
                    `api/getdocs`,
                );
                setRequisites(data);
                console.log(data)
            } catch (error) {
                console.log(error)
            }
        };
        getRequisites();
    }, [fetchContext]);



    const [images, setImages] = React.useState([]);
    const [docLoading, setDocLoading] = React.useState(false)
 
    const handleChange = (e) => setDocType(e.target.value)
    const handleClose = () => setOpen(false);
    const handleOpen = () => setOpen(true);
    
    const handleChange1 = (e) => {
        e.preventDefault()
        const value = e.target.value
        setDocData({
          ...docData,
          [e.target.name]: value
        })
    };

    const handleSubmit = async (e) => {
        e.preventDefault()
        const formData = new FormData()
        console.log(images[0])
        Object.keys(images).forEach(index => formData.set(images[index].path, images[index]))
        for (const pair of formData.entries()) {
            console.log(pair)
        };

        if (images.length === 0) {
            setResponseError('Пожалуйста, прикрепите необходимые документы.');
            return;
        }

        formData.append('Type', (doctype === 'Другое...' ? docData.other : doctype ))
        formData.append('Question', docData.question)
        formData.append('DocNumber', selectedAgreementNum)
        //---
        const credentials = {
            Type: (doctype === 'Другое...' ? docData.other : doctype ),
            Question: docData.question,
            file: formData
        }
        console.log(credentials)

        try {
            setDocLoading(true)
            const {data} = await fetchContext.authAxios1.post('api/upload-docs', formData)
            setResponseSuccess(data.message)
            setResponseError('')
            setTimeout(() => {
                setDocLoading(false)
            }, 1000)
        } catch (error) {
            setDocLoading(false)
            console.log(error)
            setResponseSuccess('')
        }
    }

    const formatBytes = (bytes, decimals = 2) => {
        if (!+bytes) return '0 Bytes'
        const k = 1024
        const dm = decimals < 0 ? 0 : decimals
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
        const i = Math.floor(Math.log(bytes) / Math.log(k))
        return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
    };

    const onDrop = React.useCallback(acceptedFiles => {
        if (acceptedFiles) {

            setImages([...images, ...acceptedFiles])
        } else {
            setImages('')
        }
    }, [images])
    
    const { getRootProps, getInputProps, isDragActive, acceptedFiles } = useDropzone({
        onDrop,
 /*       accept: {
            'image/!*': ['.png', '.pdf', '.jpeg', '.jpg'],
        }*/
    });

    const formData1 = new FormData()
  
    // eslint-disable-next-line no-unused-vars

    const fileObjects = acceptedFiles.map((file) => {
        console.log(file)
        return formData1.append('assets', file, file.name)
    });
   
    const removeFile = file => () => {
        const newFiles =[...images]
        newFiles.splice(newFiles.indexOf(file), 1)
        console.log(images.length)
        setImages(newFiles)
    };
    
    const files = images.map((file) => (
        <li key={file.path} >
            <span>{file.path}</span> - {formatBytes(file.size)}
            <MdClose style={{color: 'hsl(4 90% 58% / 0.7)', marginTop:'3px'}} onClick={removeFile(file)}/>
        </li>
    ));

    const [selectedAgreementNum, setSelectedAgreementNum] = React.useState('');
    const handleChange2 = (e) => setSelectedAgreementNum(e.target.value);

    return (
        <React.Suspense>
            <Container id='UploadC' className='ContractContainerOther'>
                <form method='POST' encType='multipart/form-data' className='DocCol' id="UploadColumn" onSubmit={values => handleSubmit(values)}>
                    <p id='UploadT' className='PayTP'>Обратитесь к нам
                        <HtmlTooltip title={
                            <React.Fragment>
                                <Typography color='#321e1e'>В этом разделе вы можете:</Typography>
                                <div className='UploadFragment'>
                                    <span>{"1. Оставить заявку на изменение паспортных данных."}</span>
                                    <span>{"2. Оставить заявку на получение перерасчета задолженности."}</span>
                                    <span>{"3. Задать интересующий вас вопрос, а ма ны него ответим."}</span>
                                </div>
                            </React.Fragment>
                        } position='bottom' arrow sx={{top:'0rem'}} enterTouchDelay={0} disableFocusListener leaveDelay={1000} TransitionComponent={Fade} TransitionProps={{ timeout: 600 }}>
                            <div className='QuestionIcon'>
                                <BsQuestionCircleFill className='QuestionIcon'/>
                            </div>
                        </HtmlTooltip>
                    </p>


                    { width > breakpoint ? <InputLabel sx={{marginTop:'2rem', paddingRight:'11.5rem', marginBottom:'5px', color:'#321e1e', letterSpacing:'0.25px'}} id='doctype'>Выберите тип документа</InputLabel> : <label htmlFor='doctype-in'>Выберите тип документа</label>}
                    <RedditTextField select required value={doctype} onChange={handleChange} id='doctype-in' variant='filled' name='doctype-in' open={open} onClose={handleClose} onOpen={handleOpen} inputProps={{sx:{paddingLeft:{lg:'1rem', xs:'1rem'}, marginTop:{lg:'-12px', xs:'-12px'},paddingBottom:'12px'}}} InputProps={{style: { paddingTop:'-1px', textAlign:'center', marginBottom:{lg:'12px', xs:'12px'}}, disableUnderline:'true'}}>
                        {questionType.map((keyName) => {
                            return (
                                <MenuItem key={keyName.id} value={keyName.value}>
                                    {keyName.value}
                                </MenuItem>
                            )
                        })}
                    </RedditTextField>
                    {doctype==='Другое...' ? <RedditTextField required value={docData.other} onChange={handleChange1} label='Впишите тип документа' id='other' variant='filled' name='other' sx={{alignSelf:'center', marginTop:'1rem', width:'400px'}}/> : ''}
                    <label style={{color:'#321e1e', letterSpacing:'0.25px'}}>Прикрепите необходимые документы.</label>
                    <ul style={{color:'red', letterSpacing:'0.25px', padding:'0'}}>Необходимо обязательно прикрепить документ удостоверяющий личность (паспорт)</ul>

                    <label htmlFor='doctype-in'>Выберите договор</label>
                    <Select
                        value={selectedAgreementNum}
                        onChange={handleChange2}
                        variant="outlined"
                        fullWidth
                        style={{ marginBottom:'10px' }} // Пример стилей, можно настроить по вашему желанию
                    >
                        {requisites && requisites.map((requisite, index) => (
                            <MenuItem key={index} value={requisite.agreementNum}>
                                {requisite.agreementNum}
                            </MenuItem>
                        ))}
                    </Select>


                    <section className="DropContainer">
                        <div {...getRootProps({ className: "dropzone" })}  >
                            <input type='file' name='files' id='files' multiple className="input-zone" {...getInputProps()} onDrop={onDrop}/>
                            <div className="text-center">
                                {isDragActive ? (
                                    <p>
                                        Отпустите чтобы загрузить
                                    </p>
                                ) : (
                                    <p>
                                        Перенесите файлы сюда, или нажмите на любую область зоны чтобы выбрать
                                    </p>
                                )}
                                <button type="button" onClick={open}>
                                    <BsFileEarmarkArrowDown style={{fontSize:'large'}}/>
                                </button>
                            </div>
                        </div>
                        {images.length > 0 ? (
                            <aside className="A-side" style={{boxShadow: 'inset 0 0px 7px hsl(153, 36%, 36%, 0.75)'}}>
                                <ul>{files}</ul>
                            </aside>
                        ) : (
                            <aside className="A-side">
                                <ul>{files}</ul>
                            </aside>
                        )}
                    </section>
                    {width > breakpoint ? <InputLabel sx={{marginTop:'1.2rem', paddingRight:'3.75rem', marginBottom:'5px', color:'#321e1e', letterSpacing:'0.25px'}} id='question'>Расскажите, как мы можем вам помочь?</InputLabel> : <label htmlFor='question'>Расскажите, как мы можем вам помочь?</label>}
                    <RedditTextField multiline required value={docData.question} onChange={handleChange1} minRows={3} maxRows={3} id='question' variant='filled' name='question' InputProps={{style: {paddingTop:'10px'}, disableUnderline:'true'}} sx={{marginTop:'0'}}></RedditTextField>
                    {responseSuccess ? <div style={{alignSelf:'center', marginTop:'12px', color:'#81c784'}}>{responseSuccess}</div> : ''}
                    {responseError ? <div style={{alignSelf:'center', marginTop:'12px', color:'red'}}>{responseError}</div> : ''}
                    <button type='submit' id='docsSubmit' loading={docLoading}>{docLoading === true ? (<Spinner animation="border" style={{marginTop:'0px', scale:'80%'}}/>) : 'отправить'}</button>
                </form>
            </Container>
        </React.Suspense>
    )
}

export default React.memo(AccountDocs)