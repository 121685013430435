import { createTheme, FormControl, FormControlLabel, FormGroup, Radio, RadioGroup, TextField, ThemeProvider } from '@mui/material'
import React from 'react'
import ButnotS from '../Styles/butnotS'
import './Calculator.css'
import { tel } from '../Styles/colors';

const theme = createTheme({
    palette: {
      secondary: {
        // This is green.A700 as hex.
        main: '#11cb5f',
      },
    },
});

function CalculatorO() {
    const [checked,setChecked] = React.useState('30')
    const [summ, setSumm] = React.useState({
        summ1: '',
    })
    const [calc, setCalc] = React.useState('')

    const handleChange = (event) => {
        event.preventDefault()
        const value = event.target.value
        setSumm({
            ...summ,
            [event.target.name]: value
        })
    };

    const handleChange1 = (e) => {
        setChecked(e.target.value);
    };

    const handleSubmit = (e) => {
        e.preventDefault()
        
        if (summ.summ1 >= 100000) {
            if (checked === '30') {
                setCalc(summ.summ1 * 0.6)
            } else if (checked === '90') {
                setCalc(summ.summ1 * 0.65)
            } else if (checked === '120') {
                setCalc(summ.summ1 * 0.7)
            } else {
                setCalc(summ.summ1 * 0.75)
            }
        } else if (summ.summ1 < 100000 && summ.summ1 > 1500) {
            if (checked === '30') {
                setCalc(summ.summ1 * 0.7)
            } else if (checked === '90') {
                setCalc(summ.summ1 * 0.75)
            } else if (checked === '120') {
                setCalc(summ.summ1 * 0.8)
            } else {
                setCalc(summ.summ1 * 0.85)
            }
        } else {
            setCalc(summ.summ1)
        }
    }

    return (
        <>
            <form onSubmit={values => handleSubmit(values)}>
                <FormControl required>
                        <FormGroup sx={{color:'rgb(244, 241, 224)', fontWeight:'300', fontSize:'x-large', marginTop:'-1.25rem'}} className='formGroupO'>
                            <div className='calcWrapper'>
                                <label htmlFor='summ1'>Сумма долга:</label>
                                <TextField required value={summ.summ1} type='text' onChange={handleChange} color='success' variant='standard' id='summ1' name='summ1' sx={{'& .MuiInputBase-input': {color:'rgb(232, 188, 123)', fontWeight:'500', letterSpacing:'0.5px', fontSize:'large'}, position:'relative', top: '3px', minWidth:'60px', maxWidth:'160px'}}/>  
                            </div>
                            <div>
                                <label id='formGroupOlabel'>Дата получения уведомления об уступке права требования?</label>
                                <RadioGroup row={true} name='use-radio-group1' value={checked} onChange={handleChange1} defaultValue='30' className='checkOps' >
                                    <ThemeProvider theme={theme}>
                                        <FormControlLabel value='30' control={<Radio disableRipple color='secondary' sx={{color:'rgb(244, 241, 224)', marginRight:'0.25rem', position:'relative'}} />} label='30 дней назад' sx={{color:'rgb(232, 188, 123)'}}/>
                                        <FormControlLabel value='90' control={<Radio disableRipple color='secondary' sx={{color:'rgb(244, 241, 224)', marginRight:'0.25rem', position:'relative'}} />} label='90 дней назад' sx={{color:'rgb(232, 188, 123)'}}/>
                                        <FormControlLabel value='120' control={<Radio disableRipple color='secondary' sx={{color:'rgb(244, 241, 224)', marginRight:'0.25rem', position:'relative'}} />} label='120 дней назад' sx={{color:'rgb(232, 188, 123)'}}/>
                                        <FormControlLabel value='120+' control={<Radio disableRipple color='secondary' sx={{color:'rgb(244, 241, 224)', marginRight:'0.25rem', position:'relative'}} />} label='свыше 120 дней / не помню' sx={{color:'rgb(232, 188, 123)'}}/>
                                    </ThemeProvider>
                                </RadioGroup>
                            </div>
                            {/* <TextField value={summ.daten} type='date' aria-describedby="my-helper-text" color='success' variant='standard' id='daten' name='daten' onChange={handleChange} sx={{'& .MuiInputBase-input': {color:'rgb(232, 188, 123)', fontWeight:'500', letterSpacing:'0.5px', fontSize:'large'}, position:'relative', top: '12px', minWidth:'60px', maxWidth:'320px', marginBottom:'3rem'}} inputProps={{ min: "2018-05-01"}}/> */}
                            <div className='calcBottomWrapperO'>
                                <ButnotS type='submit' name='Рассчитать задолженность' id='c2' style = {{marginBottom:"10px"}}></ButnotS>
                                {calc && <div><b style={{marginRight:'10px', fontSize:'xx-large'}}>{Number(calc).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')} руб.</b>
                                    <br/>
                                    Для подтверждения свяжитесь с нами:
                                    <br/>
                                    Телефон:
                                    <a href="tel:83412940707">{tel}</a>
                                    <br/>
                                    Email:
                                    <a href='mailto:collect@ec18.ru'>collect@ec18.ru</a>
                                </div>
                                }
                            </div>
                        </FormGroup>
                    </FormControl>
            </form>
        </>
    )
}

export default CalculatorO