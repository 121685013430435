import React from 'react';
import { Outlet } from 'react-router';
import Profile from '../components/Profile';
import Sidebar from '../components/Sidebar';
import Support from '../components/Support';
import { AuthContext } from '../context/AuthContext';

const WithSidebar = () => {

  const auth = React.useContext(AuthContext);
  
  return (
    <>
      <div className='bodySide'>
        <Sidebar style={{position:'absolute', zIndex:'30'}} /> 
          {!auth.isAdmin() && <Support/>}
          <Outlet/><Profile/>
      </div>
    </>
  );
}

export default WithSidebar
