import React, { createContext, useContext } from 'react';
import axios from 'axios';
import { AuthContext } from './AuthContext';

const FetchContext = createContext();
const { Provider } = FetchContext;

const FetchProvider = ({ children }) => {
  const authContext = useContext(AuthContext);

  const authAxios = axios.create({
    // baseURL: process.env.REACT_APP_API_URL1,
    baseURL: `/api`,
  });

  const authAxios1 = axios.create({
    // baseURL: process.env.REACT_APP_API_URL2,
    baseURL: `/api`,
  });

  authAxios1.interceptors.request.use(
    config => {
      config.headers = {
        'Accept' : 'application/json; text/plain ; */*',
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${authContext.authState.token}`
      }
      return config;
    },
    error => {
      return Promise.reject(error);
    }
  );

  authAxios1.interceptors.response.use(
    response => {
      console.log(response)
      return response;
    },
    error => {
      const code =
        error && error.response ? error.response.status : 0;
      if (code === 401 || code === 403) {
        // authContext.getNewToken();
        console.log('error code', code)
      }
      return Promise.reject(error);
    }
  );

  authAxios.interceptors.request.use(
    config => { 
      // const token = localStorage.getItem('token')
      config.headers = {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': `Bearer ${authContext.authState.token}`
      }
      // config.headers.Authorization = ;
      // console.log(`${config.headers.Authorization}`)
      return config;
    },
    error => {
      return Promise.reject(error);
    }
  );

  // createAuthRefreshInterceptor(
  //   authAxios,
  //   authContext.getNewTokenForRequest,
  //   {
  //     pauseInstanceWhileRefreshing: false
  //   }
  // )
  authAxios.interceptors.response.use(
    response => {
      console.log(response)
      return response;
    },
    error => {
      const code =
        error && error.response ? error.response.status : 0;
      if (code === 401 || code === 403) {
        console.log('error code', code)
      }
      return Promise.reject(error);
    }
  );

  return (
    <Provider
      value={{
        authAxios,
        authAxios1
      }}
    >
      {children}
    </Provider>
  );
};

export { FetchContext, FetchProvider };