import React from 'react';
import {FaArrowRight} from 'react-icons/fa';
import './ButtonS.css';

function ButnotS(props) {
    const preventDragHandler = (e) => {
        e.preventDefault()
    }
    return (
        <>
            <button className={'butnot-second'} style={props.style} id={props.id} type={props.type} aria-label={props.ariaLabel} onDragStart={preventDragHandler}><span>{props.name}</span><FaArrowRight className='fa'></FaArrowRight></button>
        </>
    )
}

export default React.memo(ButnotS)